<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Planning DvdS'"
                    subTitle="Verkeersmaatregelen">
        </app-header>
    </div>
    <div class="indicator">

    </div>
    <div class="print-pdf">

    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-3">

        </div>
        <div class="col-md-6">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Zoeken...</mat-label>
                <input matInput placeholder="Zoeken op project, opdrachtgever, locatie of type" value="Sushi" [formControl]="fcSearch">
            </mat-form-field>
        </div>
        <div class="col-3 d-flex align-items-center pb-2">
            <mat-checkbox [formControl]="fcShowHistory">Toon verleden</mat-checkbox>
        </div>
    </div>
    <table class="table table-center table-add-row table-hover">
        <tr>
            <th>
                Project
            </th>
            <th>
                Opdrachtgever
            </th>
            <th>
                Locatie
            </th>
            <th>
                Type
            </th>
            <th>
                Datum
            </th>
        </tr>
        <tr *ngFor="let workorder of workorders" class="curpoint">
            <td routerLink="/vkm/{{workorder.planning_id}}">
                {{workorder.afas_project_id}}
            </td>
            <td routerLink="/vkm/{{workorder.planning_id}}">
                {{workorder.contractor}}
            </td>
            <td routerLink="/vkm/{{workorder.planning_id}}">
                {{workorder.location}}
            </td>
            <td routerLink="/vkm/{{workorder.planning_id}}">
                {{workorder.type}}
            </td>
            <td routerLink="/vkm/{{workorder.planning_id}}">
                {{workorder.begindate  | date : 'd MMM yyyy'}}
            </td>
        </tr>
        <tr>
            <td colspan="5">
                <button mat-button color="primary" class="w-100" routerLink="/vkm/new">
                    Toevoegen <i class="fas fa-plus"></i>
                </button>
            </td>
        </tr>
    </table>

</div>
