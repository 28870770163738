import {Realisation} from '../../../classes/realisation';

export class GroupTotals {
    days: Date[];
    totals: WeekTotals[];
    group: string;
    visible: boolean;
}

export class WeekTotals {
    days: {
        date: Date,
        total: number
    }[];
    weekTotal: number;
    name: string;
    group: string;
    function: string;
    realisations: Realisation[];
}
