import {AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {Planning} from '../../classes/planning.class';
import {FormGroup} from '@angular/forms';
import {PlanningService} from '../../services/planning/planning.service';
import {DefaultStand, User} from 'src/app/classes/user.class';
import {EntityType, EntityTypeCode} from 'src/app/services/entities/entity-type.class';
import {Entity} from '../../classes/entity.class';
import {Project} from '../../classes/project.class';
import {DayTimeOptions} from '../../classes/day-time-options';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningDumper} from '../../classes/planningdumper.class';
import {PlanningTruck} from '../../classes/planningtruck.class';
import {Hiring} from '../../classes/hiring';
import {Observable, Subject} from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {CodaltComponent} from '../../codalt.component';
import {CDK_DRAG_PARENT, CdkDragHandle} from '@angular/cdk/drag-drop';
import {Leave} from '../../classes/leave';
import {RealisationService} from '../../services/realisation.service';

interface PlanningForm {
    mainPlanning: PlanningHasEntity;
    planning?: Planning;
    entities: {
        fg: FormGroup,
        planning: PlanningHasEntity,
        highlight?: boolean
    }[];
    hasSets?: boolean;
    asphaltTrucks?: number;
    cutterTrucks?: number;
    saving?: boolean;
}

@Component({
    selector: '[app-transport-planning-row]',
    templateUrl: './transport-planning-row.component.html',
    styleUrls: ['./transport-planning-row.component.scss']

})
export class TransportPlanningRowComponent extends CodaltComponent implements OnInit, OnDestroy, AfterViewInit {

    hourtypeIconMap = RealisationService.hourtypeIconMap;

    @Input() readOnly: boolean;

    @Input() pln: PlanningHasEntity;

    @Input() main: PlanningForm;

    @Input() fg: FormGroup;
    @Input() highlight: boolean;
    @Input() i: number;
    @Input() drivers: User[];
    @Input() lowLoaders: Entity[];
    @Input() employees: User[];
    @Input() executors: User[];
    @Input() entitiesMap: Map<number, Entity>;
    @Input() planning: Planning[];
    @Input() currentDate = new Date();
    @Input() entityTypes: EntityType[];
    @Input() hiringMap = new Map<number, Hiring[]>();
    @Input() trucks: Entity[];
    @Output() addChooseEntity: EventEmitter<{
        planningForm: PlanningForm,
        type: EntityType,
        pheId?: number,
        disableFromCurrentPlanning: boolean
    }> = new EventEmitter();
    @Output() deletePlanningEmitter: EventEmitter<PlanningHasEntity> = new EventEmitter();
    @Output() duplicatePlanningEmitter: EventEmitter<{
        planning: PlanningHasEntity,
        main: PlanningForm,
        formGroup: FormGroup,
        previous: boolean
    }> = new EventEmitter();

    @ViewChild('menuTrigger') menuTrigger;
    @ViewChild('spanTrigger') spanTrigger;

    @Input() blockedMap: Map<number, { icon: string; description: string; }>;
    @Input() leaveDayUserMap: Map<number, Leave> = new Map<number, Leave>();

    searchAfasProjects$ = new Subject<string>();
    projects: Project[];

    @ViewChild(CdkDragHandle, {static: true}) handle: CdkDragHandle;


    ngAfterViewInit() {
        this.cdk._handles.length = 1;
        this.cdk._handles._results = [this.handle];
        this.cdk._handles.changes.next();
    }

    @Input()
    startTimesEntity: Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]> = new Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]>();
    @Input()
    endTimesEntity: Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]> = new Map<(PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningDumper | PlanningTruck), DayTimeOptions[]>();

    EntityTypeCode = EntityTypeCode;
    DefaultStand = DefaultStand;

    @Input() hiringAutocompleteOptions: string[];

    @HostListener('contextmenu', ['$event']) onContxtMenu($event) {
        this.spanTrigger.nativeElement.style.left = $event.clientX + 5 + 'px';
        this.spanTrigger.nativeElement.style.top = $event.clientY + 5 + 'px';
        if (this.menuTrigger.menuOpen) {
            this.menuTrigger.closeMenu();
            this.menuTrigger.openMenu();
        } else {
            this.menuTrigger.openMenu();
        }
        $event.preventDefault();
        $event.stopPropagation();
    }


    constructor(@Optional() @Inject(CDK_DRAG_PARENT) public cdk,
                private planningService: PlanningService,
                private projectService: ProjectService) {
        super();
    }

    ngOnInit() {
        this.subscriptions.add(this.searchAfasProjects$.pipe(debounceTime(200)).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));
    }


    deletePlanning(planning: PlanningHasEntity) {
        if (planning.id) {
            this.planningService.deleteSingle(planning).then(() => {
            });
        } else {
            this.deletePlanningEmitter.emit(planning);
        }
    }


    changeEntity(planning: PlanningHasEntity, planningForm: PlanningForm, pheId: number) {
        this.addChooseEntity.emit({planningForm, type: planning.entitytype, pheId, disableFromCurrentPlanning: false});
    }

    removeEntity(planningForm: PlanningForm, pheId: number) {
        const entity = planningForm.entities.find(e => e.planning.id === pheId);
        if (entity) {
            entity.planning.entity = null;
            entity.fg.controls.entity_id.setValue(null);
            entity.fg.controls.lowloader_entity_id.setValue(null);
            entity.fg.controls.truck_entity_id.setValue(null);
            entity.fg.controls.driver_user_id.setValue(null);
            entity.fg.controls.hiring_id.setValue(null);
            entity.fg.controls.name.setValue(null);
            entity.fg.markAsDirty();
        }
    }

    duplicatePlanning(planning: PlanningHasEntity, main: PlanningForm, formGroup: FormGroup, previous = false) {
        this.duplicatePlanningEmitter.emit({planning, main, formGroup, previous});
    }

    ngOnDestroy() {
    }

}
