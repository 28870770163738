import {Component, OnInit} from '@angular/core';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Asphaltmill} from '../classes/asphaltmill.class';
import {AsphaltService} from '../services/asphalt/asphalt.service';
import {Asphalt} from '../classes/asphalt.class';
import {AsphaltmillAsphalt} from '../classes/asphaltmillasphalt.class';
import {ImportAsphaltListDialogComponent} from './import-asphalt-list-dialog/import-asphalt-list-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {saveAs} from 'file-saver';
import {Utils} from "../utils.class";
import {PlanningAsphalt} from '../classes/planningasphalt.class';
import {formatDate} from '@angular/common';

@Component({
    selector: 'app-asphalt',
    templateUrl: './asphalt.component.html',
    styleUrls: ['./asphalt.component.scss']
})
export class AsphaltComponent implements OnInit {

    public asphaltMills: Asphaltmill[];
    public asphaltList: Asphalt[];

    private subscriptions = new Subscription();

    constructor(private dialog: MatDialog,
                public dialogRef: MatDialogRef<AsphaltComponent>,
                private confirmDialogService: ConfirmDialogService,
                private asphaltService: AsphaltService) {
    }

    ngOnInit() {
        this.loadData();
    }

    close() {
        this.dialogRef.close();
    }

    export() {
        this.asphaltService.exportAsphaltListToExcel().subscribe(data => {
            saveAs(data, 'asfaltlijst.xlsx');
        });
    }

    openImportDialog() {
        const dialogRef = this.dialog.open(ImportAsphaltListDialogComponent, {
            width: '500px',
            maxHeight: '600px',
            data: {}
        });
        this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
            this.loadData(false);
        }));
    }

    loadData(cache = true) {
        this.asphaltService.getAsphaltMills(cache).then(asphaltMill => {
            this.asphaltMills = asphaltMill;
        });
        this.asphaltService.getAsphaltList(cache).then(asphaltList => {
            this.asphaltList = asphaltList;
        });
    }

    public deleteAsphalt(asphalt: Asphalt) {
        this.confirmDialogService.confirm(
            'Asfaltsoort verwijderen',
            `Weet u zeker dat u asfaltsoort ${asphalt.name} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(confirm => {
            this.asphaltService.deleteAsphalt(asphalt).then(result => {
                this.loadData(false);
            }, error => {
                Utils.handleError(error, this.confirmDialogService);
            });
        }, () => {

        });
    }

    public deleteAsphaltMill(asphaltmill: Asphaltmill) {
        this.confirmDialogService.confirm(
            'Asfaltmolen verwijderen',
            `Weet u zeker dat u asfalt molen ${asphaltmill.name} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(confirm => {
            this.asphaltService.deleteAsphaltMill(asphaltmill).then(result => {
                this.loadData(false);
            }, error => {
                Utils.handleError(error, this.confirmDialogService);
            });
        }, () => {

        });
    }

    public deleteAsfaltMillAsphalt(asphaltmillAsphalt: AsphaltmillAsphalt) {
        this.confirmDialogService.confirm(
            'Asfaltsoort van centrale verwijderen',
            `Weet u zeker dat u asfaltsoort ${asphaltmillAsphalt.code} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(confirm => {
            this.asphaltService.deleteAsfaltMillAsphalt(asphaltmillAsphalt).then(result => {
                this.loadData(false);
            }, error => {
                const lines = error['error']['error']['data'] as PlanningAsphalt[];
                const errorList = lines.reduce((accumulator, message) => {
                    return accumulator + `<li>${message.planning.afas_project_id} - ${formatDate(message.time, 'EEEE d MMMM yyyy HH:mm', 'nl')}</li>`;
                }, '');
                this.confirmDialogService.confirm(
                    'Fout bij het opslaan1',
                    `Er is al gepland met dit asfalt op de volgende datums en projecten:<ul>${errorList}</ul>`,
                    'Oké',
                    null
                );
            });
        }, () => {

        });
    }
}
