<div (click)="open(pln)"
     *ngFor="let pln of item.items"
     [class.highlight]="pln.planning === highlightedPlanning"
     [class.no-people]="isTeam && people.get(pln.planning.id) === 0"
     class="planning offset-{{pln.minutesOffset}} duration-{{pln.duration}} {{pln.planningEntity.entity_id !== pln.planning.entity_id ? 'secondary' : '' }}">
    <div (mouseenter)="mouseEnter(pln)" (mouseleave)="mouseLeave(pln)" class="info status-{{pln.planning.status_id}}">
        <div class="main">
            <div class="time">
                {{pln.planningEntity.begindate | date : 'HH:mm'}} - {{pln.planningEntity.enddate | date : 'HH:mm'}} uur
            </div>
            <div class="name">
                {{pln.planningEntity.entity.use_once ? pln.planningEntity.entity.name : pln.planningEntity.name}}

                <ng-container *ngIf="pln.planning.worknumber || pln.planning.afas_project_id">
                    - {{pln.planning.worknumber ?? pln.planning.afas_project_id}}
                </ng-container>
            </div>
        </div>
        <div class="other-info">
            <div class="description" *ngIf="pln.planning.status_id === PlanningStatus.geblokkeerd">
                {{pln.planning.description}}
            </div>
            <ng-container *ngIf="pln.planningEntity.entitytype !== 5">
                <span class="ucfirst">{{pln.planningEntity.driver}}</span><br>
            </ng-container>
            <ng-container *ngIf="pln.planningEntity.entitytype === 5">
                {{pln.planning.worknumber}}<br>
            </ng-container>
            {{pln.planning.contractor}}<br>
            <ng-container *ngIf="pln.planningEntity.entitytype === 5">
                <ng-container *ngFor="let set of pln.planning.planning_sets; let i = index;">
                    <span *ngIf="i > 0">, </span><span>{{set.entity.name}}</span>
                </ng-container>
            </ng-container>
        </div>
        <div *ngIf="pln.planningEntity.entitytype !== 430003 && pln.planning.status_id < 5 && pln.planning.entity_id != 900" class="d-flex">
            <div class="tons flex-grow-1">{{tons.get(pln.planning.id)}}</div>
            <div class="trucks flex-grow-1">{{trucks.get(pln.planning.id)}}</div>
            <div class="people flex-grow-1" *ngIf="isTeam">{{people.get(pln.planning.id)}}</div>
            <div [class.night]="night.get(pln.planning.id)" class="daynight flex-grow-1"></div>
        </div>
    </div>
</div>
<div #timeChooseDiv *ngIf="hourChoose !== null && (!isFixed ||viewModel.addPlanningFixed)"
     class="planning duration-{{defaultDuration * 60}} selector offset-{{hourChoose}}">

</div>
<div (dblclick)="openEntityEdit()" *ngIf="item.unavailableDays" class="unavailable duration-{{item.unavailableDays}}">
    Niet beschikbaar
</div>
