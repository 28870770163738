<div class="modal-inner">
    <div class="stepper">
        <div [class.active]="activeStep===Step.Type"
             [class.filled]="form.value.type"
             *ngIf="!data.userId"
             (click)="setActiveStep(Step.Type)">
            <small>Type</small>
            {{form.value.type === 'user' ? 'Medewerker' : ''}}
            {{form.value.type === 'entity' ? 'Materieel' : ''}}
            {{form.value.type === 'hiring_user' ? 'Inhuur medewerker' : ''}}
            {{form.value.type === 'hiring_material' ? 'Inhuur materieel' : ''}}
        </div>
        <div [class.active]="activeStep===Step.Employee"
             [class.filled]="form.value.userId || form.value.entityId"
             *ngIf="!data.userId"
             (click)="setActiveStep(Step.Employee)">
            <small>{{form.value.type?.substr(-4) === 'user' ? 'Medewerker' : 'Materieel'}}</small>
            {{(employees | byId : form.value.userId)?.name}}
            {{form.value.hiring_name ?? (entities | byId : form.value.entityId)?.name}}
        </div>
        <div [class.active]="activeStep===Step.Hourtype"
             [class.filled]="form.value.hourtype"
             (click)="setActiveStep(Step.Hourtype)">
            <small>Uursoort</small>
            {{form.value.hourtype | hourtype}}
            <ng-container *ngIf="form.value.hourtype === RealisationHourtype.worktime && night">
                nacht
            </ng-container>
        </div>
        <div [class.active]="activeStep===Step.Project"
             [class.filled]="activeStep===Step.Vehicle || activeStep===Step.Time"
             (click)="setActiveStep(Step.Project)">
            <small>Project</small>
            {{form.value.projectId ?? '-'}}
        </div>
        <div [class.active]="activeStep===Step.Time"
             [class.filled]="form.value.time !== null"
             (click)="setActiveStep(Step.Time)">
            <small>Tijd</small>
            <span *ngIf="form.value.time !== null">
                <span [class.downlight]="timeOptions[form.value.time].begin | datetimeEquals : previousRealisation?.enddate">
                    {{timeOptions[form.value.time].begin | date : 'HH:mm'}}
                </span>
                -
                <span [class.downlight]="timeOptions[form.value.time].end | datetimeEquals : nextRealisation?.begindate">
                    {{timeOptions[form.value.time].end | date : 'HH:mm'}}
                </span>
            </span>
            <span *ngIf="form.value.time === null">
                -
            </span>
        </div>
        <div [class.active]="activeStep===Step.Vehicle"
             *ngIf="form.value.type === 'user'"
             (click)="setActiveStep(Step.Vehicle)">
            <small>Voertuig</small>
        </div>
    </div>

    <div *ngIf="activeStep===Step.Project" class="modal-inner-inner">
        <h1>Kies een project</h1>
        <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
        <div class="results">
            <div class="result text-center" *ngIf="searching">
                aan het zoeken...
            </div>
            <div class="result text-center" *ngIf="!searching && projects?.length === 0">
                {{fcProjectSearch.value?.length > 0 ? 'project niet gevonden' : 'voer een projectnummer in'}}
            </div>
            <div class="result"
                 [class.highlight]="i == selectedIndex"
                 (click)="selectProject(project.projectId, project.planningId)"
                 *ngFor="let project of projects; let i = index;">
                <strong>{{project.projectId}}</strong> {{project.location}}
            </div>
        </div>
    </div>

    <div *ngIf="activeStep===Step.Vehicle" class="modal-inner-inner">
        <h1>Kies een voertuig ({{fcEntities.value?.length ?? 0}} geselecteerd)</h1>
        <input placeholder="Zoeken..." [(ngModel)]="itemSearch">
        <div class="results">
            <mat-selection-list [formControl]="fcEntities">
                <mat-list-option *ngFor="let entity of entities" [value]="entity.id"
                                 [class.d-none]="itemSearch?.length > 0 && entity.name.toLowerCase().indexOf(itemSearch.toLowerCase()) === -1">
                    {{entity.name}}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </div>

    <div *ngIf="activeStep===Step.Type" class="modal-inner-inner">
        <h1>Kies een type</h1>
        <div class="results">
            <div class="choose-buttons mb-2">
                <button mat-raised-button class="planning-button cancel" (click)="setType('entity')">
                    <i class="fa-duotone fa-truck fa-2x"></i><br>
                    Materieel
                </button>
                <button mat-raised-button class="planning-button cancel" (click)="setType('user')">
                    <i class="fa-duotone fa-user fa-2x"></i><br>
                    Medewerker
                </button>
            </div>
            <div class="choose-buttons mb-3">
                <button mat-raised-button class="planning-button cancel" (click)="setType('hiring_material')">
                    <i class="fa-thin fa-truck fa-2x"></i><br>
                    Inhuur materieel
                </button>
                <button mat-raised-button class="planning-button cancel" (click)="setType('hiring_user')">
                    <i class="fa-thin fa-user fa-2x"></i><br>
                    Inhuur medewerker
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="activeStep===Step.Hourtype" class="modal-inner-inner">
        <h1>Kies een uursoort</h1>
        <div class="other-hours-day" *ngIf="otherRealisationsThisDay?.realisations?.length">
            <div>
                {{(employees | byId : form.value.userId)?.name}} heeft
                <ng-container *ngIf="data.bookdate | isToday">
                    vandaag
                </ng-container>
                <ng-container *ngIf="!(data.bookdate | isToday)">
                    op {{data.bookdate | date : 'EEEE d MMMM'}}
                </ng-container>
                al urenregistraties:
            </div>
            <div *ngFor="let realisation of otherRealisationsThisDay?.realisations">
                - {{realisation.begindate | date : 'EEE HH:mm'}}-- {{realisation.enddate | date : 'HH:mm'}}
                -
                {{realisation.planning?.afas_project_id ?? realisation.afas_project_id ?? realisation?.user_planning?.project?.afas_project_id}}
                <ng-container *ngIf="realisation.hourtype === RealisationHourtype.sleep">
                    Slaapdag
                </ng-container>
                -
                <ng-container *ngIf="!realisation?.planning_has?.performer?.name">
                    <ng-container *ngIf="otherRealisationsThisDay.showProjectTeamPerformer">
                        {{realisation?.planning?.performer_user?.name ?? realisation?.project?.performer?.name ?? realisation?.user_planning?.project?.performer?.name}}
                    </ng-container>
                    <ng-container *ngIf="otherRealisationsThisDay.showAsphaltTeamPerformer">
                        <ng-container
                            *ngIf="otherRealisationsThisDay.showProjectTeamPerformer && (realisation?.planning?.asphalt_performer_user?.name ?? realisation?.project?.asphalt_performer?.name ?? realisation?.user_planning?.project?.asphalt_performer?.name)">
                            /
                        </ng-container>
                        {{realisation?.planning?.asphalt_performer_user?.name ?? realisation?.project?.asphalt_performer?.name ?? realisation?.user_planning?.project?.asphalt_performer?.name}}
                        <ng-container
                            *ngIf="!(realisation?.planning?.asphalt_performer_user?.name ?? realisation?.project?.asphalt_performer?.name ?? realisation?.user_planning?.project?.asphalt_performer?.name)">
                            {{realisation?.planning?.performer_user?.name ?? realisation?.project?.performer?.name ?? realisation?.user_planning?.project?.performer?.name}}
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="realisation?.planning_has?.performer?.name">
                    {{realisation?.planning_has?.performer?.name}}
                </ng-container>
            </div>
        </div>
        <div class="results">
            <div class="choose-buttons mb-3" *ngIf="form.value.userId">
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.driving_back)">
                    <i class="fa-duotone fa-truck fa-2x"></i><br>
                    Transporttijd
                </button>
                <button mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.worktime)">
                    <i class="fa-duotone fa-person-digging fa-2x"></i><br>
                    Werktijd
                </button>
                <button *ngIf="form.value.userId" mat-raised-button class="planning-button cancel"
                        (click)="setHourtype(RealisationHourtype.travel_back)">
                    <i class="fa-duotone fa-car-side fa-2x"></i><br>
                    Reistijd
                </button>
            </div>
            <ng-container *ngIf="form.value.userId">
                <div class="choose-buttons mb-3">
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.frost)">
                        <i class="fa-duotone fa-snowflakes fa-2x"></i><br>
                        Vorst
                    </button>
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.paid_leave)">
                        <i class="fa-duotone fa-calendar-range fa-2x"></i><br>
                        Doorbetaald verlof
                    </button>
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.short_absence)">
                        <i class="fa-duotone fa-calendar-clock fa-2x"></i><br>
                        Kort verzuim
                    </button>
                </div>
                <div class="choose-buttons mb-3">
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.sleep)">
                        <i class="fa-duotone fa-face-sleeping fa-2x"></i><br>
                        Slapen
                    </button>
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.special_leave)">
                        <i class="fa-duotone fa-baby fa-2x"></i><br>
                        Bijzonder verlof
                    </button>
                    <button mat-raised-button class="planning-button cancel"
                            (click)="setHourtype(RealisationHourtype.education)">
                        <i class="fa-duotone fa-school fa-2x"></i><br>
                        Scholing
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <div *ngIf="activeStep===Step.Time" class="modal-inner-inner">
        <h1>Kies een tijd</h1>
        <div class="results">
            <h2 *ngIf="currentMinutes > 0">
                Uren vandaag: {{currentMinutes - currentPause | minutes}} uur
                + <i class="fas fa-mug-saucer"></i> {{currentPause | minutes}}
            </h2>
            <h2 *ngIf="currentMinutes > 0">
                Gewerkte tijden:
                {{nextRealisation?.begindate | date : 'EEE HH:mm'}} -
                {{previousRealisation?.enddate | date : 'EEE HH:mm'}}
            </h2>
            <h2 *ngIf="currentMinutes == 0">
                Nog geen uren geregistreerd
            </h2>
            <div class="choose-buttons two mb-3">
                <button mat-raised-button class="planning-button cancel"
                        *ngFor="let timeOption of timeOptions; let i = index"
                        (click)="setTime(i)">
                    <i class="fa-duotone {{form.value.hourtype | hourtypeIcon}} fa-2x"></i><br>
                    <span [class.downlight]="timeOption.begin | datetimeEquals : previousRealisation?.enddate">
                        {{timeOption.begin | date : 'EEE HH:mm'}}
                    </span>
                    -
                    <span [class.downlight]="timeOption.end | datetimeEquals : nextRealisation?.begindate">
                        {{timeOption.end | date : 'EEE HH:mm'}}
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="activeStep===Step.Employee" class="modal-inner-inner">
        <h1>Kies {{form.value.type === 'user' || form.value.type === 'hiring_user' ? 'medewerker' : 'materieel'}}</h1>
        <input placeholder="Zoeken..." [formControl]="fcItemSearch">
        <div class="results" *ngIf="form.value.type === 'user'">
            <div class="result"
                 [class.highlight]="selectedIndex === i"
                 (click)="setUser(user.id)"
                 *ngFor="let user of employees | search : 'name' : fcItemSearch.value; let i = index">
                <strong>{{user.name}}</strong> {{user.function}}
            </div>
        </div>
        <div class="results" *ngIf="form.value.type === 'entity'">
            <div class="result"
                 (click)="setEntity(entity)"
                 *ngFor="let entity of entities | search : 'name' : fcItemSearch.value">
                <strong>{{entity.name}}</strong>
            </div>
        </div>
        <div class="results" *ngIf="form.value.type === 'hiring_material' || form.value.type === 'hiring_user'">
            <div class="result" (click)="setHiring(fcItemSearch.value)" *ngIf="fcItemSearch.value?.length > 2">
                <strong>{{fcItemSearch.value}}</strong>
            </div>
            <div class="result"
                 (click)="setHiring(entity)"
                 *ngFor="let entity of hirings">
                <strong>{{entity}}</strong>
            </div>
        </div>
    </div>
    <div class="buttons">
        <button mat-raised-button
                class="planning-button confirm"
                color="primary"
                [disabled]="(Step.Vehicle !== activeStep || creating)"
                *ngIf="form.value.type === 'user' && !(Step.Project === activeStep && optionalProject)"
                (click)="createRealisation()">
            Toevoegen
        </button>

        <button mat-raised-button
                class="planning-button confirm"
                color="primary"
                *ngIf="form.value.type === 'user' && Step.Project === activeStep && optionalProject"
                (click)="selectProject(null)">
            Zonder project
        </button>
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
