export class EntityType {
    id: number;
    name: string;
    plural_name: string;
    icon: string;
    order: number;
    is_team: boolean;
    has_driver: boolean;
    has_lowloader: boolean;
    has_truck: boolean;
    has_preferred_team: boolean;
    has_employees: boolean;
    has_default_asphalt_performer: boolean;
    has_default_performer: boolean;
    force_wide: boolean;
    visible_overview: boolean;
    visible_transport: boolean;
    visible_small: boolean;
    afas_name: string;

    plural?: {
        order: number;
    };

    belongs_to: number;

    public constructor(init?: Partial<EntityType>) {
        Object.assign(this, init);
    }
}

export enum EntityTypeCode {
    Set = 430001,
    Cutter = 430002,
    Wipetruck = 430003,
    Truck = 430004,
    AsfaltTeam = 430005,
    Dumper = 430006,
    HirePeople = 430007,
    LowLoader = 430008,
    ProjectTeam = 430009,
    PreparationTeam = 430010,
    AsfaltTruck = 430011,
    CutterTruck = 430012,
    Crane = 430014,
    Roller = 430015,
    StickyWagon = 430022,
    Keet = 430016,
    AsphaltOther = 430018,
    Other = 430019,
    Trilplaat = 430020,
    Verkeersmaatregel = 430021,
    InhuurPersoneel = 430028,
    VkmMateriaal = 430031,
    VkmVoorbereiding = 430032,
    VKMWerkopdracht = 430033,
    VKMPloeg = 430034,
    VKMVakman = 430035
}

export enum EntityId {
    HiringEmployee = 1000,
    HiringMaterials = 1001
}
