<table class="table">
    <thead>
    <tr>
        <th colspan="2">Bestuurder</th>
        <th width="100">Materieel</th>
        <th colspan="2">Ingepland</th>
    </tr>
    </thead>
    <ng-container *ngFor="let type of types">
        <thead *ngIf="typeMap.get(type.id)?.length > 0">
        <tr>
            <th class="text-center" colspan="5">{{type.name}}</th>
        </tr>
        </thead>
        <tr *ngFor="let entity of typeMap.get(type.id)">
            <td [class.downlight]="!planningMap.get(entity.id) && usedDrivers.indexOf(entity.driver_user_id) !== -1">
                <ng-container *ngIf="!planningMap.get(entity.id)">
                    {{(users | byId: entity.driver_user_id)?.name}}
                </ng-container>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    {{(users | byId: phe.driver_user_id)?.name}}<br>
                </ng-container>
            </td>
            <td (click)="addOrRemoveLeaveFromEntity(entity)"
                (contextmenu)="contextUserId = entity.driver_user_id;"
                [cdkContextMenuDisabled]="!(users | byId: entity.driver_user_id)"
                [cdkContextMenuTriggerFor]="context_menu">
                <div class="d-inline-flex flex-column align-center">
                    <ng-container *ngIf="!planningMap.get(entity.id) && leaveDayUserMap.has(entity.driver_user_id)">
                        <i matTooltip="{{leaveDayUserMap.get(entity.driver_user_id)?.hourtype | hourtype}} - {{leaveDayUserMap.get(entity.driver_user_id)?.comment}}"
                           [matTooltipShowDelay]="0"
                           class="{{hourtypeIconMap.get(leaveDayUserMap.get(entity.driver_user_id).hourtype)}}"></i>
                        <i *ngIf="blockedMap?.has(entity.driver_user_id)" class="fa-regular {{blockedMap.get(entity.driver_user_id).icon}}"></i>
                    </ng-container>
                    <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                        <i (contextmenu)="contextUserId = phe.driver_user_id;"
                           [cdkContextMenuTriggerFor]="context_menu"
                           matTooltip="{{leaveDayUserMap.get(phe.driver_user_id)?.hourtype | hourtype}} - {{leaveDayUserMap.get(phe.driver_user_id)?.comment}}"
                           [matTooltipShowDelay]="0"
                           *ngIf="leaveDayUserMap.has(phe.driver_user_id)"
                           class="{{hourtypeIconMap.get(leaveDayUserMap.get(phe.driver_user_id).hourtype)}}"></i>
                        <i *ngIf="blockedMap?.has(phe.driver_user_id)"
                           [matTooltip]="blockedMap.get(phe.driver_user_id).description"
                           [matTooltipShowDelay]="0"
                           class="fa-regular {{blockedMap.get(phe.driver_user_id).icon}}"></i>
                    </ng-container>
                </div>
            </td>
            <td>
                <div class="entity-name">
                    {{entity.name | licensePlate}}
                </div>
            </td>
            <td>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    {{phe.afas_project_id ?? (plannings | byId : phe.planning_id)?.afas_project_id ?? (plannings | byId : phe.planning_id)?.worknumber ?? '???'}}
                    <br>
                </ng-container>
            </td>
            <td>
                <ng-container *ngFor="let phe of planningMap.get(entity.id)">
                    <ng-container *ngIf="Utils.getDateDate(phe.begindate) !== Utils.getDateDate(currentDate)">
                        {{phe.begindate | date : 'EEE'}}
                    </ng-container>
                    {{phe.begindate | date : 'HH:mm'}}-{{phe.enddate | date : 'HH:mm'}}<br>
                </ng-container>
            </td>
        </tr>
        <ng-container *ngIf="type.id === 430004">
            <thead>
            <tr>
                <th class="text-center" colspan="5">Niet ingeplande chauffeurs & machinisten</th>
            </tr>
            </thead>
            <ng-container *ngFor="let driver of drivers">
                <tr *ngIf="usedDrivers.indexOf(driver.id) === -1">
                    <td>
                        {{driver.name}}
                    </td>
                    <td (click)="addOrRemoveLeave(driver.id)"
                        (contextmenu)="contextUserId = driver.id;"
                        [cdkContextMenuTriggerFor]="context_menu">
                        <ng-container *ngIf="leaveDayUserMap.has(driver.id)">
                            <i matTooltip="{{leaveDayUserMap.get(driver.id)?.hourtype | hourtype}} - {{leaveDayUserMap.get(driver.id)?.comment}}"
                               [matTooltipShowDelay]="0"
                               class="{{hourtypeIconMap.get(leaveDayUserMap.get(driver.id).hourtype)}}"></i>
                        </ng-container>
                        <i *ngIf="blockedMap?.has(driver.id)"
                           [matTooltip]="blockedMap.get(driver.id).description"
                           [matTooltipShowDelay]="0"
                           class="fa-regular {{blockedMap.get(driver.id).icon}}"></i>
                    </td>
                    <td colspan="3">
                        {{driver.function}}
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
</table>

<ng-template #context_menu>
    <div class="context-menu" cdkMenu>
        <div class="menu-title">
            {{(users | byId: contextUserId)?.name}}
        </div>
        <ng-container *ngIf="(users | byId: contextUserId)?.afas_employee_id?.substring(0, 1) !== 'Z'">
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.frost"
                    (click)="setHourType(RealisationHourtype.frost)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.frost)}}"></i> Vorst
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.paid_leave"
                    (click)="setHourType(RealisationHourtype.paid_leave)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.paid_leave)}}"></i> Doorbetaald verlof
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.short_absence"
                    (click)="setHourType(RealisationHourtype.short_absence)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.short_absence)}}"></i> Kort verzuim
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.education"
                    (click)="setHourType(RealisationHourtype.education)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.education)}}"></i> Scholing
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.bank_holiday"
                    (click)="setHourType(RealisationHourtype.bank_holiday)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.bank_holiday)}}"></i> Feestdag
            </button>
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.sleep"
                    (click)="setHourType(RealisationHourtype.sleep)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.sleep)}}"></i> Slapen
            </button>
        </ng-container>
        <ng-container *ngIf="(users | byId: contextUserId)?.afas_employee_id?.substring(0, 1) === 'Z'">
            <button cdkMenuItem class="menu-item"
                    [class.active]="currentHourType === RealisationHourtype.zzp_leave"
                    (click)="setHourType(RealisationHourtype.zzp_leave)">
                <i class="{{hourtypeIconMap.get(RealisationHourtype.zzp_leave)}}"></i> ZZP N/b
            </button>
        </ng-container>
    </div>
</ng-template>
