import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../services/auth/auth.service';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {MatDialog} from '@angular/material/dialog';
import {DialogService} from '../services/dialog/dialog.service';
import {UsersOverviewComponent} from '../users/users-overview/users-overview.component';
import {PlanningListComponent} from '../planning-list/planning-list.component';
import {AsphaltExportDialogComponent} from '../asphalt-export-dialog/asphalt-export-dialog.component';
import {EntitiesOverviewComponent} from '../entities-overview/entities-overview.component';
import {AsphaltComponent} from '../asphalt/asphalt.component';
import {HirePeopleDialogComponent} from '../hire-people-dialog/hire-people-dialog.component';
import {ProjectmanagerReportDialogComponent} from '../projectmanager-report-dialog/projectmanager-report-dialog.component';
import {HiringCompaniesDialogComponent} from '../hiring-companies-dialog/hiring-companies-dialog.component';
import {HiredCompaniesDialogComponent} from '../hired-companies-dialog/hired-companies-dialog.component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {ViewPdfDialogComponent} from '../view-pdf-dialog/view-pdf-dialog.component';
import {CodaltComponent} from '../codalt.component';
import {ToolboxService} from '../services/toolbox.service';
import {ToolboxComponent} from '../toolbox/toolbox.component';
import {LocalStorage} from '../storage.class';
import {PastToolboxesDialogComponent} from '../past-toolboxes-dialog/past-toolboxes-dialog.component';

@Component({
    selector: 'app-settings-menu',
    templateUrl: './settings-menu.component.html',
    styleUrls: ['./settings-menu.component.scss']
})
export class SettingsMenuComponent extends CodaltComponent implements OnInit {

    @Output()
    public selectedTeams = new EventEmitter<number[]>();

    @Input() date: Date;
    @Input() projectId: string;
    @Input() showPlanningFilter = false;

    viewModel = new HomeViewModel();
    authenticatedUser: User;

    mobile = false;
    open = false;

    location = location;

    constructor(public dialog: MatDialog,
                public dialogService: DialogService,
                private toolboxService: ToolboxService,
                private confirmDialogService: ConfirmDialogService,
                private breakpointObserver: BreakpointObserver,
                private authService: AuthService) {
        super();
    }

    ngOnInit() {
        this.viewModel.updateViewStateMenu();
        this.authService.getUserDetails().subscribe(user => {
            this.authenticatedUser = user.data;
        });
        this.breakpointObserver.observe('(max-width: 722px)').subscribe(match => {
            this.mobile = match.matches;
        });
    }

    logout() {
        this.confirmDialogService.confirm('Uitloggen', 'Weet je zeker dat je wilt uitloggen?', 'Uitloggen', 'Hier blijven').then(() => {
            this.authService.logout();
        }, () => {
        });
    }

    exportAsphalt() {
        this.dialogService.open(AsphaltExportDialogComponent);
    }

    projectmanagerReport() {
        this.dialogService.open(ProjectmanagerReportDialogComponent, null, {minHeight: '300px'});
    }

    planningList() {
        this.dialogService.open(PlanningListComponent);
    }

    users() {
        this.dialogService.open(UsersOverviewComponent);
    }

    entities() {
        this.dialogService.open(EntitiesOverviewComponent);
    }

    asphalt() {
        this.dialogService.open(AsphaltComponent);
    }

    hirePeople() {
        this.dialogService.open(HirePeopleDialogComponent);
    }

    hiringCompanies() {
        this.dialogService.open(HiringCompaniesDialogComponent);
    }

    hiredCompanies() {
        this.dialogService.open(HiredCompaniesDialogComponent);
    }

    showToolbox() {
        this.subscriptions.add(this.toolboxService.getCurrentToolbox().subscribe(toolbox => {
            this.dialog.open(ViewPdfDialogComponent, {
                panelClass: 'toolbox-pdf-dialog',
                width: '99vw',
                maxWidth: '1300px',
                height: '99vh',
                minHeight: '500px',
                maxHeight: '99vh',
                disableClose: true,
                data: toolbox.data
            });
        }));
    }

    showPastToolboxes() {
        this.dialogService.open(PastToolboxesDialogComponent);
    }

    toolbox() {
        this.dialogService.open(ToolboxComponent);
    }
}

export class HomeViewModel {
    manageUsers = false;
    manageEntities = false;
    manageAsphalt = false;
    manageHiringCompanies = false;
    manageToolbox = false;
    manageProjects = false;
    manageMenu = false;

    checkHours = false;
    generalHourCheck = false;
    projectWeekReport = false;
    projectDayReport = false;

    settlementsAndAfas = false;
    leave = false;
    hoursMenu = false;

    planningMenu = false;
    exportAsphalt = false;
    reportHiringCompanies = false;
    planningList = false;
    hirePeople = false;
    projectmanagerReport = false;
    exportMenu = false;

    hourRegistration = false;
    periodSettlement = false;

    vkm = false;

    updateViewStateMenu() {
        this.vkm = UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.PROJECTMANAGER);
        this.manageUsers = UserService.userHasRights(UserType.WORKPLANNER);
        this.manageEntities = UserService.userHasRights(UserType.WORKPLANNER);
        this.manageAsphalt = UserService.userHasRights(UserType.ASPHALTCOORDINATOR);
        this.manageHiringCompanies = UserService.userHasRights(UserType.WORKPLANNER);
        this.manageToolbox = UserService.userHasRights(UserType.TOOLBOX);
        this.manageProjects = UserService.userHasRights(UserType.WORKPLANNER);
        this.manageMenu = this.manageUsers || this.manageEntities || this.manageAsphalt || this.manageHiringCompanies || this.manageToolbox || this.manageProjects;

        this.checkHours = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) || UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.ASPHALTEXECUTOR);
        this.generalHourCheck = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK);
        this.projectWeekReport = UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.PROJECTMANAGER);
        this.projectDayReport = UserService.userHasRights(UserType.EXECUTOR) || UserService.userHasRights(UserType.PROJECTMANAGER);
        this.settlementsAndAfas = UserService.userHasRights(UserType.SETTLEMENT);
        this.leave = UserService.userHasRights(UserType.WORKPLANNER);
        this.hoursMenu = this.checkHours || this.generalHourCheck || this.projectWeekReport || this.projectDayReport || this.settlementsAndAfas || this.leave;

        this.planningMenu = UserService.userHasRights(UserType.USER);

        this.exportAsphalt = UserService.userHasRights(UserType.ASPHALTCOORDINATOR);
        this.hirePeople = UserService.userHasRights(UserType.ASPHALTCOORDINATOR);
        this.reportHiringCompanies = UserService.userHasRights(UserType.WORKPLANNER);
        this.projectmanagerReport = UserService.userHasRights(UserType.ADMIN);
        this.exportMenu = this.exportAsphalt || this.hirePeople || this.reportHiringCompanies || this.projectmanagerReport;

        this.hourRegistration = UserService.userHasRights(UserType.HOUR_REGISTER);
        this.periodSettlement = !this.settlementsAndAfas && !UserService.officeWorkerFunctions.includes(LocalStorage.user?.function);
    }
}
