import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {formatDate} from '@angular/common';
import {User} from '../classes/user.class';
import {Utils} from '../utils.class';
import {LocalStorage} from '../storage.class';
import {UserType} from './user/user.service';
import {Project} from '../classes/project.class';
import {Entity} from '../classes/entity.class';

@Injectable({
    providedIn: 'root'
})
export class RealisationService {

    static hourtypeIconMap = new Map<RealisationHourtype, string>([
        [RealisationHourtype.frost, 'fa-light fa-snowflake'],
        [RealisationHourtype.time_for_time, 'fa-solid fa-timer'],
        [RealisationHourtype.day_off, 'fa-solid fa-person-walking-arrow-right'],
        [RealisationHourtype.short_absence, 'fa-regular fa-tooth'],
        [RealisationHourtype.paid_leave, 'fa-solid fa-person-walking-arrow-right'],
        [RealisationHourtype.illness, 'fa-regular fa-face-thermometer'],
        [RealisationHourtype.education, 'fa-solid fa-book'],
        [RealisationHourtype.bank_holiday, 'fa-solid fa-party-horn'],
        [RealisationHourtype.sleep, 'fa-light fa-face-sleeping'],
        [RealisationHourtype.zzp_leave, 'fa-solid fa-xmark'],
    ]);

    static hourtypeIconMapDuotone = new Map<RealisationHourtype, string>([
        [RealisationHourtype.frost, 'fa-duotone fa-snowflake'],
        [RealisationHourtype.time_for_time, 'fa-duotone fa-timer'],
        [RealisationHourtype.day_off, 'fa-duotone fa-person-walking-arrow-right'],
        [RealisationHourtype.short_absence, 'fa-duotone fa-calendar-clock'],
        [RealisationHourtype.paid_leave, 'fa-duotone fa-calendar-range'],
        [RealisationHourtype.illness, 'fa-duotone fa-face-thermometer'],
        [RealisationHourtype.education, 'fa-duotone fa-school'],
        [RealisationHourtype.bank_holiday, 'fa-duotone fa-party-horn'],
        [RealisationHourtype.sleep, 'fa-duotone fa-face-sleeping'],
        [RealisationHourtype.worktime, 'fa-duotone fa-person-digging'],
        [RealisationHourtype.driving_to, 'fa-duotone fa-truck'],
        [RealisationHourtype.driving_back, 'fa-duotone fa-truck'],
        [RealisationHourtype.travel_to, 'fa-duotone fa-car-side'],
        [RealisationHourtype.travel_back, 'fa-duotone fa-car-side'],
        [RealisationHourtype.special_leave, 'fa-duotone fa-baby'],
        [RealisationHourtype.additional_parental, 'fa-duotone fa-sparkles'],
        [RealisationHourtype.zzp_leave, 'fa-duotone fa-xmark'],
    ]);

    constructor(protected apiService: ApiService) {

    }

    getUserDay(userId, date: Date) {
        return this.apiService.getCall$<{
            realisations: Realisation[],
            team: Entity
        }>(`realisation/user/${userId}/${formatDate(date, 'yyyy-MM-dd', 'nl')}`);
    }

    getWeekReportPdf(afasProjectId: string, period: number, year: number, onlyWeekTotals: boolean, noHours: boolean, noComments: boolean, noAsphalt: boolean) {
        return this.apiService.getBlobCall(`realisation/week-report-pdf/${afasProjectId}/${period}/${year}/${onlyWeekTotals}/${noHours}/${noComments}/${noAsphalt}`);
    }

    getProjectsPeriod(beginDate: Date, endDate: Date, alwaysInclude?: string, searchProjects?: string) {
        let params = {
            beginDate: new Date(beginDate).toISOString(),
            endDate: new Date(endDate).toISOString(),
            alwaysInclude
        } as any;
        if (searchProjects) {
            params = {
                ...params,
                searchProjects
            }
        }
        return this.apiService.getCall$<Project[]>('realisation/period/projects', params);
    }

    getDayEntities(date: Date, userId: number) {
        return this.apiService.getCall$<number[]>('realisation/day-entities', {
            date: formatDate(date, 'yyyy-MM-dd', 'nl'),
            userId
        });
    }

    public getDayExecutors(date: Date) {
        return this.apiService.getCall$<User[]>(`realisation/day-executors`, {
            date: formatDate(date, 'yyyy-MM-dd', 'nl')
        });
    }

    public getHistory(id: number) {
        return this.apiService.getCall$<Realisation[]>(`realisation/history`, {
            id
        });
    }

    getProjectSchedule(beginDate: Date, endDate: Date, afas_project_id?: string) {
        let params = {
            beginDate: new Date(beginDate).toISOString(),
            endDate: new Date(endDate).toISOString()
        } as any;
        if (afas_project_id) {
            params = {
                ...params,
                afas_project_id
            };
        }
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/project', params);
    }

    calcMaxDate(add?: number) {
        const maxDate = Utils.setTime(new Date(), 23, 59);
        if (!LocalStorage.getUser() || LocalStorage.getUser().groups.map(g => g.group).includes(UserType.TRUCK_DRIVER)) {
            maxDate.setDate(maxDate.getDate() + 1);
            while (maxDate.getDay() < 1 || maxDate.getDay() > 5) {
                maxDate.setDate(maxDate.getDate() + 1);
            }
        } else {
            maxDate.setDate(maxDate.getDate() + (7 - maxDate.getDay()));
            const nextWeekFromDay = 5;
            if (new Date().getDay() >= nextWeekFromDay) {
                maxDate.setDate(maxDate.getDate() + 7);
            }
            if (add) {
                maxDate.setDate(maxDate.getDate() + add);
            }
        }
        return maxDate;
    }

    getDaySchedule(date, executorId?: number) {
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/day', {
            date: formatDate(new Date(date), 'yyyy-MM-dd', 'nl'),
            executorId: executorId ?? ''
        });
    }

    getDayScheduleSummary(date, executorId?: number) {
        return this.apiService.getCall$<RealisationDaySummary[]>('realisation/schedule/day', {
            date: formatDate(new Date(date), 'yyyy-MM-dd', 'nl'),
            executorId: executorId ?? '',
            summary: true
        });
    }

    getPreviousDayOpen(date, executorId?: number, nextDay?: boolean) {
        return this.apiService.getCall$<RealisationCount[]>('realisation/schedule/previous-open', {
            date: formatDate(new Date(date), 'yyyy-MM-dd', 'nl'),
            executorId: executorId ?? '',
            nextDay
        });
    }

    getUserSchedule(beginDate: Date, endDate: Date, userId?: number) {
        let params = {
            beginDate: formatDate(new Date(beginDate), 'yyyy-MM-dd', 'nl'),
            endDate: formatDate(new Date(endDate), 'yyyy-MM-dd', 'nl')
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.getCall$<Realisation[]>('realisation/schedule/user', params);
    }

    getUserDaySchedule(date, userId?: number) {
        let params = {
            date: new Date(date).toISOString()
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.getCall$<{
            realisations: Realisation[],
            weekTotal: DayWeekTotal,
            dayTotal: DayWeekTotal,
            user?: User
        }>('realisation/schedule/user/day', params);
    }

    submitRealisation(realisations: Realisation[]) {
        return this.apiService.postCall$('realisation/schedule/submit', {realisations});
    }

    saveRealisation(realisation: Realisation) {
        return this.apiService.postCall$<{
            realisation: Realisation,
            weekTotal: DayWeekTotal,
            dayTotal: DayWeekTotal
        }>('realisation/schedule/save', realisation);
    }

    approveRealisation(realisation: Realisation) {
        return this.apiService.postCall$<Realisation[]>('realisation/schedule/approve-v2', realisation);
    }

    hiringnames(type: 'entity' | 'user' | 'hiring_material' | 'hiring_user', search: string) {
        return this.apiService.getCall$<string[]>('realisation/hiringnames', {type: type ?? '', search: search ?? ''});
    }

    changePause(date: Date, pause: number, userId?: number) {
        let params = {
            date,
            pause
        } as any;
        if (userId) {
            params = {
                ...params,
                userId
            };
        }
        return this.apiService.postCall$<{
            realisations: Realisation[],
            weekTotal: DayWeekTotal,
            dayTotal: DayWeekTotal
        }>('realisation/schedule/pause', params);
    }
}

interface DayWeekTotal {
    minutes: number;
    pause: number;
};

export class RealisationCount {
    bookdate: Date;
    count: number;
}

export class RealisationDaySummary {
    bookdate: Date;
    count: number;
    afas_project_id: string;
    projects: Project[];
}
