import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Planning} from '../../../classes/planning.class';
import {Entities} from '../../../classes/entities';
import {PlanningService} from '../../../services/planning/planning.service';
import {PlanningDayItem} from '../../../classes/planning-day-item.class';
import {combineLatest, Subscription} from 'rxjs';
import {PlanningOverviewViewModel} from '../../planning-overview-view-model';
import {Utils} from '../../../utils.class';
import {EntityUnavailableService} from '../../../services/entities/entity-unavailable.service';
import {EntityUnavailable} from '../../../classes/entityunavailable.class';
import {PlanningAsfaltteam} from '../../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../../classes/planning-has-entity.class';
import {DialogService} from '../../../services/dialog/dialog.service';
import {EntityDetailComponent} from '../../../entities-overview/entity-detail/entity-detail.component';
import {Settings} from '../../../settings.class';
import {PlanningSet} from '../../../classes/planningset.class';
import {PlanningDumper} from '../../../classes/planningdumper.class';
import {PlanningStatus} from '../../../planning-status.enum';
import {Router} from '@angular/router';
import {EntityTypeCode} from '../../../services/entities/entity-type.class';

@Component({
    selector: 'app-entity',
    templateUrl: './entity.component.html',
    styleUrls: ['./entity.component.scss']
})
export class EntityComponent implements OnInit, OnDestroy {

    PlanningStatus = PlanningStatus;

    public hourChoose = null;
    @Input()
    public viewModel: PlanningOverviewViewModel;
    @Input()
    public date: Date;
    @Input()
    public item: Entities;
    @Input()
    public isFixed: boolean;
    @Output()
    public openPlanning = new EventEmitter<Planning>();

    @HostBinding('class.use-multiple') useMultiple: boolean;

    public defaultDuration = Settings.DEFAULT_DURATION_OTHER;

    public mainPlanning = new Map<Planning, PlanningCutter | PlanningHasEntity | PlanningAsfaltteam | PlanningSet | PlanningDumper>();

    public tons = new Map<number, number>();
    public night = new Map<number, boolean>();
    public trucks = new Map<number, number>();
    public people = new Map<number, number>();

    public highlightedPlanning: Planning;
    public isTeam: boolean = null;
    @ViewChild('timeChooseDiv')
    private timeChooseDiv: ElementRef;
    private subscriptions = new Subscription();

    constructor(private planningService: PlanningService,
                private entityUnavailableService: EntityUnavailableService,
                private router: Router,
                private dialogService: DialogService) {
    }

    @HostListener('mouseleave', ['$event']) mouseleave(event) {
        this.hourChoose = null;
    }

    @HostListener('mousemove', ['$event']) mousemove(event) {
        if (event.target.localName === 'app-entity') {
            const hour = Math.floor(24 * (event.offsetX / event.target.offsetWidth));
            const offset = (hour * 60) - (1 * 60);
            this.hourChoose = offset > 0 ? offset : 0;
        }
    }

    @HostListener('click', ['$event']) onClick(event) {

        if (event.target.localName === 'app-entity'
            && this.viewModel.addPlanning
            && (this.viewModel.addPlanningFixed || !this.isFixed)) {
            const classes = this.timeChooseDiv.nativeElement.classList.value;
            const time = classes.split('offset-');
            if (time && time.length > 1) {
                const chooseBeginDate = new Date(this.date);
                const chooseEndDate = new Date(this.date);
                let duration = this.defaultDuration;
                let hour = this.isTeam ? Settings.DEFAULT_START : +(time[1].split(' ')[0] / 60);
                const getFrom = Utils.setTime(new Date(chooseBeginDate), 0, 0);
                const getTo = Utils.setTime(new Date(chooseEndDate), 23, 59);
                getTo.setDate(getTo.getDate() + 1);

                const planning$ = this.planningService.getFilteredList(getFrom, getTo);
                const unavailable$ = this.entityUnavailableService.getFilteredList(getFrom, getTo);
                const subs = combineLatest(planning$, unavailable$).subscribe(([planningList, unavailableList]) => {
                    chooseBeginDate.setHours(hour);
                    chooseEndDate.setHours(hour);
                    chooseEndDate.setMinutes(chooseEndDate.getMinutes() + (60 * duration));
                    if (this.item.entity.use_once) {
                        planningList.forEach(planning => {
                            Utils.planningAllEntities(planning).filter(p => p.entity_id === this.item.entity.id).forEach(planningItem => {
                                const begin = new Date(planningItem.begindate);
                                const end = new Date(planningItem.enddate);
                                duration = this.calculateDuration(chooseBeginDate, end, chooseEndDate, duration, begin);
                                hour = this.calculateHours(chooseBeginDate, end, chooseEndDate, hour);
                            });
                        });
                        if (this.item.entity.enddate) {
                            const unav = new EntityUnavailable();
                            unav.begindate = this.item.entity.enddate;
                            unav.enddate = getTo;
                            unav.entity_id = this.item.entity.id;
                            unavailableList.push(unav);
                        }
                        unavailableList.filter(u => u.entity_id === this.item.entity.id).forEach(unavailable => {
                            const begin = new Date(unavailable.begindate);
                            const end = new Date(unavailable.enddate);
                            duration = this.calculateDuration(chooseBeginDate, end, chooseEndDate, duration, begin);
                            hour = this.calculateHours(chooseBeginDate, end, chooseEndDate, hour);
                        });
                    }

                });
                subs.unsubscribe();
                this.createPlanning(hour, duration);
            }
        }
    }

    ngOnInit() {
        /**
         * This component has multiple planningEntity's in the item: Entities array
         * Every EntityPlanning has a parent Planning
         * This component only shows the planningEntity's for one date and a specific entityType
         * This subscription provides info if some planningEntity is hovered.
         * If the planning object is the same, we highlight this planningEntity (see view)
         */
        this.isTeam = this.item.entity.entitytypes[0]?.is_team;
        this.useMultiple = !this.item.entity.use_once && this.item.items.length > 1;

        this.subscriptions.add(this.planningService.mouseEnterPlanning.subscribe(planning => this.highlightedPlanning = planning));
        this.subscriptions.add(this.planningService.mouseLeavePlanning.subscribe(planning => {
            if (this.highlightedPlanning === planning) {
                this.highlightedPlanning = null;
            }
        }));

        this.item.items.forEach(pln => {
            let tons = pln.planning.planning_cutters.map(cutter => cutter.tons).reduce((sum, current) => sum + current, 0);
            tons += pln.planning.asphalt_list.map(asphalt => asphalt.tons).reduce((sum, current) => sum + current, 0);
            this.tons.set(pln.planning.id, tons);
            const mainPlanning = this.planningService.getMainPlanning(pln.planning);
            this.mainPlanning.set(pln.planning, mainPlanning);
            this.night.set(pln.planning.id, Utils.isNight(mainPlanning.begindate, mainPlanning.enddate));

            // Calculate max used trucks
            const from = new Date(mainPlanning.begindate);
            const to = new Date(from);
            from.setDate(from.getDate() - 2);
            to.setDate(to.getDate() + 2);
            const usedTrucks = Utils.getCountOfUsedTrucks([pln.planning], from, to);
            this.trucks.set(pln.planning.id, usedTrucks);
            this.people.set(pln.planning.id, pln.planning.user_planning?.length ?? 0);
        });
        this.defaultDuration = this.item.entity.entitytypes[0]?.is_team ? Settings.DEFAULT_DURATION_TEAM : Settings.DEFAULT_DURATION_OTHER;
    }

    open(pln: PlanningDayItem) {
        if (this.planningService.getMainPlanning(pln.planning).entity.entitytypes.map(e => e.id).includes(EntityTypeCode.VKMWerkopdracht)) {
            this.router.navigateByUrl(`vkm/${pln.planning.id}`);
        } else {
            this.openPlanning.emit(pln.planning);
        }
    }

    createPlanning(hour, duration) {
        const fromDate = new Date(this.date);
        const toDate = new Date(this.date);
        fromDate.setHours(hour);
        toDate.setHours(hour);
        toDate.setMinutes(toDate.getMinutes() + (60 * duration));

        if (this.item.entity.entitytypes[0].id === EntityTypeCode.VKMWerkopdracht) {
            this.router.navigate([`vkm/new`, {fromDate: fromDate.toISOString(), toDate: toDate.toISOString()}]);
        } else {
            const planning = this.planningService.newPlanningByType(this.item.entity.entitytypes[0].id);
            const mainPlanning = this.planningService.getMainPlanning(planning);
            mainPlanning.begindate = fromDate;
            mainPlanning.enddate = toDate;
            mainPlanning.entity = this.item.entity;
            mainPlanning.entity_id = this.item.entity.id;
            this.openPlanning.emit(planning);
        }
    }

    mouseEnter(pln: PlanningDayItem) {
        this.planningService.mouseEnterPlanning.emit(pln.planning);
    }

    mouseLeave(pln: PlanningDayItem) {
        this.planningService.mouseLeavePlanning.emit(pln.planning);
    }

    openEntityEdit() {
        if (this.viewModel.openEntity) {
            this.dialogService.open(EntityDetailComponent, this.item.entity);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private calculateHours(chooseBeginDate: Date, end: Date, chooseEndDate: Date, hour: number) {
        if (chooseBeginDate.getTime() < end.getTime() && chooseEndDate.getTime() > end.getTime()) {
            hour = hour + (((end.getTime() - chooseBeginDate.getTime()) / 36e5));
        }
        return hour;
    }

    private calculateDuration(chooseBeginDate: Date, end: Date, chooseEndDate: Date, duration: number, begin: Date) {
        if (chooseBeginDate.getTime() < end.getTime() && chooseEndDate.getTime() > end.getTime()) {
            duration = duration - (((end.getTime() - chooseBeginDate.getTime()) / 36e5));
        } else if (begin.getTime() < chooseEndDate.getTime() && end.getTime() > chooseBeginDate.getTime()) {
            duration = duration - (((chooseEndDate.getTime() - begin.getTime()) / 36e5));
        }
        return duration;
    }

}
