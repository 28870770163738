import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {PlanningService} from '../services/planning/planning.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService, UserType} from '../services/user/user.service';
import {combineLatest, concat, from, merge, of, Subscription} from 'rxjs';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {User} from '../classes/user.class';
import {UserPlanning} from '../classes/user-planning';
import {LocationService} from '../services/location.service';
import {UserPlanningService} from '../services/planning/user-planning.service';
import {PlanningDetailDialogService} from '../services/dialog/planning-detail-dialog.service';
import {EntitiesService} from '../services/entities/entities.service';
import {AfasService} from '../services/afas.service';
import {PlanningHasEntity} from '../classes/planning-has-entity.class';
import {Entity} from '../classes/entity.class';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {ProjectService} from '../services/project.service';
import {Project} from '../classes/project.class';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AddProjectDialogComponent} from './add-project-dialog/add-project-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {EntityId, EntityType} from '../services/entities/entity-type.class';
import {Settings} from '../settings.class';
import {environment} from '../../environments/environment';
import {PlanningHasService} from '../services/planning/planning-has.service';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {LeaveService} from '../services/leave.service';
import {Leave} from '../classes/leave';
import {RealisationHourtype} from '../classes/realisation';
import {RealisationService} from '../services/realisation.service';
import {AddLeaveNameDialogComponent, returnData} from './add-leave-name-dialog/add-leave-name-dialog.component';
import {EmployeeDayInfoDialogComponent} from './employee-day-info-dialog/employee-day-info-dialog.component';

@Component({
    selector: 'app-employee-planning',
    templateUrl: './employee-planning.component.html',
    styleUrls: ['./employee-planning.component.scss']
})
export class EmployeePlanningComponent extends CodaltComponent implements OnInit {

    readonly formatDate = formatDate;

    readonly RealisationHourtype = RealisationHourtype;
    hourtypeIconMap = RealisationService.hourtypeIconMap;

    fcNightPlanning = new FormControl();

    fcProjectIds = new FormControl<string[]>();
    fcPerformerIds = new FormControl<number[]>();

    userDatesMap = new Map<number, string[]>();

    projects: Project[];
    planningList: Planning[];
    usersMap: Map<number, User>;
    entitiesMap: Map<number, Entity>;
    entityTypeMap: Map<number, EntityType>;
    entities: Entity[];
    leaveData: Leave[];
    leaveList: Leave[];

    print = false;
    fromDate: Date;
    toDate: Date;
    disableNext = false;
    week: number;
    year: number;

    EntityId = EntityId;
    downloadingPdf = false;

    projectDaysList: ProjectDay[];
    projectDaysListFiltered: ProjectDay[];

    smallMaterial: Entity[];

    addingHiringForEntityId: number;
    entityHiringNames = new Map<number, string[]>();
    hireEntities: Entity[];
    fcAddHiring = new FormControl<string>();
    @ViewChild('addHiringInp') addHiringInput: ElementRef;
    entityWeekUsedDays: Map<string, string[]> = new Map<string, string[]>();
    hiringAutocompleteOptions: string[];
    uniqueProjectsIds: string[];

    users: User[];
    teams: {
        entityType: EntityType,
        entity: Entity,
        users: User[],
        showUsers: boolean
    }[];

    projectManagers: User[];
    executors: User[];
    filteredExecutors: User[];

    dateHasUserMap = new Map<string, boolean>();
    projectDayTeamMap: Map<string, Planning> = new Map<string, Planning>();

    projectDayMaterialMap: Map<string, PlanningHasEntity[]> = new Map<string, PlanningHasEntity[]>();

    dayUserMap: Map<string, string> = new Map<string, string>();

    projectDayUserMap: Map<string, {
        type: 'userPlanning' | 'planning' | 'project',
        userPlanning?: UserPlanning[],
        planningHas?: PlanningHasEntity[]
    }> = new Map<string, { type: 'userPlanning' | 'planning' | 'project'; userPlanning?: UserPlanning[]; planningHas?: PlanningHasEntity[] }>();
    userWeekCount: Map<number, number> = new Map<number, number>();
    blockedMap: Map<string, { icon: string; description: string; startDate: Date; endDate: Date; }>;

    allUsers: User[];
    leaveDays: Date[];
    leaveDayUserMap: Map<string, Leave> = new Map<string, Leave>();
    currentHourType: RealisationHourtype;
    contextUser: User;
    contextDay: Date;
    contextComment = 'Personeelsplanning';
    contextProject: Project;

    leavesToSave: Leave[] = [];
    planningsToSave: Planning[] = [];
    userPlanningsToSave: UserPlanning[] = [];
    planningHasToSave: PlanningHasEntity[] = [];
    formDirty = false;
    saving = false;
    rollingBack = false;
    userPlannings: UserPlanning[];
    planningHass: PlanningHasEntity[];
    periodSubscriptions: Subscription;

    addresses: {
        addressLine,
        adminDistrict,
        adminDistrict2,
        countryRegion,
        formattedAddress,
        locality,
        postalCode
    }[];

    highlightDay: Date;
    highlightProject: string;
    highlight: string;

    readonly = !UserService.userHasRights(UserType.EMPLOYEE_PLANNING);

    constructor(private confirmDialogService: ConfirmDialogService,
                private planningService: PlanningService,
                private projectService: ProjectService,
                private activatedRoute: ActivatedRoute,
                private locationService: LocationService,
                private userPlanningService: UserPlanningService,
                private afasService: AfasService,
                private planningDetailDialogService: PlanningDetailDialogService,
                private entitiesService: EntitiesService,
                private router: Router,
                private dialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private userService: UserService,
                private planningHasService: PlanningHasService,
                private leaveService: LeaveService) {
        super();
    }

    private filterProjectDaysList() {
        const projectIds = this.fcProjectIds.value ?? [];
        const performerIds = this.fcPerformerIds.value ?? [];
        this.projectDaysListFiltered = this.projectDaysList.filter(pdl => {
            return (projectIds.length === 0 || projectIds.includes(pdl.project?.afas_project_id)) &&
                (performerIds.length === 0 || performerIds.includes(pdl.project?.performer_id));
        });
        this.filteredExecutors = this.executors.filter(e => this.projectDaysList.map(pdl => pdl.project.performer_id).includes(e.id));
    }

    ngOnInit(): void {
        this.subscriptions.add(this.activatedRoute.queryParams.subscribe((q: { print: string }) => {
            this.print = q.print === 'true';
        }));
        this.subscriptions.add(merge(this.fcPerformerIds.valueChanges, this.fcProjectIds.valueChanges).pipe(distinctUntilChanged()).pipe(debounceTime(100)).subscribe(() => {
            this.filterProjectDaysList();
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { period, year }) => {
            this.clearMaps();
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            const year = monday.getFullYear();
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            const beginDate = new Date(monday);

            const period = +(params.period ?? formatDate(new Date(), 'w', 'nl'));
            beginDate.setDate(beginDate.getDate() + (7 * (period - 1)));

            const endDate = Utils.setTime(new Date(beginDate), 23, 59);
            endDate.setDate(endDate.getDate() + 6);

            this.week = period;
            this.year = period > 1 ? beginDate.getFullYear() : endDate.getFullYear();

            this.fromDate = beginDate;
            this.toDate = endDate;

            if (this.readonly) {
                const maxDate = new Date();
                // When after thursday 15:00, next week pls
                if (maxDate.getDay() > 4 || (maxDate.getDay() === 4 && maxDate.getHours() > 15)) {
                    maxDate.setDate(maxDate.getDate() + 7);
                }
                Utils.setTime(maxDate, 23, 59);
                maxDate.setDate(maxDate.getDate() + (7 - maxDate.getDay()));
                if (maxDate < this.toDate) {
                    this.prev();
                } else {
                    this.getData();
                }
                this.disableNext = Utils.dateFormatEquals(maxDate, this.toDate, 'yyyy-MM-dd');
            } else {
                this.getData();
            }

            this.leaveDays = [];
            const loopDate = new Date(this.fromDate);
            while (loopDate.getTime() < this.toDate.getTime()) {
                const day = Utils.setTime(new Date(loopDate), 0, 0);
                this.leaveDays.push(day);
                loopDate.setDate(loopDate.getDate() + 1);
            }

        }));
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.planningsToSave?.length > 0 || this.userPlanningsToSave?.length > 0 || this.planningHasToSave?.length > 0) {
            $event.returnValue = true;
        }
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.planningsToSave?.length > 0 || this.userPlanningsToSave?.length > 0 || this.planningHasToSave?.length > 0) {
                this.confirmDialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen verwerpen?`,
                    'Hier blijven',
                    'Wijzigingen verwerpen').then(() => {
                    resolve(false);
                }, () => {
                    this.userPlanningsToSave = [];
                    this.planningHasToSave = [];
                    this.planningsToSave = [];
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    mouseOverTeam(day: {
        day: Date,
        project: Project
    }, entity: Entity) {
        this.highlightProject = day.project?.afas_project_id;
        this.highlightDay = day.day;
        this.highlight = `t-${entity.id}-${day.day.getDate()}`;
    }

    mouseOver(day: {
        day: Date,
        project: Project
    }, user: User) {
        this.highlightProject = day.project?.afas_project_id;
        this.highlightDay = day.day;
        this.highlight = `u-${user.id}`;
    }

    mouseOverEntity(day: {
        day: Date,
        project: Project
    }, entity: Entity, nonUseOnceName: string = null) {
        this.highlightProject = day.project?.afas_project_id;
        this.highlightDay = day.day;
        this.highlight = `e-${entity.id}${nonUseOnceName ? '-' + nonUseOnceName : ''}`;
    }

    makeKey(date: Date, id: number, project: string, nonuseOnceName: string = null) {
        return `${formatDate(date, 'yyyy-MM-dd', 'nl')}-${id}-${project}${nonuseOnceName ? `-${nonuseOnceName}` : ''}`;
    }


    addEntityPlanning(event,
                      day: {
                          day: Date,
                          project: Project
                      },
                      entity: Entity,
                      nonUseOnceName: string = null,
                      silent = false) {
        event.preventDefault();
        if (this.readonly) {
            return;
        }

        const key = this.makeKey(day.day, entity.id, day.project?.afas_project_id, nonUseOnceName);
        let planningHasList = this.projectDayMaterialMap.get(key);
        const teamEntity = this.teams.find(team => this.projectDayTeamMap.has(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id)))?.entity;
        const planning = this.projectDayTeamMap.get(this.makeKey(day.day, teamEntity?.id, day.project?.afas_project_id));

        if (planningHasList?.length) {
            if (planningHasList?.length > 1 && !Utils.pheSame(planningHasList[0], planningHasList[1])) {
                if (!silent) {
                    this.confirmDialogService.confirm('Meer keren ingepland',
                        `Dit materieel is op deze dag meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                    });
                }
                return;
            } else if (!planningHasList[0].planning_id && !!planning && entity.entitytypes[0].visible_transport) {
                planningHasList[0].planning_id = planning.id;
                planningHasList[0].planning = planning;
                planningHasList[0].afas_project_id = null;
                if (!this.planningHasToSave.includes(planningHasList[0])) {
                    this.planningHasToSave.push(planningHasList[0]);
                }
            } else {
                if (nonUseOnceName) {
                    const dates = this.entityWeekUsedDays.get(nonUseOnceName + entity.id);
                    dates.splice(dates.indexOf(formatDate(day.day, 'yyyy-MM-dd', 'nl')), 1);
                    this.entityWeekUsedDays.set(nonUseOnceName + entity.id, dates);
                }
                let reAddUserPlanningToUser = null;
                if (planningHasList[0].driver_user_id) {
                    const user = this.users.find(u => u.id === planningHasList[0].driver_user_id);
                    //don't remove user, add as normal userPlanning
                    this.addUserPlanningProject(event, day, this.projectDayUserMap.get(this.makeKey(day.day, user.id, day.project?.afas_project_id)), user);
                    reAddUserPlanningToUser = user;
                }
                planningHasList[0].deleted_at = new Date();

                if (!this.planningHasToSave.includes(planningHasList[0])) {
                    this.planningHasToSave.push(planningHasList[0]);
                }
                if (Utils.pheSame(planningHasList[0], planningHasList[1])) {
                    planningHasList[1].deleted_at = new Date();

                    if (!this.planningHasToSave.includes(planningHasList[1])) {
                        this.planningHasToSave.push(planningHasList[1]);
                    }
                }
                this.projectDayMaterialMap.delete(key);
                if (reAddUserPlanningToUser) {
                    this.addUserPlanningProject(event, day, this.projectDayUserMap.get(this.makeKey(day.day, reAddUserPlanningToUser.id, day.project?.afas_project_id)), reAddUserPlanningToUser, true);
                }
            }
        } else {

            const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
            if (
                !this.projectDayMaterialMap.has(this.makeKey(day.day, entity.id, day.project?.afas_project_id, nonUseOnceName)) &&
                projectsIds.map(projectId => this.projectDayMaterialMap.has(this.makeKey(day.day, entity.id, projectId, nonUseOnceName))).find(p => p)
            ) {
                if (!silent) {
                    this.confirmDialogService.confirm(
                        'Al ingepland op deze dag',
                        nonUseOnceName ? `Deze persoon is al ingehuurd op deze dag. Dubbele inhuur is niet mogelijk.` :
                            `Het materieel is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                        'Sluiten', null).then(() => {
                    });
                }
                return;
            }

            const fromDate = planning ? new Date(Utils.mainPlanning(planning).begindate) : new Date(day.day);
            const toDate = planning ? new Date(Utils.mainPlanning(planning).enddate) : new Date(day.day);
            if (!planning) {
                const startTime = 60 * (this.fcNightPlanning.value ? Settings.DEFAULT_START_NIGHT : Settings.DEFAULT_START);
                fromDate.setMinutes(startTime);
                toDate.setMinutes(startTime);
                toDate.setMinutes(toDate.getMinutes() + (60 * Settings.DEFAULT_DURATION_OTHER));
            }

            const planningHas = new PlanningHasEntity();
            planningHas.entity_id = entity.id;
            planningHas.entitytype_id = entity.entitytypes[0].id;
            planningHas.entitytype = entity.entitytypes[0];
            planningHas.begindate = fromDate;
            planningHas.enddate = toDate;
            if (planning && [EntityId.HiringEmployee, EntityId.HiringMaterials].indexOf(entity.id) !== -1) {
                planningHas.planning = planning;
                planningHas.planning_id = planning.id;
            } else {
                planningHas.afas_project_id = day.project.afas_project_id;
            }
            planningHas.name = nonUseOnceName;
            let currentMaterial = this.projectDayMaterialMap.get(key);
            if (!currentMaterial) {
                currentMaterial = [];
            }
            if (entity.driver_user_id) {
                const userKey = this.makeKey(day.day, entity.driver_user_id, day.project?.afas_project_id);
                const projectDayUser = this.projectDayUserMap.get(userKey);
                if (projectDayUser) {
                    //remove user planning
                    const user = this.users.find(u => u.id === entity.driver_user_id);
                    this.addUserPlanningProject(event, day, this.projectDayUserMap.get(userKey), user);
                    //add user to planningHas
                    planningHas.driver_user_id = user.id;
                    this.dayUserMap.set(this.makeKey(day.day, user.id, ''), day.project?.afas_project_id);
                    this.projectDayUserMap.set(userKey, {
                        type: 'project',
                        planningHas: [planningHas],
                        userPlanning: []
                    });
                }
            }

            currentMaterial.push(planningHas);
            this.projectDayMaterialMap.set(key, currentMaterial);
            this.planningHasToSave.push(planningHas);

            if (nonUseOnceName) {
                const dates = this.entityWeekUsedDays.get(nonUseOnceName + entity.id) ?? [];
                dates.push(formatDate(day.day, 'yyyy-MM-dd', 'nl'));
                this.entityWeekUsedDays.set(nonUseOnceName + entity.id, dates);
            }
        }
        return true;
    }

    addProject() {
        const ref = this.dialog.open(AddProjectDialogComponent, {
            maxWidth: '400px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                currentProjects: [
                    ...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])
                ]
            }
        });
        this.periodSubscriptions.add(ref.afterClosed().subscribe(project => {
            if (project) {
                const projectDays = this.createProjectDays(project.afas_project_id, [project]);
                if (!this.uniqueProjectsIds.indexOf(project.afas_project_id)) {
                    this.uniqueProjectsIds.push(project.afas_project_id);
                    this.uniqueProjectsIds = this.uniqueProjectsIds.sort((a, b) => a.padEnd(8, '0').localeCompare(b.padEnd(8, '0')));
                }
                if (!this.projectDaysList) {
                    this.projectDaysList = [];
                }
                this.projectDaysList.splice(this.uniqueProjectsIds.indexOf(project.afas_project_id), 0, projectDays);
                this.filterProjectDaysList();
            }
        }));
    }

    addTeamPlanning(event,
                    day: {
                        day: Date,
                        project: Project
                    },
                    team: {
                        entityType: EntityType,
                        entity: Entity,
                        users: User[],
                        showUsers: boolean
                    }) {
        event.preventDefault();
        if (this.readonly) {
            return;
        }
        const key = this.makeKey(day.day, team.entity.id, day.project?.afas_project_id);
        let planning = this.projectDayTeamMap.get(key);
        if (planning && planning.user_planning?.length) {
            if (!planning?.id) {
                this.planningsToSave.splice(this.planningsToSave.indexOf(planning), 1);
                this.projectDayTeamMap.delete(key);
            }
            planning.user_planning.forEach(up => {
                const key = this.makeKey(day.day, up.user_id, day.project?.afas_project_id);
                if (this.projectDayUserMap.has(key)) {
                    this.projectDayUserMap.delete(key);
                    this.dayUserMap.delete(this.makeKey(day.day, up.user_id, ''));
                    if (!planning?.id) {
                        this.userPlanningsToSave.splice(this.userPlanningsToSave.indexOf(up), 1);
                    } else {
                        up.deleted_at = new Date();
                        this.userPlanningsToSave.push(up);
                    }
                }
            });
            planning.user_planning = [];
        } else {
            const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
            if (!planning) {
                if (projectsIds.map(projectId => !!this.projectDayTeamMap.get(
                    this.makeKey(day.day, team.entity.id, projectId))?.entity?.use_once).find(p => p)) {
                    this.confirmDialogService.confirm(
                        'Al ingepland op deze dag',
                        `Deze ploeg is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                        'Sluiten', null).then(() => {
                    });
                    return;
                }
                planning = this.createPlanning(team.entity, team.entityType, day);
            }

            const begindate = new Date(Utils.mainPlanning(planning).begindate);
            const enddate = new Date(Utils.mainPlanning(planning).enddate);

            team.users.forEach(user => {

                if (!this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
                    !projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
                ) {
                    this.addUserPlanningToPlanningTeam(planning, user, begindate, enddate, day);
                }
            });
        }
        this.generateCounts();
    }

    private createPlanning(entity: Entity, entityType: EntityType, day: { day: Date; project: Project }) {
        const planning = this.planningService.newPlanningByType(entityType.id);
        const mainPlanning = this.planningService.getMainPlanning(planning);
        const fromDate = new Date(day.day);
        const toDate = new Date(day.day);
        const startTime = 60 * (this.fcNightPlanning.value ? Settings.DEFAULT_START_NIGHT : Settings.DEFAULT_START);
        fromDate.setMinutes(startTime);
        toDate.setMinutes(startTime);
        toDate.setMinutes(toDate.getMinutes() + (60 * Settings.DEFAULT_DURATION_TEAM));
        mainPlanning.begindate = fromDate;
        mainPlanning.enddate = toDate;
        mainPlanning.entity = entity;
        mainPlanning.entity_id = entity.id;
        planning.entity_id = entity.id;
        planning.afas_project_id = day.project.afas_project_id;
        planning.worknumber = day.project.afas_project_id;
        planning.location = day.project.location;
        planning.performer = day.project.performer_id;
        planning.projectmanager = day.project.projectmanager_id;
        planning.asphalt_performer = day.project.asphalt_performer_id;
        planning.contractor = day.project.contractor;
        planning.status_id = 3;
        planning.user_planning = [];
        this.planningsToSave.push(planning);
        const key = this.makeKey(day.day, entity.id, day.project?.afas_project_id);
        this.projectDayTeamMap.set(key, planning);
        return planning;
    }

    private addUserPlanningToPlanningTeam(planning: Planning, user: User, begindate: Date, enddate: Date, day: { day: Date; project: Project }) {
        const userPlanning = new UserPlanning();
        userPlanning.planning_id = planning.id;
        userPlanning.planning = planning;
        userPlanning.user_id = user.id;
        userPlanning.begindate = begindate;
        userPlanning.enddate = enddate;
        userPlanning.work_begin = day.project.location;
        userPlanning.work_end = day.project.location;
        userPlanning.origin = this.locationService.formatAddress(user as any);
        userPlanning.destination = this.locationService.formatAddress(user as any);

        this.userPlanningsToSave.push(userPlanning);

        const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
        this.projectDayUserMap.set(key, {
            type: 'userPlanning',
            userPlanning: [userPlanning]
        });
        this.dayUserMap.set(this.makeKey(day.day, user.id, ''), day.project?.afas_project_id);
        planning.user_planning.push(userPlanning);
        this.generateCounts();
    }

    addUserPlanningTeam(event,
                        day: {
                            day: Date,
                            project: Project
                        },
                        team: {
                            entityType: EntityType,
                            entity: Entity,
                            users: User[],
                            showUsers: boolean
                        },
                        user: User) {
        event.preventDefault();
        if (this.readonly) {
            return;
        }
        const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
        if (
            !this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
            projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
        ) {
            this.confirmDialogService.confirm(
                'Al ingepland op deze dag',
                `De persoon is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        const userProjectDay = this.projectDayUserMap.get(this.makeKey(day.day, user.id, day.project?.afas_project_id));
        if (userProjectDay?.type === 'planning') {
            this.confirmDialogService.confirm(
                'Chauffeurs kunnen alleen via de transportplanning geplant worden',
                `Deze persoon is op deze dag al ingepland als chauffeur en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        if (userProjectDay && (userProjectDay.userPlanning?.length || userProjectDay.planningHas?.length)) {
            if (userProjectDay.userPlanning?.length === 1) {
                userProjectDay.userPlanning[0].deleted_at = new Date();
                if (!this.userPlanningsToSave.includes(userProjectDay.userPlanning[0])) {
                    this.userPlanningsToSave.push(userProjectDay.userPlanning[0]);
                }
                const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
                if (this.projectDayUserMap.has(key)) {
                    this.projectDayUserMap.delete(key);
                }
                const planning = this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id));
                if (planning?.user_planning) {
                    planning.user_planning.splice(planning.user_planning.indexOf(userProjectDay.userPlanning[0]), 1);
                }
            } else {
                this.confirmDialogService.confirm('Meer keren ingepland',
                    `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                });
                return;
            }
        } else {
            let planning = this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, day.project?.afas_project_id));
            if (!planning) {
                if (projectsIds.map(projectId => !!this.projectDayTeamMap.get(this.makeKey(day.day, team.entity.id, projectId))?.entity?.use_once).find(p => p)) {
                    this.confirmDialogService.confirm(
                        'Al ingepland op deze dag',
                        `Deze ploeg is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                        'Sluiten', null).then(() => {
                    });
                    return;
                }
                planning = this.createPlanning(team.entity, team.entityType, day);
            }
            const begindate = new Date(Utils.mainPlanning(planning).begindate);
            const enddate = new Date(Utils.mainPlanning(planning).enddate);
            this.addUserPlanningToPlanningTeam(planning, user, begindate, enddate, day);
        }
        this.generateCounts();
    }

    addUserPlanningProject(event,
                           day: {
                               day: Date,
                               project: Project
                           },
                           userProjectDay: {
                               type: 'userPlanning' | 'planning' | 'project',
                               userPlanning?: UserPlanning[],
                               planningHas?: PlanningHasEntity[]
                           },
                           user: User,
                           doNotAddEntity = false
    ) {
        event.preventDefault();
        if (this.readonly) {
            return;
        }

        if (!day.project?.afas_project_id) {
            this.confirmDialogService.confirm(
                'Onbekend project',
                `Voor deze planning is nog geen juist Afas project gekozen.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        const projectsIds = [...new Set([...this.projects.map(p => p.afas_project_id), ...this.planningList.map(p => p.afas_project_id ?? p.worknumber)] || [])];
        if (
            !this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id)) &&
            projectsIds.map(projectId => this.projectDayUserMap.has(this.makeKey(day.day, user.id, projectId))).find(p => p)
        ) {
            this.confirmDialogService.confirm(
                'Al ingepland op deze dag',
                `De persoon is op deze dag al ingepland en kan daarom
                    alleen via de transport- of weekplanning ingepland worden.`,
                'Sluiten', null).then(() => {
            });
            return;
        }

        if (userProjectDay?.type === 'planning') {
            if ((userProjectDay.userPlanning?.length + userProjectDay.planningHas?.length) === 1) {
                const project = this.planningList.find(p => p.id === (userProjectDay?.userPlanning[0]?.planning_id ?? userProjectDay?.planningHas[0]?.planning_id))
                this.planningDetailDialogService.openPlanning(project);
            } else {
                this.confirmDialogService.confirm(
                    'Meerdere keren ingepland',
                    `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom
                    alleen via de transport of weekplanning aangepast worden.`,
                    'Sluiten', null).then(() => {
                });
            }
        } else {
            if (this.projectDayUserMap.has(this.makeKey(day.day, user.id, day.project?.afas_project_id))) {

                if ((userProjectDay.userPlanning?.length + userProjectDay.planningHas?.length) > 1) {
                    this.confirmDialogService.confirm('Meer keren ingepland',
                        `De persoon is op deze dag en dit project meerdere keren ingepland en kan daarom alleen via de transport of weekplanning aangepast worden.`, 'Sluiten', null).then(() => {
                    });
                    return;
                }

                if (userProjectDay.userPlanning?.length === 1) {
                    userProjectDay.userPlanning[0].deleted_at = new Date();
                    if (!this.userPlanningsToSave.includes(userProjectDay.userPlanning[0])) {
                        this.userPlanningsToSave.push(userProjectDay.userPlanning[0]);
                    }
                    const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);

                    if (this.projectDayUserMap.has(key)) {
                        this.projectDayUserMap.delete(key);
                        this.dayUserMap.delete(this.makeKey(day.day, user.id, ''));
                    }
                }
                if (userProjectDay.planningHas?.length === 1) {
                    if (userProjectDay.planningHas[0].id) {
                        userProjectDay.planningHas[0].driver_user_id = null;
                        if (!this.planningHasToSave.includes(userProjectDay.planningHas[0])) {
                            this.planningHasToSave.push(userProjectDay.planningHas[0]);
                        }

                    } else {
                        const entityKey = this.makeKey(day.day, userProjectDay.planningHas[0].entity_id, day.project?.afas_project_id)
                        this.projectDayMaterialMap.delete(entityKey);
                        if (this.planningHasToSave.includes(userProjectDay.planningHas[0])) {
                            this.planningHasToSave.splice(this.planningHasToSave.indexOf(userProjectDay.planningHas[0]), 1);
                        }
                    }

                    const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
                    if (this.projectDayUserMap.has(key)) {
                        this.projectDayUserMap.delete(key);
                        this.dayUserMap.delete(this.makeKey(day.day, user.id, ''));
                    }
                }
            } else {
                const add = () => {
                    const driverFrom = this.entities.find(e => e.driver_user_id === user.id);

                    let materialPlanningDriver = this.projectDayMaterialMap.get(this.makeKey(day.day, driverFrom?.id, day.project?.afas_project_id));

                    const fromDate = new Date(day.day);
                    const toDate = new Date(day.day);
                    const startTime = 60 * (this.fcNightPlanning.value ? Settings.DEFAULT_START_NIGHT : Settings.DEFAULT_START);
                    fromDate.setMinutes(startTime);
                    toDate.setMinutes(startTime);
                    toDate.setMinutes(toDate.getMinutes() + (60 * Settings.DEFAULT_DURATION_OTHER));

                    const key = this.makeKey(day.day, user.id, day.project?.afas_project_id);
                    if (driverFrom && materialPlanningDriver?.length && !doNotAddEntity) {
                        materialPlanningDriver[0].driver_user_id = user.id;
                        this.dayUserMap.set(this.makeKey(day.day, user.id, ''), day.project?.afas_project_id);
                        this.projectDayUserMap.set(key, {
                            type: 'project',
                            planningHas: [materialPlanningDriver[0]]
                        });
                        this.planningHasToSave.push(materialPlanningDriver[0]);
                    }
                    if (driverFrom && !materialPlanningDriver?.length && !doNotAddEntity) {
                        if (this.addEntityPlanning(event, day, driverFrom, null, true)) {
                            materialPlanningDriver = this.projectDayMaterialMap.get(this.makeKey(day.day, driverFrom.id, day.project?.afas_project_id));
                            materialPlanningDriver[0].driver_user_id = user.id;
                            this.dayUserMap.set(this.makeKey(day.day, user.id, ''), day.project?.afas_project_id);
                            this.projectDayUserMap.set(key, {
                                type: 'project',
                                planningHas: [materialPlanningDriver[0]]
                            });
                        } else {
                            doNotAddEntity = true;
                        }
                    }
                    if (!driverFrom || doNotAddEntity) {
                        const userPlanning = new UserPlanning();
                        userPlanning.afas_project_id = day.project.afas_project_id;
                        userPlanning.user_id = user.id;
                        userPlanning.begindate = fromDate;
                        userPlanning.enddate = toDate;
                        userPlanning.work_begin = day.project.location;
                        userPlanning.work_end = day.project.location;
                        userPlanning.origin = this.locationService.formatAddress(user as any);
                        userPlanning.destination = this.locationService.formatAddress(user as any);
                        this.userPlanningsToSave.push(userPlanning);
                        this.dayUserMap.set(this.makeKey(day.day, user.id, ''), day.project?.afas_project_id);
                        this.projectDayUserMap.set(key, {
                            type: 'project',
                            userPlanning: [userPlanning]
                        });
                    }
                };

                const leave = this.leaveDayUserMap.get(this.makeKey(day.day, user.id, ''));
                if (leave) {
                    this.confirmDialogService.confirm(
                        'Let op: medewerker heeft verlof!',
                        `Deze medewerker heeft verlof op deze dag, wilt u deze persoon toch inplannen?`,
                        'Toch inplannen', 'Annuleren').then(() => {
                        add();
                    }, () => {
                    });
                } else {
                    add();
                }
            }

        }


        this.generateCounts();
    }

    addressSelected(event: MatAutocompleteSelectedEvent, form: FormGroup<ControlsOf<{
        location: string,
        location_housenumber: string,
        location_place: string,
        location_postcode: string,
        location_straat: string,
        performer: number,
        projectmanager: number
    }>>) {
        const address = this.addresses.find(a => a.formattedAddress === event.option.value);
        if (address) {
            const numberIndex = address?.addressLine?.search(/\d/);
            const street = address.addressLine?.slice(0, numberIndex < 5 ? address.addressLine?.length : numberIndex).trim();
            const number = address.addressLine?.replace(street, '');
            form.controls.location_postcode.setValue(address?.postalCode);
            form.controls.location_housenumber.setValue(number);
            form.controls.location_straat.setValue(street);
            form.controls.location_place.setValue(address?.locality);
        }
    }

    undo() {
        setTimeout(() => {
            this.rollingBack = true;
        });
        setTimeout(() => {
            this.projectDayUserMap.clear();
            this.projectDayTeamMap.clear();
            this.userWeekCount.clear();
            this.dateHasUserMap.clear();
            this.projectDayMaterialMap.clear();
            this.leaveDayUserMap.clear();
            this.userPlanningsToSave = [];
            this.planningHasToSave = [];
            this.planningsToSave = [];
            this.leavesToSave = [];
            this.leavesToMap(this.leaveList, this.allUsers);
            this.parseData(this.planningList, this.projects, this.planningHass, this.userPlannings, this.usersMap, this.entitiesMap, true);
            this.rollingBack = false;
            this.projectDayTeamMap.forEach((planning, key) => {
                const mPlanning = Utils.mainPlanning(planning);
                planning.user_planning.filter(up => !up.id).forEach(up => {
                    this.projectDayUserMap.delete(this.makeKey(new Date(mPlanning.begindate), up.user_id, planning.afas_project_id));
                    this.dayUserMap.delete(this.makeKey(new Date(mPlanning.begindate), up.user_id, ''));
                });
                planning.user_planning = planning.user_planning.filter(up => !!up.id);
            });
        }, 50);
    }

    save() {
        if (this.readonly) {
            return;
        }
        this.saving = true;
        const saveCalls = [];

        if (this.leavesToSave?.length) {
            this.leavesToSave.forEach(leave => {
                leave.users = leave.users?.map(u => u.id) as any[];
                saveCalls.push(
                    from(this.leaveService.saveLeave(leave)).pipe(tap(p => {
                        leave.id = p.data.id;
                        leave.updated_at = p.data.updated_at;
                    }))
                );
            });
            this.leavesToSave = [];
        }

        if (this.planningsToSave?.length) {
            this.planningsToSave.forEach((planning, index) => {
                const pl = {...planning};
                delete pl.user_planning;
                saveCalls.push(
                    from(this.planningService.save(pl)).pipe(tap(p => {
                        planning.id = p.id;
                        planning.user_planning.filter(up => !up.id).forEach(up => {
                            up.planning_id = planning.id;
                            up.planning = null;
                        });
                    }))
                );
            });
        }
        this.userPlanningsToSave.filter(up => !!up.planning_id).forEach(up => {
            up.planning = null;
        });
        saveCalls.push(this.userPlanningService.saveUserPlanningProject(this.userPlanningsToSave, this.planningHasToSave).pipe(tap(() => {
            this.userPlanningsToSave.forEach(up => {
                const planning = this.planningList.find(planning => planning.id === up.planning_id);

                const key = this.makeKey(new Date(up.begindate), up.user_id, (planning?.afas_project_id ?? up?.afas_project_id));

                let userPlannings = this.projectDayUserMap.get(key);
                if (userPlannings) {
                    userPlannings.userPlanning.splice(userPlannings.userPlanning.indexOf(up), 1);
                }
                this.dayUserMap.set(this.makeKey(new Date(up.begindate), up.user_id, ''), (planning?.afas_project_id ?? up?.afas_project_id));
                this.projectDayUserMap.set(key, userPlannings);
                if (!userPlannings?.userPlanning?.length && !userPlannings?.planningHas?.length) {
                    this.projectDayUserMap.delete(key);
                    this.dayUserMap.delete(this.makeKey(new Date(up.begindate), up.user_id, ''));
                }
            });
            this.userPlanningsToSave = [];
            this.planningHasToSave.forEach(ph => {
                const key = this.makeKey(new Date(ph.begindate), ph.entity_id, ph.afas_project_id ?? ph.planning?.afas_project_id, ph.name);
                const userKey = this.makeKey(new Date(ph.begindate), ph.driver_user_id, ph.afas_project_id ?? ph.planning?.afas_project_id, ph.name);
                let planningHasList = this.projectDayMaterialMap.get(key);
                if (planningHasList) {
                    planningHasList.splice(planningHasList.indexOf(ph), 1);
                }
                this.projectDayMaterialMap.set(key, planningHasList);
                if (!planningHasList?.length) {
                    this.projectDayMaterialMap.delete(key);
                }

                let planningHasListUser = this.projectDayUserMap.get(userKey);
                if (planningHasListUser) {
                    planningHasListUser.planningHas.splice(planningHasListUser.planningHas.indexOf(ph), 1);
                }
                this.projectDayUserMap.set(userKey, planningHasListUser);
                if (!planningHasListUser?.userPlanning?.length && !planningHasListUser?.planningHas?.length) {
                    this.projectDayUserMap.delete(userKey);
                }
            });
            this.planningHasToSave = [];
        })));

        concat(...saveCalls).subscribe(result => {
            this.planningsToSave = [];
            this.saving = false;
        }, error => {
            console.error(error);
            this.saving = false;
        });

        this.projectDaysList.forEach(projectDays => {
            if (projectDays.form.dirty) {
                projectDays.project.performer_id = projectDays.form.value.performer_id;
                projectDays.project.location = projectDays.form.value.location;
                projectDays.project.location_postcode = projectDays.form.value.location_postcode;
                projectDays.project.location_housenumber = projectDays.form.value.location_housenumber;
                projectDays.project.location_straat = projectDays.form.value.location_straat;
                projectDays.project.location_place = projectDays.form.value.location_place;
                projectDays.project.projectmanager_id = projectDays.form.value.projectmanager_id;
                this.projectService.saveProject(projectDays.project).subscribe(p => {
                    projectDays.project.updated_at = p.data.updated_at;
                    projectDays.form.markAsPristine();
                    this.saving = false;
                }, () => {
                    this.saving = false;
                });
            }
        });
    }

    private clearMaps() {
        this.leaveDayUserMap.clear();
        this.entityHiringNames.clear();
        this.projectDaysList = null;
        this.projectDayUserMap.clear();
        this.projectDayTeamMap.clear();
        this.userWeekCount.clear();
        this.dateHasUserMap.clear();
        this.entityWeekUsedDays.clear();
    }

    next() {
        this.openChangesBackActionCheck().then((result) => {
            if (result) {
                this.clearMaps();
                let week = this.week + 1;
                let year = this.year;
                if (week > 52) {
                    week = 1;
                    year += 1;
                }
                this.router.navigate([`personeelsplanning`, week, year]);
            }
        });
    }

    prev() {
        this.openChangesBackActionCheck().then((result) => {
            if (result) {
                this.clearMaps();
                let week = this.week - 1;
                let year = this.year;
                if (week < 1) {
                    week = 52;
                    year -= 1;
                }
                this.router.navigate([`personeelsplanning`, week, year]);
            }
        });
    }

    leavesToMap(leaves: Leave[], users: User[]) {
        leaves.forEach(leave => {
            this.leaveToMap(leave, users);
        });
    }

    getData() {
        const requestFromDate = new Date(this.fromDate);
        const requestToDate = new Date(this.toDate);
        const planning$ = this.planningService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const project$ = this.projectService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const planningHas$ = this.planningHasService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const userPlanning$ = this.userPlanningService.getFilteredList(requestFromDate, requestToDate).pipe(debounceTime(100));
        const leaves$ = (environment.disableAfas || this.readonly) ? of(null) : this.afasService.getLeaves(requestFromDate, requestToDate);
        const illness$ = (environment.disableAfas || this.readonly) ? of(null) : this.afasService.getIllness(requestFromDate, requestToDate);
        const users$ = this.userService.getFilteredList(this.fromDate, this.toDate, false, false);
        const usersMap$ = this.userService.getMap();
        const entities$ = this.entitiesService.getList(this.fromDate, this.toDate);
        const entitiesMap$ = this.entitiesService.getMap().pipe(debounceTime(100));
        const entityTypes$ = this.entitiesService.getTypesMap();

        const leave$ = this.leaveService.getFilteredList(this.fromDate, this.toDate);
        const usersForLeave$ = this.userService.getFilteredList(this.fromDate, this.toDate, false, true);

        this.blockedMap = null;
        this.periodSubscriptions?.unsubscribe();
        this.periodSubscriptions = new Subscription();
        this.periodSubscriptions.add(this.fcAddHiring.valueChanges.pipe(debounceTime(200)).subscribe(value => {
            this.periodSubscriptions.add(this.planningService.getHiringNames(this.addingHiringForEntityId).subscribe(names => {
                let newHirings = names.data.filter(name => name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
                newHirings.push(value);
                const currentNames = this.entityHiringNames.get(this.addingHiringForEntityId) ?? [];
                newHirings = newHirings.filter(newHiring => currentNames.indexOf(newHiring) === -1 && newHiring?.length > 0).slice(-5);
                this.hiringAutocompleteOptions = newHirings;
            }));
        }));

        this.periodSubscriptions.add(combineLatest(usersForLeave$, leave$)
            .subscribe(([users, leaves]) => {
                this.allUsers = users;
                this.leaveData = leaves;
                this.leaveList = [...leaves];
                this.leaveDayUserMap.clear();
                this.leavesToMap(this.leaveList, this.allUsers);
            }));


        this.periodSubscriptions.add(combineLatest(planningHas$, userPlanning$, planning$, project$, users$, usersMap$, entitiesMap$, entities$, entityTypes$)
            .subscribe(([planningHas, userPlanning, planningList, projects, users, usersMap, entitiesMap, entities, entityTypeMap]) => {

                this.usersMap = usersMap;
                this.entitiesMap = entitiesMap;
                this.entityTypeMap = entityTypeMap;

                const hiddenUserFunctions = [
                    'chauffeur',
                    'machinist groot materieel',
                    'machinist asfalt',
                    'planner',
                    'veeg-zuig machinist',
                    'machinist frees',
                    'stagiair',
                    'uitvoerder',
                    'uitvoerder verkeer',
                    'vakman verkeer',
                    'werkvoorbereider',
                    'werkvoorbereider verkeer'
                ];
                const functionMap = {
                    'asfalt balkman': 'Asfalt',
                    'asfalt machinist': 'Asfalt',
                    'asfalt machinist kleefwagen': 'Asfalt',
                    'asfalt medewerker': 'Asfalt',
                    'asfalt walsmachinist': 'Asfalt',
                    gieter: 'Asfalt',
                    balkman: 'Asfalt',
                    'machinist gww': 'Machinist',
                    'machinist klein materieel': 'Machinist',
                    'machinist mobiele kraan': 'Machinist',
                    straatmaker: 'Straatm./Gw./Vkr.',
                    grondwerker: 'Straatm./Gw./Vkr.',
                    verkeersregelaar: 'Straatm./Gw./Vkr.'
                };

                this.users = users.map(u => {
                    u = JSON.parse(JSON.stringify(u));
                    u.function = functionMap[u.function.toLowerCase()] ?? u.function;
                    return u;
                }).filter(u => hiddenUserFunctions.indexOf(u.function.toLowerCase()) === -1)
                    .sort((a, b) => a.lastname?.localeCompare(b.lastname))
                    .sort((a, b) => a.function?.localeCompare(b.function));

                if (this.entities?.length !== entities?.length && entities?.length) {
                    this.teams = entities.filter(e => !!e.entitytypes.find(t => t.is_team) && e.default_employees?.filter(df => usersMap.has(df))?.length > 1).map(team => {
                        return {
                            entityType: team.entitytypes.find(e => e.is_team),
                            entity: team,
                            users: (team.default_employees?.map(e => usersMap.get(+e)).filter(u => !!u) ?? []).sort((a, b) => a.lastname?.localeCompare(b.lastname)),
                            showUsers: false
                        };
                    }).sort((a, b) => a.entityType.name.localeCompare(b.entityType.name));

                    this.smallMaterial = entities.filter(e => !!e.entitytypes.find(e => e.visible_small) && !!e.use_once);

                    this.hireEntities = entities.filter(e => !!e.entitytypes.find(e => e.visible_small) && !e.use_once && [EntityId.HiringEmployee, EntityId.HiringMaterials].indexOf(e.id) !== -1);
                }
                this.entities = entities;

                if (!this.blockedMap) {
                    this.blockedMap = new Map<string, { icon: string; description: string; startDate: Date; endDate: Date; }>();
                    this.periodSubscriptions.add(combineLatest([leaves$, illness$]).subscribe(([leaves, illness]) => {
                        const leavesAndIlls = [...illness?.data.map(ill => {
                            return {
                                startDate: ill.startDate,
                                endDate: ill.endDate,
                                description: ill.absenceTypeDesc,
                                employeeId: ill.employeeId,
                                icon: 'fa-face-thermometer'
                            };
                        }) ?? [], ...leaves?.data.map(leave => {
                            return {
                                startDate: leave.startDate,
                                endDate: leave.endDate,
                                description: leave.leaveDescr,
                                employeeId: leave.employeeId,
                                icon: 'fa-user-slash'
                            };
                        }) ?? []];

                        const loopDate = new Date(this.fromDate);
                        while (loopDate.getTime() < this.toDate.getTime()) {
                            const startOfDay = Utils.setTime(new Date(loopDate), 0, 0);
                            const endOfDay = Utils.setTime(new Date(loopDate), 23, 59);
                            leavesAndIlls.forEach(leaveOrIll => {
                                const user = this.users.find(u => u.afas_employee_id === leaveOrIll.employeeId);
                                if (Utils.newDate(leaveOrIll.startDate) <= endOfDay && (leaveOrIll.endDate === null || Utils.newDate(leaveOrIll.endDate) >= startOfDay) && user) {
                                    this.blockedMap.set(formatDate(startOfDay, 'yyyy-MM-dd', 'nl') + '-' + user.id, {
                                        description: leaveOrIll.description,
                                        icon: leaveOrIll.icon,
                                        startDate: leaveOrIll.startDate,
                                        endDate: leaveOrIll.endDate
                                    });
                                }
                            });
                            loopDate.setDate(loopDate.getDate() + 1);
                        }
                    }));
                }
                this.parseData(planningList, projects, planningHas, userPlanning, usersMap, entitiesMap);
                this.projects = projects;
                this.planningList = planningList;
                this.planningHass = planningHas;
                this.userPlannings = userPlanning;

                setTimeout(() => {
                    document.body.appendChild(document.createElement('readyforpuppeteer'));
                });

            }));
        this.periodSubscriptions.add(this.userService.getByType(UserType.PROJECTMANAGER).subscribe(users => this.projectManagers = users));
        this.periodSubscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
    }

    showInfoDialog() {
        const dateCompareFormat = (date) => {
            return formatDate(date, 'yyyy-MM-dd', 'nl');
        };
        const planningList = this.planningList;
        const planningHas = this.planningHass.filter(p => p.driver_user_id === this.contextUser?.id && dateCompareFormat(p.begindate) === dateCompareFormat(this.contextDay));
        const userPlanning = this.userPlannings.filter(p => p.user_id === this.contextUser?.id && dateCompareFormat(p.begindate) === dateCompareFormat(this.contextDay));
        const leave = this.leaveDayUserMap.get(this.makeKey(this.contextDay, this.contextUser?.id, ''));
        const afasLeave = this.blockedMap.get(`${formatDate(this.contextDay, 'yyyy-MM-dd', 'nl')}-${this.contextUser?.id}`);
        this.dialog.open(EmployeeDayInfoDialogComponent, {
            minWidth: '320px',
            panelClass: 'comment-edit-dialog',
            data: {
                planningHas,
                userPlanning,
                leave,
                afasLeave,
                projects: this.projects,
                planningList,
                user: this.contextUser,
                day: this.contextDay
            }
        });
    }

    private leaveToMap(leave: Leave, users: User[]) {

        const leaveDates = this.leaveService.generateLeaveDates(leave);
        const userIds = leave.users.map(u => u.id);

        users.filter(u => leave.common ? true : userIds.includes(u.id)).forEach(user => {
            leaveDates.forEach(dates => {
                const dateString = formatDate(dates.beginDate, 'yyyy-MM-dd', 'nl');
                if (!leave.excluded.find(e => e.user_id === user.id && formatDate(e.date, 'yyyy-MM-dd', 'nl') === dateString)) {
                    this.leaveDayUserMap.set(this.makeKey(dates.beginDate, user.id, ''), leave);
                }
            });
        });
    }

    private parseData(planningList: Planning[],
                      projects: Project[],
                      planningHass: PlanningHasEntity[],
                      userPlannings: UserPlanning[],
                      usersMap: Map<number, User>,
                      entitiesMap: Map<number, Entity>,
                      clean = false) {
        if (!this.projectDaysList) {
            const newProjects = [
                ...new Set([...projects.map(p => p.afas_project_id), ...planningList.map(p => p.afas_project_id)] || [])
            ];
            this.projectDaysList = this.projectDaysList?.filter(p => p.project?.afas_project_id && !newProjects.includes(p.project?.afas_project_id)) || [];
            this.filterProjectDaysList();
        }

        const currentDateUserDayMap = new Map<string, boolean>();
        const currentDateEntityDayMap = new Map<string, boolean>();
        this.userDatesMap.clear();
        this.dayUserMap.clear();

        if (planningList !== this.planningList || clean) {
            planningList.forEach(planning => {
                const date = new Date(Utils.mainPlanning(planning).begindate);
                this.projectDayTeamMap.set(this.makeKey(date, planning.entity_id, (planning.afas_project_id)), planning);


                planning.performer_user = usersMap.get(planning.performer);
                planning.projectmanager_user = usersMap.get(planning.projectmanager);

                Utils.planningAllEntities(planning).forEach(plnEntity => {
                    plnEntity.entity = entitiesMap.get(plnEntity.entity_id);
                });
            });
        }
        planningHass.forEach(planningHas => {
            const afasProjectId = planningHas.afas_project_id ?? planningList.find(p => p.id === planningHas.planning_id)?.afas_project_id;
            this.handlePlanningHas(planningHas, afasProjectId, currentDateUserDayMap, currentDateEntityDayMap);
        });
        userPlannings.forEach(userPlanning => {
            const planning = planningList.find(p => p.id === userPlanning.planning_id);
            const afasProjectId = userPlanning.afas_project_id ?? planning?.afas_project_id;
            const date = new Date(userPlanning.begindate);
            const key = this.makeKey(date, userPlanning.user_id, afasProjectId);

            let current = this.projectDayUserMap.get(key);
            if (!current) {
                current = {
                    type: userPlanning.planning_id ? 'userPlanning' : 'project',
                    planningHas: [],
                    userPlanning: []
                };
            }
            if (!current.userPlanning.some(up => up.id === userPlanning.id) && !this.userPlanningsToSave.some(ups => ups.id === userPlanning.id)) {
                current.userPlanning.push(userPlanning);
            } else {
                const cup = current.userPlanning.find(up => up.id === userPlanning.id);
                if (cup && cup !== userPlanning) {
                    delete userPlanning.deleted_at;
                    Object.assign(cup, userPlanning);
                }
                const ups = this.userPlanningsToSave.find(upts => upts.id === userPlanning.id);
                if (ups && ups !== userPlanning) {
                    delete userPlanning.deleted_at;
                    Object.assign(ups, userPlanning);
                }
            }
            if (current.planningHas?.length || current.userPlanning?.length) {
                this.dayUserMap.set(this.makeKey(date, userPlanning.user_id, ''), afasProjectId);
                this.projectDayUserMap.set(key, current);
            }
            currentDateUserDayMap.set(key, true);
            this.userDatesMap.set(userPlanning.user_id, [...(this.userDatesMap.get(userPlanning.user_id) || []), Utils.formatDate(date)]);

            if (planning && userPlanning.planning_id) {
                if (!planning.user_planning.find(up => up.id === userPlanning.id)) {
                    planning.user_planning.push(userPlanning);
                }
            }
        });

        this.planningList?.forEach(planning => {
            planning.user_planning = planning.user_planning.filter(up => !!userPlannings.find(ups => ups.id === up.id));
        });

        this.projectDayUserMap.forEach((projectDayUser, key) => {
            if (!currentDateUserDayMap.has(key)) {
                if (projectDayUser.userPlanning && this.projectDayUserMap.has(key)) {
                    if (!projectDayUser.userPlanning.some(up => this.userPlanningsToSave.indexOf(up) !== -1)) {
                        this.projectDayUserMap.delete(key);
                    } else {
                        this.dayUserMap.set(this.makeKey(projectDayUser.userPlanning[0].begindate, projectDayUser.userPlanning[0].user_id, ''), projectDayUser.userPlanning[0].afas_project_id);
                    }
                }
                if (projectDayUser.planningHas && this.projectDayUserMap.has(key)) {
                    if(!projectDayUser.planningHas.some(up => this.planningHasToSave.indexOf(up) !== -1)) {
                        this.projectDayUserMap.delete(key);
                    } else {
                        this.dayUserMap.set(this.makeKey(projectDayUser.planningHas[0].begindate, projectDayUser.planningHas[0].driver_user_id, ''), projectDayUser.planningHas[0].afas_project_id);
                    }
                }
            }

            if (projectDayUser.userPlanning?.some(m => !!this.userPlanningsToSave.find(up => up.id === m.id)?.deleted_at)) {
                this.projectDayUserMap.delete(key);
            }
        });

        this.projectDayMaterialMap.forEach((projectDayMaterial, key) => {
            if (!currentDateEntityDayMap.has(key)) {
                if (this.projectDayMaterialMap.has(key) && !projectDayMaterial.some(pdm => this.planningHasToSave.indexOf(pdm) !== -1)) {
                    this.projectDayMaterialMap.delete(key);
                }
            }
            if (projectDayMaterial.some(m => !!this.planningHasToSave.find(up => up.id === m.id)?.deleted_at)) {
                this.projectDayMaterialMap.delete(key);
            }
        });

        const currentDates = [];
        const loopDate = new Date(this.fromDate);
        while (loopDate.getTime() < this.toDate.getTime()) {
            currentDates.push(formatDate(loopDate, 'yyyy-MM-dd', 'nl'));
            loopDate.setDate(loopDate.getDate() + 1);
        }

        this.userDatesMap.forEach((dates, userId) => {
            const datesUnique = ([...new Set([...dates])] || []).filter(d => currentDates.includes(d));
            if (datesUnique.length !== this.userWeekCount.get(userId)) {
                this.userWeekCount.set(userId, datesUnique.length);
            }
        });

        this.uniqueProjectsIds = [
            ...new Set([
                ...projects.map(p => p.afas_project_id),
                ...planningList.map(p => p.afas_project_id),
                ...planningHass.map(ph => ph.afas_project_id),
                ...userPlannings.map(ph => ph.afas_project_id)
            ] || [])
        ]
            .filter(p => !!p)
            .sort((a, b) => a.padEnd(8, '0').localeCompare(b.padEnd(8, '0')));

        this.uniqueProjectsIds.forEach(projectId => {
            const project = projects.find(p => p.afas_project_id === projectId);
            if (project) {
                if (!this.projectDaysList.find(p => p.project?.afas_project_id === projectId)) {
                    this.projectDaysList.push(this.createProjectDays(projectId, projects, planningList));
                } else {
                    const projectDay = this.projectDaysList.find(p => p.project?.afas_project_id === projectId);
                    if (!projectDay.form.dirty) {
                        projectDay.form.patchValue(project);
                    }
                }
            } else {
                console.log('missing project', projectId);
                const tempSub = this.projectService.getSingle(projectId).subscribe(() => {
                    tempSub.unsubscribe();
                });
            }
        });

        this.generateCounts();

        this.filterProjectDaysList();
    }

    private generateCounts() {
        this.userWeekCount.clear();
        this.users.forEach(user => {
            this.leaveDays.forEach(day => {
                if (this.dayUserMap.has(this.makeKey(day, user.id, '')) || this.leaveDayUserMap.has(this.makeKey(day, user.id, ''))) {
                    let count = this.userWeekCount.get(user.id) ?? 0;
                    count++;
                    this.userWeekCount.set(user.id, count);
                }
            });
        });
    }

    private handlePlanningHas(planningHas: PlanningHasEntity, afas_project_id: string, currentDateUserDayMap: Map<string, boolean>, currentDateEntityDayMap: Map<string, boolean>) {
        if (!planningHas.entity_id) {
            return;
        }
        const date = new Date(planningHas.begindate);
        if (!this.entitiesMap.get(planningHas.entity_id)?.use_once && [EntityId.HiringEmployee, EntityId.HiringMaterials].indexOf(planningHas.entity_id) !== -1) {
            if (!this.entityHiringNames.has(planningHas.entity_id)) {
                this.entityHiringNames.set(planningHas.entity_id, []);
            }
            if (!this.entityHiringNames.get(planningHas.entity_id).find(z => z === planningHas.name)) {
                this.entityHiringNames.get(planningHas.entity_id).push(planningHas.name);
            }
        }

        const key = this.makeKey(date, planningHas.driver_user_id, afas_project_id);
        let current = this.projectDayUserMap.get(key);
        if (!current) {
            current = {
                type: planningHas.planning_id ? 'planning' : 'project',
                planningHas: [],
                userPlanning: []
            };
        }
        if (!current.planningHas.some(phe => phe.id === planningHas.id)) {
            current.planningHas.push(planningHas);
        }
        this.dayUserMap.set(this.makeKey(date, planningHas.driver_user_id, ''), afas_project_id);
        this.projectDayUserMap.set(key, current);
        currentDateUserDayMap.set(key, true);

        //Do for all material, because auto couple of entities
        const nonUseOnceName = this.entitiesMap.get(planningHas.entity_id).use_once ? null : planningHas.name;
        const materialKey = this.makeKey(date, planningHas.entity_id, afas_project_id, nonUseOnceName);

        const currentMaterial = this.projectDayMaterialMap.get(materialKey) ?? [];
        if (!currentMaterial.some(phe => +phe.id === +planningHas.id)) {
            currentMaterial.push(planningHas);
            this.projectDayMaterialMap.set(materialKey, currentMaterial);
        }
        currentDateEntityDayMap.set(materialKey, true);

        const days = this.entityWeekUsedDays.get(nonUseOnceName + planningHas.entity_id) ?? [];
        const dateString = formatDate(planningHas.begindate, 'yyyy-MM-dd', 'nl');
        if (!days.find(d => d === dateString)) {
            days.push(dateString);
        }
        this.entityWeekUsedDays.set(nonUseOnceName + planningHas.entity_id, days);


        this.userDatesMap.set(planningHas.driver_user_id, [...(this.userDatesMap.get(planningHas.driver_user_id) || []), Utils.formatDate(date)]);
    }

    private createProjectDays(afasProjectId: string, projects: Project[], planningList?: Planning[]) {
        const planning = planningList?.find(p => (p.afas_project_id ?? p.worknumber) === afasProjectId && !p.is_transport) ?? planningList?.find(p => (p.afas_project_id ?? p.worknumber) === afasProjectId);
        const project = projects.find(p => p.afas_project_id === afasProjectId) ?? planning?.project;
        const projectDays = {
            project,
            worknumber: planning?.worknumber ?? project?.afas_project_id,
            form: new FormGroup<ControlsOf<{
                location: string,
                location_housenumber: string,
                location_place: string,
                location_postcode: string,
                location_straat: string,
                performer_id: number,
                projectmanager_id: number
            }>>({
                location: new FormControl(project?.location),
                location_housenumber: new FormControl(project?.location_housenumber),
                location_place: new FormControl(project?.location_place),
                location_postcode: new FormControl(project?.location_postcode),
                location_straat: new FormControl(project?.location_straat),
                performer_id: new FormControl(project?.performer_id),
                projectmanager_id: new FormControl(project?.projectmanager_id)
            }),
            days: []
        };
        if (!project?.afas_project_id) {
            projectDays.form.disable();
        }
        this.periodSubscriptions.add(projectDays.form.dirty$.subscribe(dirty => {
            this.formDirty = dirty;
        }));
        this.periodSubscriptions.add(projectDays.form.controls.location.valueChanges.pipe(debounceTime(200)).subscribe(search => {
            if (search !== project.location) {
                const addresses = [];
                this.periodSubscriptions.add(this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                    result.resourceSets.forEach(res => {
                        res.resources.forEach(address => {
                            addresses.push(address.address);
                        });
                    });
                    this.addresses = addresses;
                }));
            }
        }));
        const loopDate = new Date(this.fromDate);
        while (loopDate.getTime() < this.toDate.getTime()) {
            const day = Utils.setTime(new Date(loopDate), 0, 0);
            projectDays.days.push({
                day,
                project
            });
            loopDate.setDate(loopDate.getDate() + 1);
        }
        return projectDays;
    }

    addHiring(team: MatAutocompleteSelectedEvent) {
        const newHiring = `${team.option.value}`.trim();
        const array = (this.entityHiringNames.get(this.addingHiringForEntityId) ?? []);
        if (array.indexOf(newHiring) === -1) {
            array.push(newHiring);
            this.entityHiringNames.set(this.addingHiringForEntityId, array);
        }
        this.fcAddHiring.reset();
        this.addingHiringForEntityId = null;
    }

    startAddHiring(entity: Entity) {
        if (this.readonly) {
            return;
        }

        this.addingHiringForEntityId = entity.id;
        setTimeout(() => {
            this.addHiringInput.nativeElement.focus();
        });
    }


    pdf() {
        this.downloadingPdf = true;
        const filename = `Personeelsplanning-week_${this.week}-${this.year}.pdf`;
        this.subscriptions.add(this.planningService.generateEmployeePdf(this.week, this.year).subscribe((data: HttpResponse<any>) => {
            saveAs(data, filename);
            this.downloadingPdf = false;
        }, () => {
            this.downloadingPdf = false;
        }));
    }

    removeHire(hireEntityId: number, user: string) {
        if (this.entityWeekUsedDays.get(user + hireEntityId).length > 0) {
            this.confirmDialogService.confirm(
                'Verwijderen niet mogelijk',
                `Verwijderen is niet mogelijk omdat er nog inhuur ingepland is. Verwijder eerst de inhuur.`,
                'Sluiten', null).then(() => {
            });
        } else {
            const items = this.entityHiringNames.get(hireEntityId);
            items.splice(items.indexOf(user), 1);
            this.entityHiringNames.set(hireEntityId, items);
        }
    }

    setHourType(hourtype: RealisationHourtype) {
        this.currentHourType = hourtype;
        this.addOrRemoveLeave(this.contextUser, this.contextDay, true, true);
    }

    addOrRemoveLeave(user: User, day: Date, setHourType?: boolean, changeProject?: boolean) {
        const dateString = formatDate(day, 'yyyy-MM-dd', 'nl');
        const checkHourtype = this.leaveDayUserMap.get(this.makeKey(day, user.id, ''))?.hourtype ?? this.currentHourType;
        let leaves = this.leaveData.filter(l =>
            l.hourtype === checkHourtype &&
            dateString === formatDate(l.begindate, 'yyyy-MM-dd', 'nl') &&
            !l.deleted_at
        );

        let leave = leaves[0];
        if (leaves.length > 1) {
            leave = leaves.find(l => !!l.users.find(u => u.id === user.id));
        }
        if (this.currentHourType === RealisationHourtype.sleep) {
            leave = this.leaveData.find(l =>
                l.hourtype === checkHourtype &&
                dateString === formatDate(l.begindate, 'yyyy-MM-dd', 'nl') &&
                !l.deleted_at &&
                l.afas_project_id === this.contextProject?.afas_project_id
            );
        }

        if (leave && (leave?.interval_week)) {
            this.confirmDialog.confirm(
                'Herhalend verlof',
                `Herhalend verlof kan alleen via de verlofmodule worden aangepast.`,
                'Sluiten',
                'Naar verlof detail').then(() => {

            }, () => {
                this.router.navigateByUrl(`/leave/${leave.id}`);
            });
            return;
        }

        if (!this.leaveDayUserMap.has(this.makeKey(day, user.id, '')) && leave?.comment !== this.contextComment) {
            leave = null;
        }
        if (this.currentHourType === RealisationHourtype.sleep && leave?.afas_project_id !== this.contextProject?.afas_project_id || changeProject) {
            leave = null;
        }

        const leaveUsers = () => {
            if (!leave.users) {
                leave.users = [];
            }
            let actionIsAdd = false;

            if (leave.excluded.find(e => e.user_id === user.id)) {
                leave.excluded.splice(leave.excluded.findIndex(e => e.user_id === user.id), 1);
                actionIsAdd = true;
            } else {
                if (leave?.common) {
                    leave.excluded.push({user_id: user.id, date: day});
                    this.leaveDayUserMap.delete(this.makeKey(day, user.id, ''));
                } else {
                    if (leave.users.find(u => u.id === user.id)) {
                        leave.users.splice(leave.users.findIndex(u => u.id === user.id), 1);
                        this.leaveDayUserMap.delete(this.makeKey(day, user.id, ''));
                        actionIsAdd = true;
                    } else {
                        leave.users.push(user);
                    }
                }
            }

            if (!this.leaveData.includes(leave)) {
                this.leaveData.push(leave);
            }
            if (!this.leavesToSave.includes(leave)) {
                this.leavesToSave.push(leave);
            }
            this.leaveToMap(leave, this.users);
            this.generateCounts();
            return actionIsAdd;
        };

        if (!leave) {
            if (!this.currentHourType) {
                return;
            }
            const dialogRef = this.dialog.open<AddLeaveNameDialogComponent, any, returnData>(AddLeaveNameDialogComponent, {
                width: '320px',
                panelClass: 'comment-edit-dialog',
                data: {
                    currentHourType: this.currentHourType,
                    contextComment: this.contextComment,
                    contextProject: this.contextProject,
                    projects: this.projectDaysList.map(pd => pd.project)
                }
            });
            const subs = dialogRef.afterClosed().subscribe((data: { comment: string, project: Project }) => {
                if (data) {
                    this.contextProject = data.project;
                    this.contextComment = data.comment;
                    leave = new Leave();
                    leave.hourtype = this.currentHourType;
                    if (leave.hourtype === RealisationHourtype.sleep) {
                        leave.afas_project_id = this.contextProject.afas_project_id;
                    }
                    const beginDate = new Date(day);
                    Utils.setTime(beginDate, Settings.DEFAULT_START, 0, 0);
                    const endDate = new Date(beginDate);
                    Utils.setTime(endDate, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
                    leave.begindate = beginDate;
                    leave.enddate = endDate;
                    const begintime = new Date();
                    Utils.setTime(begintime, Settings.DEFAULT_START, 0, 0);
                    const endtime = new Date();
                    Utils.setTime(endtime, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
                    leave.comment = data.comment;
                    leave.begintime = formatDate(begintime, 'HH:mm', 'nl');
                    leave.endtime = formatDate(endtime, 'HH:mm', 'nl');
                    leaveUsers();
                }
                subs.unsubscribe();

            });

        } else {

            if (leaveUsers() && setHourType) {
                this.addOrRemoveLeave(this.contextUser, this.contextDay);
            }
        }


    }

}

interface ProjectDay {
    project: Project;
    worknumber?: string;
    form: FormGroup<ControlsOf<{
        location: string,
        location_housenumber: string,
        location_place: string,
        location_postcode: string,
        location_straat: string,
        performer_id: number,
        projectmanager_id: number
    }>>;
    days: {
        day: Date,
        project: Project
    }[];
}
