import {EntityUnavailable} from './entityunavailable.class';
import {EntityType} from '../services/entities/entity-type.class';
import {RealtimeInterface} from '../services/realtime/realtime-interface';

export class Entity implements RealtimeInterface {
    id: number;
    entitytype_id: number;
    entitytypes: EntityType[];
    name: string;
    function?: string;
    use_once: boolean;
    exclude_driving_hours: boolean;
    default_transport_none: boolean;
    default_transport_home: boolean;
    lowloader_entity_id: number;
    truck_entity_id: number;
    team_entity_id: number;
    driver_user_id: number;
    begindate: Date;
    enddate: Date;
    created_at: Date;
    updated_at: Date;
    default_entities: any;
    default_employees: number[];
    entity_unavailable: EntityUnavailable[];
    afas_employee_id: string;
    order: {
        entitytype_id: number;
        order: number;
    }[];

    default_performer_id: number;
    default_asphalt_performer_id: number;

    price?: number;
    price_unit?: number;
    unit?: string;
    settle_group?: string;
    price_plotten?: number;
}
