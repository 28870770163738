import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {PlanningService} from '../../services/planning/planning.service';
import {EntitiesService} from '../../services/entities/entities.service';
import {Entity} from '../../classes/entity.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {UserService, UserType} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {EntityType, EntityTypeCode} from '../../services/entities/entity-type.class';
import {Utils} from '../../utils.class';
import {combineLatest, concat, from, of, Subscription} from 'rxjs';
import {Planning} from '../../classes/planning.class';
import {LeaveService} from '../../services/leave.service';
import {formatDate} from '@angular/common';
import {Leave} from '../../classes/leave';
import {RealisationService} from '../../services/realisation.service';
import {RealisationHourtype} from '../../classes/realisation';
import {AddLeaveNameDialogComponent, returnData} from '../../employee-planning/add-leave-name-dialog/add-leave-name-dialog.component';
import {Settings} from '../../settings.class';
import {Router} from '@angular/router';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {debounceTime, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AfasService} from '../../services/afas.service';
import {Project} from '../../classes/project.class';
import {UserPlanningService} from '../../services/planning/user-planning.service';

@Component({
    selector: 'app-transport-in-use',
    templateUrl: './transport-in-use.component.html',
    styleUrls: ['./transport-in-use.component.scss']
})
export class TransportInUseComponent extends CodaltComponent implements OnChanges, OnInit {

    readonly RealisationHourtype = RealisationHourtype;

    @Input() currentDate: Date;

    entities: Entity[];

    users: User[];
    drivers: User[];

    planningMap = new Map<number, PlanningHasEntity[]>();
    plannings: Planning[];

    types: EntityType[];
    typeMap = new Map<number, Entity[]>();
    usedDrivers: number[] = [];

    leaveDayUserMap: Map<number, Leave> = new Map<number, Leave>();
    leaveList: Leave[];
    leavesToSave: Leave[] = [];

    hourtypeIconMap = RealisationService.hourtypeIconMap;

    currentHourType: RealisationHourtype;
    contextUserId: number;
    contextComment = 'Personeelsplanning';
    contextProject: Project;

    saveLeaveAction = new EventEmitter();

    blockedMap: Map<number, { icon: string; description: string; }>;

    @Output() leaveBlockedMap = new EventEmitter<Map<number, { icon: string; description: string; }>>();
    @Output() leaveUserMap = new EventEmitter<Map<number, Leave>>();

    private dateSubscriptions: Subscription;

    constructor(private planningService: PlanningService,
                private userPlanningService: UserPlanningService,
                private leaveService: LeaveService,
                private entitiesService: EntitiesService,
                private userService: UserService,
                private confirmDialog: ConfirmDialogService,
                private afasService: AfasService,
                private dialog: MatDialog,
                private router: Router) {
        super();
    }

    ngOnInit() {
        const showTypes = [
            EntityTypeCode.Truck,
            EntityTypeCode.Crane,
            EntityTypeCode.StickyWagon,
            EntityTypeCode.Roller,
            EntityTypeCode.Dumper,
            EntityTypeCode.Set
        ];

        this.subscriptions.add(this.entitiesService.getTypes().subscribe(types => {
            this.types = types.data
                .filter(type => showTypes.indexOf(type.id) !== -1)
                .sort((a, b) => {
                    return showTypes.indexOf(a.id) - showTypes.indexOf(b.id);
                });

            this.loadData();
        }));

        this.subscriptions.add(this.saveLeaveAction.pipe(debounceTime(800)).subscribe(() => {
            const saveCalls = [];

            if (this.leavesToSave?.length) {
                this.leavesToSave.forEach(leave => {
                    const toSave = new Leave();
                    Object.assign(toSave, leave);
                    toSave.users = toSave.users?.map(u => u.id) as any[];
                    saveCalls.push(
                        from(this.leaveService.saveLeave(toSave)).pipe(tap(p => {
                            leave.id = p.data.id;
                            leave.updated_at = p.data.updated_at;
                            leave.deleted_at = p.data.deleted_at;
                        }))
                    );
                });
                concat(...saveCalls).subscribe(result => {
                    this.leavesToSave = [];
                }, error => {
                    console.error(error);
                });
            }
        }));

    }

    loadData() {
        this.typeMap = new Map<number, Entity[]>();
        this.usedDrivers = [];
        this.planningMap = new Map<number, PlanningHasEntity[]>();

        const dayAfter = this.Utils.newDate(this.currentDate);
        dayAfter.setDate(dayAfter.getDate() + 1);

        this.dateSubscriptions?.unsubscribe();
        this.dateSubscriptions = new Subscription();

        this.dateSubscriptions.add(this.entitiesService.getList(this.currentDate, dayAfter).subscribe(entities => {
            this.types.forEach(type => {
                this.typeMap.set(type.id,
                    entities
                        .filter(e => !!e.entitytypes.find(et => et.id === type.id))
                        .sort((a, b) => {
                            return +a.order - +b.order;
                        })
                );
            });
        }));


        this.dateSubscriptions.add(combineLatest(
            this.planningService.getFilteredList(this.currentDate, dayAfter),
            this.userPlanningService.getFilteredList(this.currentDate, dayAfter)
        ).subscribe(([planning, userPlannings]) => {
            this.usedDrivers = [];
            this.planningMap = new Map<number, PlanningHasEntity[]>();
            this.plannings = planning;
            planning.forEach(pln => {
                pln.planning_has?.filter(ae => Utils.displayOnDate(ae, pln, this.currentDate))
                    .forEach(phe => {
                        [phe.entity_id, phe.truck_entity_id, phe.lowloader_entity_id].filter(e => !!e).forEach(anyEntityId => {
                            const planningList = this.planningMap.get(anyEntityId);
                            if (planningList) {
                                if (planningList.indexOf(phe) === -1) {
                                    planningList.push(phe);

                                    planningList.sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
                                }
                            } else {
                                this.planningMap.set(anyEntityId, [phe]);
                            }
                        });

                        if (phe.driver_user_id) {
                            this.usedDrivers.push(phe.driver_user_id);
                        }
                    });
            });
            userPlannings.forEach(userPlanning => {
                this.usedDrivers.push(userPlanning.user_id);
            });
        }));

        this.dateSubscriptions.add(this.userService.getFilteredList(this.currentDate, this.currentDate, false, true).subscribe(drivers => this.users = drivers));
        this.dateSubscriptions.add(this.userService.getFilteredByType(this.currentDate, this.currentDate, UserType.TRUCK_DRIVER).subscribe(drivers => {
            this.drivers = drivers.sort((a, b) => (a.function + a.name).localeCompare((b.function + b.name)));
        }));

        const endOfDay = this.Utils.newDate(this.currentDate);
        endOfDay.setDate(endOfDay.getHours() + 24);

        const leave$ = this.leaveService.getFilteredList(this.currentDate, dayAfter);
        const usersForLeave$ = this.userService.getFilteredList(this.currentDate, this.currentDate, false, true);
        this.dateSubscriptions.add(combineLatest(usersForLeave$, leave$)
            .subscribe(([users, leaves]) => {
                this.leaveList = leaves;
                this.leaveDayUserMap.clear();
                leaves.filter(l => !l.deleted_at).forEach(leave => {
                    this.leaveToMap(leave, users);
                });
            }));


        const leaves$ = (environment.disableAfas) ? of(null) : this.afasService.getLeaves(this.currentDate, dayAfter);
        const illness$ = (environment.disableAfas) ? of(null) : this.afasService.getIllness(this.currentDate, dayAfter);

        this.blockedMap = new Map<number, { icon: string; description: string; }>();
        this.dateSubscriptions.add(combineLatest([leaves$, illness$]).subscribe(([leaves, illness]) => {
            const leavesAndIlls = [...illness?.data.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()).map(ill => {
                return {
                    startDate: ill.startDate,
                    endDate: ill.endDate,
                    description: `${ill.absenceTypeDesc} (${formatDate(ill.startDate, 'EEEE d MMMM yyyy HH:mm', 'nl')} -  ${ill.endDate ? formatDate(ill.endDate, 'EEEE d MMMM yyyy HH:mm', 'nl') : 'onbekend'})`,
                    employeeId: ill.employeeId,
                    icon: 'fa-face-thermometer'
                };
            }) ?? [], ...leaves?.data.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()).map(leave => {
                return {
                    startDate: leave.startDate,
                    endDate: leave.endDate,
                    description: `${leave.leaveDescr} (${formatDate(leave.startDate, 'EEEE d MMMM yyyy HH:mm', 'nl')} -  ${leave.endDate ? formatDate(leave.endDate, 'EEEE d MMMM yyyy HH:mm', 'nl') : 'onbekend'})`,
                    employeeId: leave.employeeId,
                    icon: 'fa-user-slash'
                };
            }) ?? []];

            const loopDate = new Date(this.currentDate);
            while (loopDate.getTime() < dayAfter.getTime()) {
                const startOfDay = Utils.setTime(new Date(loopDate), 0, 0);
                const endOfDay = Utils.setTime(new Date(loopDate), 23, 59);
                leavesAndIlls.forEach(leaveOrIll => {
                    const user = this.users.find(u => u.afas_employee_id === leaveOrIll.employeeId);
                    if (Utils.newDate(leaveOrIll.startDate) <= endOfDay && (leaveOrIll.endDate === null || Utils.newDate(leaveOrIll.endDate) >= startOfDay) && user) {
                        this.blockedMap.set(user.id, {
                            description: leaveOrIll.description,
                            icon: leaveOrIll.icon
                        });
                    }
                });
                loopDate.setDate(loopDate.getDate() + 1);
            }
            this.leaveBlockedMap.emit(this.blockedMap);
        }));
    }

    private leaveToMap(leave: Leave, users: User[]) {

        const leaveDates = this.leaveService.generateLeaveDates(leave);
        const userIds = leave.users.map(u => u.id);
        const currentDateString = formatDate(this.currentDate, 'yyyy-MM-dd', 'nl');

        users.filter(u => leave.common ? true : userIds.includes(u.id)).forEach(user => {
            leaveDates.forEach(dates => {
                const dateString = formatDate(dates.beginDate, 'yyyy-MM-dd', 'nl');
                if (!leave.excluded.find(e =>
                    e.user_id === user.id && formatDate(e.date, 'yyyy-MM-dd', 'nl') === dateString)
                    && (formatDate(dates.beginDate, 'yyyy-MM-dd', 'nl') === currentDateString || formatDate(dates.endDate, 'yyyy-MM-dd', 'nl') === currentDateString)
                ) {
                    this.leaveDayUserMap.set(user.id, leave);
                }
            });
        });
        this.leaveUserMap.emit(this.leaveDayUserMap);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && !!changes['currentDate'] && this.types) {
            this.loadData();
        }
    }

    ngOnDestroy() {
        this.dateSubscriptions.unsubscribe();
        super.ngOnDestroy();
    }


    setHourType(hourtype: RealisationHourtype) {
        this.currentHourType = hourtype;
        this.addOrRemoveLeave(this.contextUserId, true);
    }

    addOrRemoveLeaveFromEntity(entity: Entity) {
        let userId = entity.driver_user_id;
        if (!userId) {
            userId = this.planningMap.get(entity.id)?.find(phe => !!phe.driver_user_id)?.driver_user_id;
        }
        if(userId) {
            this.addOrRemoveLeave(userId);
        }
    }

    addOrRemoveLeave(userId: number, setHourType?: boolean) {
        const user = this.users.find(u => u.id === userId);
        const day = this.currentDate;
        const dateString = formatDate(day, 'yyyy-MM-dd', 'nl');
        const checkHourtype = this.leaveDayUserMap.get(user.id)?.hourtype ?? this.currentHourType;
        let leave = this.leaveList.find(l =>
            l.hourtype === checkHourtype &&
            dateString === formatDate(l.begindate, 'yyyy-MM-dd', 'nl') &&
            !l.deleted_at
        );

        if (leave && (leave?.interval_week)) {
            this.confirmDialog.confirm(
                'Herhalend verlof',
                `Herhalend verlof kan alleen via de verlofmodule worden aangepast.`,
                'Sluiten',
                'Naar verlof detail').then(() => {

            }, () => {
                this.router.navigateByUrl(`/leave/${leave.id}`);
            });
            return;
        }

        if (!this.leaveDayUserMap.has(user.id) && leave?.comment !== this.contextComment) {
            leave = null;
        }

        const leaveUsers = () => {
            if (!leave.users) {
                leave.users = [];
            }
            let actionIsAdd = false;

            if (leave.excluded.find(e => e.user_id === user.id)) {
                leave.excluded.splice(leave.excluded.findIndex(e => e.user_id === user.id), 1);
                actionIsAdd = true;
            } else {
                if (leave?.common) {
                    leave.excluded.push({user_id: user.id, date: day});
                    this.leaveDayUserMap.delete(user.id);
                } else {
                    if (leave.users.find(u => u.id === user.id)) {
                        leave.users.splice(leave.users.findIndex(u => u.id === user.id), 1);
                        this.leaveDayUserMap.delete(user.id);
                        actionIsAdd = true;
                    } else {
                        leave.users.push(user);
                    }
                }
            }

            if (!this.leaveList.includes(leave)) {
                this.leaveList.push(leave);
            }
            if (!this.leavesToSave.includes(leave)) {
                this.leavesToSave.push(leave);
            }
            this.leaveToMap(leave, this.users);
            this.saveLeaveAction.emit();
            return actionIsAdd;
        };

        if (!leave) {
            if (!this.currentHourType) {
                return;
            }
            const dialogRef = this.dialog.open<AddLeaveNameDialogComponent, any, returnData>(AddLeaveNameDialogComponent, {
                width: '320px',
                panelClass: 'comment-edit-dialog',
                data: {
                    currentHourType: this.currentHourType,
                    contextComment: this.contextComment,
                    contextProject: this.contextProject,
                    projects: this.plannings.map(p => p.project)
                }
            });
            const subs = dialogRef.afterClosed().subscribe(data => {
                if (data) {
                    this.contextProject = data.project;
                    this.contextComment = data.comment;
                    leave = new Leave();
                    leave.hourtype = this.currentHourType;
                    if (leave.hourtype === RealisationHourtype.sleep) {
                        leave.afas_project_id = this.contextProject.afas_project_id;
                    }
                    const beginDate = new Date(day);
                    Utils.setTime(beginDate, Settings.DEFAULT_START, 0, 0);
                    const endDate = new Date(beginDate);
                    Utils.setTime(endDate, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
                    leave.begindate = beginDate;
                    leave.enddate = endDate;
                    const begintime = new Date();
                    Utils.setTime(begintime, Settings.DEFAULT_START, 0, 0);
                    const endtime = new Date();
                    Utils.setTime(endtime, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
                    leave.comment = data.comment;
                    leave.begintime = formatDate(begintime, 'HH:mm', 'nl');
                    leave.endtime = formatDate(endtime, 'HH:mm', 'nl');
                    leaveUsers();
                }
                subs.unsubscribe();

            });

        } else {

            if (leaveUsers() && setHourType) {
                this.addOrRemoveLeave(this.contextUserId);
            }
        }


    }


}
