<div class="d-flex align-items-center pb-3" mat-dialog-title>
    <div class="spacer title">Oude toolboxen</div>
    <div>
        <button (click)="close()" mat-icon-button tabindex="-1"><i class="fas fa-times"></i></button>
    </div>
</div>
<div class="dialog-content">
    <table class="table table-hover">
        <thead>
        <tr>
            <th>
                Toolbox
            </th>
            <th>
                Van
            </th>
            <th>
                Tot
            </th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="open(toolbox)" *ngFor="let toolbox of toolboxes" class="curpoint">
            <td>
                {{toolbox?.name}}
            </td>
            <td>
                {{toolbox.begindate | date : 'EEE d MMMM yyyy'}}
            </td>
            <td>
                {{toolbox.enddate | date : 'EEE d MMMM yyyy'}}
            </td>
        </tr>
        <tr *ngIf="!toolboxes?.length">
            <td colspan="3" class="text-center">
                Bezig met laden...
            </td>
        </tr>
        </tbody>
    </table>
</div>
