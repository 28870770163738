<div class="controls">
    <div class="header">
        <app-header (clicked)="setCenterDate()"
                    [mainTitle]="environment.isCodalt ? 'Planning Codalt' : 'Planning DvdS'"
                    [subTitle]="environment.isCodalt ? 'Overzicht' : 'Frees- en transport'">
        </app-header>
    </div>
    <div class="indicator">
        <div class="team-selector d-flex" *ngIf="!print && !mobile">
            <app-planning-filter (selectedTeams)="selectedTeamsChange($event)"></app-planning-filter>
            <div class="ml-2">
                <div class="settings">
                    <div class="trigger" [cdkMenuTriggerFor]="filterPerformer">
                        <div class="icon">
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div class="name">
                            Uitvoerder
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="show-in-use">
            <div *ngIf="readOnly" class="status-text" [class.concept]="fcPlanningStatus.value === 'concept'">
                <i class="fa-duotone fa-solid fa-triangle-exclamation" *ngIf="fcPlanningStatus.value === 'concept'"></i> {{fcPlanningStatus.value}}
            </div>
            <div class="mr-2" *ngIf="!print && !readOnly">
                <div class="settings">
                    <div class="trigger" [cdkMenuTriggerFor]="statusMenu">
                        <div class="icon">
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div class="name">
                            {{fcPlanningStatus.value ?? 'Status'}}
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!print && !readOnly">
                <div class="settings">
                    <div class="trigger" [cdkMenuTriggerFor]="configMenu">
                        <div class="icon">
                            <i class="fa-solid fa-chevron-down"></i>
                        </div>
                        <div class="name">
                            Weergave
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [print]="print"
                            [singleDate]="currentDate">
        </app-date-indicator>
    </div>
    <div class="print-pdf" *ngIf="!print">
        <button (click)="pdf()" color="primary" mat-icon-button>
            <i class="fa-light fa-file-pdf" *ngIf="!downloadingPdf"></i>
            <i class="fas fa-spinner fa-spin" *ngIf="downloadingPdf"></i>
        </button>
    </div>
    <div class="actions" *ngIf="!print">
        <app-settings-menu (selectedTeams)="selectedTeamsChange($event)"></app-settings-menu>
    </div>
    <div class="actions" *ngIf="print">
        PDF datum: {{now | date: 'd MMMM yyyy HH:mm'}}
    </div>
</div>
<div class="text-container">
    <div class="overview-and-side" [class.show-in-use]="fcShowInUse.value" cdkDropListGroup>

        <div class="overview">
            <div class="legend">
                <div *ngIf="!planningForms" class="loading">
                    Laden...
                </div>
                <div *ngIf="planningForms && !dayIsFixed" class="loading">
                    Deze dag is nog niet vastgezet in de weekplanning
                </div>
                <div *ngIf="dayIsFixed && planningForms?.length === 0" class="loading">
                    Er zijn geen defintieve planningen voor deze dag
                </div>
                <div *ngIf="!readOnlyAllStatus && planningForms && dayIsFixed && readOnly && fcPlanningStatus.value === 'besloten'" class="loading">
                    De planning voor {{currentDate | date: 'd MMMM yyyy'}} is nog niet vrijgegeven
                </div>
            </div>

            <ng-container *ngFor="let main of planningForms">
                <table class="table table-center"
                       [class.table-add-row]="!print && !readOnly"
                       *ngIf="dayIsFixed && (readOnlyAllStatus || !readOnly || fcPlanningStatus.value !== 'besloten')"
                       #myDropList
                       cdkDropList
                       cdkDropListLockAxis="y"
                       cdkDropListOrientation="vertical"
                       (cdkDropListDropped)="onDrop($event, main)">
                    <tr class="invisible">
                        <td [style.width.px]="30"></td>
                        <td [style.width.px]="200"></td>
                        <td [style.width.px]="100"></td>
                        <td [style.width.px]="100"></td>
                        <td colspan="5"></td>
                        <td [style.width.px]="150"></td>
                        <td [style.width.px]="150"></td>
                        <td colspan="2"></td>
                    </tr>
                    <tr [class.red]="main.planning.status_id !== PlanningStatus.definitief">
                        <th colspan="14"
                            class="team"
                            *ngIf="main.planning.is_transport">
                            TRANSPORT
                            <small>diversen</small>
                        </th>
                        <ng-container *ngIf="!main.planning.is_transport">
                            <th colspan="2"
                                class="project-id">
                                {{main.planning.afas_project_id}}
                            </th>
                            <th colspan="5"
                                class="team"
                                *ngIf="main.hasSets || (!main.planning.performer && !main.planning.asphalt_performer)"
                                (dblclick)="openPlanning(main.planning)">
                                {{EntityTypeMap.get(main.mainPlanning.entitytype)?.name}}
                                {{main.mainPlanning?.entity?.name}}
                                <small>{{(executors | byId: main.planning.asphalt_performer)?.name}}
                                    / {{(executors | byId: main.planning.performer)?.name}}</small>
                            </th>
                            <th colspan="4"
                                *ngIf="!main.hasSets && (main.planning.performer || main.planning.asphalt_performer)"
                                (dblclick)="openPlanning(main.planning)">
                                {{(executors | byId: main.planning.performer)?.name}}
                                <small>{{(executors | byId: main.planning.asphalt_performer)?.name}}</small>
                            </th>
                            <th *ngIf="main.hasSets || true">
                                {{main.mainPlanning?.begindate | date : 'EEE HH:mm'}}
                            </th>
                            <th *ngIf="main.hasSets || true">
                                {{main.mainPlanning?.enddate | date : 'HH:mm'}}
                            </th>
                            <th colspan="6" class="main-comment" [matTooltip]="main.planning.description">
                                {{main.planning.description}}
                            </th>
                        </ng-container>
                        <th width="300" *ngIf="(main.planning.is_transport || fcShowSmallMaterials.value) && !readOnly"></th>
                    </tr>
                    <tr *ngIf="main.planning.status_id == PlanningStatus.definitief">
                        <th [style.width.px]="50" colspan="1" *ngIf="!readOnly"></th>
                        <th [style.width.px]="300" colspan="1">Chauffeur</th>
                        <th [style.width.px]="150" colspan="2">Materieel</th>
                        <th [style.width.px]="100">Auto</th>
                        <th [style.width.px]="100">Dieplader</th>
                        <th [style.width.px]="100">Aanvang</th>
                        <th [style.width.px]="100">Tot</th>
                        <th [style.width.px]="40">Start</th>
                        <th [style.width.px]="300">Van</th>
                        <th [style.width.px]="300">Naar</th>
                        <th [style.width.px]="40">Stop</th>
                        <th [style.min-width.px]="300">Werkzaamheden</th>
                        <th [style.min-width.px]="100">Uitvoerder</th>
                        <th [style.width.px]="200" *ngIf="main.planning.is_transport">Project</th>
                        <td [style.min-width.px]="250" [attr.rowspan]="main.entities | entitiesRows" class="employees-equipment"
                            *ngIf="!main.planning.is_transport && this.fcShowSmallMaterials.value">
                            <div>
                                <div (click)="openEmployees(main.planning)" class="curpoint">
                                    <b>Personeel</b><br>
                                    <div *ngFor="let userPlanning of main.planning.user_planning" class="d-flex">
                                        <div class="ellipsis">
                                            {{userPlanning?.user?.name}}
                                        </div>
                                        <div class="col-auto pl-1 pr-0">
                                            <ng-container *ngIf="userPlanning?.begindate | isOtherDate : this.currentDate">
                                                {{userPlanning?.begindate | date : 'EE d MMM'}}
                                            </ng-container>
                                            {{userPlanning?.begindate | date : 'H:mm'}} {{userPlanning?.enddate | date : 'HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                                <div (click)="openEquipment(main.planning)" class="curpoint">
                                    <b>Klein materieel</b><br>
                                    <div *ngFor="let phe of main.planning.planning_has | entityType : 'visible_small' : true" class="d-flex">
                                        <div class="ellipsis">
                                            {{phe?.entity?.name}}
                                        </div>
                                        <div class="col-auto pl-1 pr-0">
                                            <ng-container *ngIf="phe?.begindate | isOtherDate : this.currentDate">
                                                {{phe?.begindate | date : 'EE d MMM'}}
                                            </ng-container>
                                            {{phe?.begindate | date : 'H:mm'}} {{phe?.enddate | date : 'HH:mm'}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>


                    <ng-container *ngFor="let pln of main.entities; let i = index;">
                        <tr app-transport-planning-row
                            [hiringAutocompleteOptions]="hiringAutocomplete.get(pln.fg.controls.entity_id.value)"
                            [blockedMap]="blockedMap"
                            [leaveDayUserMap]="leaveDayUserMap"
                            [readOnly]="readOnly"
                            [pln]="pln.planning"
                            [fg]="pln.fg"
                            [main]="main"
                            [cdkDragDisabled]="readOnly"
                            cdkDrag
                            cdkDragPreviewClass="cdk-drag-preview"
                            cdkDragLockAxis="y"
                            [cdkDragData]="pln"
                            [class.highlight]="pln.highlight"
                            [class.selected]="pln.selected"
                            class="contents"
                            [highlight]="false"
                            [i]="i"
                            [drivers]="drivers"
                            [lowLoaders]="lowLoaders"
                            [employees]="employees"
                            [executors]="executors"
                            [startTimesEntity]="startTimesEntity"
                            (addChooseEntity)="addChooseEntity($event)"
                            (deletePlanningEmitter)="deletePlanning($event)"
                            (duplicatePlanningEmitter)="duplicatePlanning($event)"
                            [endTimesEntity]="endTimesEntity"
                            [entitiesMap]="entitiesMap"
                            [planning]="planning"
                            [currentDate]="currentDate"
                            [entityTypes]="entityTypes"
                            [hiringMap]="hiringMap"
                            [trucks]="trucks"
                        ></tr>
                    </ng-container>

                    <tr class="add-row" *ngIf="!print && !readOnly">
                        <td colspan="15" *ngIf="main.planning.status_id !== PlanningStatus.definitief">
                            <button class="w-100" color="secondary" mat-button (click)="openPlanning(main.planning)">
                                Deze planning is nog niet definitief
                            </button>
                        </td>
                        <ng-container *ngIf="main.planning.status_id === PlanningStatus.definitief">
                            <td colspan="8" *ngIf="main.cutterTrucks+main.asphaltTrucks > 0">
                                <button (click)="addTrucks(main)" class="w-100" color="primary" mat-button>
                                    {{main.cutterTrucks + main.asphaltTrucks}} vrachtwagens toevoegen <i class="fas fa-plus"></i>
                                </button>
                            </td>
                            <td [attr.colspan]="main.cutterTrucks+main.asphaltTrucks > 0 ? 7 : 15">
                                <button (click)="addChooseType(main)" class="w-100" color="primary" mat-button>
                                    Regel toevoegen <i class="fas fa-plus"></i>
                                </button>
                            </td>
                        </ng-container>
                    </tr>
                </table>
            </ng-container>
        </div>
        <app-transport-in-use *ngIf="!readOnly"
                              (leaveBlockedMap)="blockedMap = $event"
                              (leaveUserMap)="leaveDayUserMap = $event"
                              [currentDate]="currentDate">
        </app-transport-in-use>
    </div>
    <app-transport-planning-text *ngIf="fcShowComments.value"></app-transport-planning-text>
</div>

<ng-template #filterPerformer>
    <div class="menu performer" cdkMenu>
        <div>
            Selecteer uitvoerder
        </div>
        <mat-selection-list [formControl]="fcPerformerIds" class="performer-selector-dropdown">
            <mat-list-option checkboxPosition="before" *ngFor="let executor of executors" [value]="executor.id">
                {{executor.name}}
            </mat-list-option>
        </mat-selection-list>
    </div>
</ng-template>
<ng-template #configMenu>
    <div class="menu" cdkMenu>
        <div>
            Weergave
        </div>
        <span class="d-block px-3">
            <mat-checkbox [formControl]="fcShowSmallMaterials" class="mr-3">Pers/mat</mat-checkbox><br>
            <mat-checkbox [formControl]="fcShowInUse" class="mr-3">Bezetting</mat-checkbox><br>
            <mat-checkbox [formControl]="fcShowComments">Kladblok</mat-checkbox>
        </span>
    </div>
</ng-template>
<ng-template #statusMenu>
    <div class="menu" cdkMenu>
        <div>
            Status
        </div>
        <span class="d-block px-3">
            <mat-radio-button
                [checked]="fcPlanningStatus.value === status"
                *ngFor="let status of ['besloten', 'concept','definitief']"
                cdkMenuItemRadio
                [cdkMenuItemChecked]="fcPlanningStatus.value === status"
                (cdkMenuItemTriggered)="fcPlanningStatus.setValue(status)" class="w-100">
              {{status}}
            </mat-radio-button>
        </span>
    </div>
</ng-template>
