<div class="controls">
    <div class="header">
        <app-header
            mainTitle="Planning DvdS"
            subTitle="Projecten">
        </app-header>
    </div>
    <div class="indicator">

    </div>

    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>

</div>
<div class="text-container">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <mat-form-field class="w-100" appearance="fill">
                <mat-label>Project</mat-label>
                <input matInput placeholder="Project (naam of nummer)" value="Sushi" [formControl]="fcProjectSearch">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <table class="table table-hover">
                <tr>
                    <th>
                        Nummer
                    </th>
                    <th>
                        Naam
                    </th>
                    <th>
                        hoofdproject
                    </th>
                    <th>
                        Projectleider
                    </th>
                    <th>
                        Uitvoerder
                    </th>
                    <th>
                        Asfalt uitvoerder
                    </th>
                    <th>
                        Locatie
                    </th>
                </tr>
                <tr *ngFor="let project of projects"
                    class="curpoint"
                    routerLink="/projects/{{project.afas_project_id}}">
                    <td>
                        {{project.afas_project_id}}
                    </td>
                    <td>
                        {{project.name}}
                    </td>
                    <td>
                        {{project.parent_id}}
                    </td>
                    <td>
                        {{project.projectmanager_id}}
                    </td>
                    <td>
                        {{project.performer_id}}
                    </td>
                    <td>
                        {{project.asphalt_performer_id}}
                    </td>
                    <td>
                        {{project.location}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
