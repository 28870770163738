<div class="dialog-container">
    <h1 class="condensed dialog-title">Inhuur</h1>
    <div class="dialog-content">
        <mat-selection-list (selectionChange)="listChange($event)">
            <mat-list-option [selected]="entityFormGroup.value.count > 0"
                             [value]="entityFormGroup.value.entity_id"
                             color="primary"
                             [disabled]="data.disabled"
                             checkboxPosition="before"
                             *ngFor="let entityFormGroup of formArray.controls">
                <div class="d-flex align-items-center">
                    <input class="count-input mr-2"
                           appDecimalInput
                           *ngIf="entitiesMap?.get(entityFormGroup.value.entity_id).name !== 'Asfaltploeg'"
                           [formControl]="entityFormGroup.controls.count"
                           placeholder="Aantal"
                           (click)="focus($event)">
                    <div>{{entitiesMap?.get(entityFormGroup.value.entity_id).name}}</div>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div class="d-flex flex-wrap">
        <button mat-button (click)="onNoClick()">Annuleren</button>
        <div class="spacer"></div>
        <button mat-raised-button (click)="select()" cdkFocusInitial color="primary">Selecteer</button>
    </div>
</div>
