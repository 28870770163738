import {Component, Inject, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Settlement} from '../../classes/settlement.class';
import {FormControl} from '@ngneat/reactive-forms';
import {SettlementsService} from '../../services/settlements.service';
import {debounceTime, startWith} from 'rxjs/operators';
import {ProjectService} from '../../services/project.service';
import {Project} from '../../classes/project.class';

@Component({
    selector: 'app-edit-settlement-project-dialog',
    templateUrl: './edit-settlement-project-dialog.component.html',
    styleUrls: ['./edit-settlement-project-dialog.component.scss']
})
export class EditSettlementProjectDialogComponent extends CodaltComponent implements OnInit {

    projects: Project[] = [];

    fcProjectSearch = new FormControl<string>();

    searching = false;

    constructor(public dialogRef: MatDialogRef<EditSettlementProjectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    settlements: Settlement[],
                    currentProjectId: string
                },
                private settlementService: SettlementsService,
                private projectService: ProjectService) {
        super();

    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200), startWith('')).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));
    }

    selectProject(project: Project) {
        this.subscriptions.add(this.settlementService.changeProject(this.data.settlements, project.afas_project_id).subscribe(settlements => {
            this.dialogRef.close(settlements.data);
        }));
    }

}
