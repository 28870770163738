import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {UserService, UserType} from '../services/user/user.service';
import {LocalStorage} from '../storage.class';

@Pipe({
    name: 'notApprovable'
})
export class NotApprovablePipe implements PipeTransform {

    transform(realisation?: Realisation, date?: Date): unknown {
        if (!realisation && !date) {
            return;
        }

        if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) && !realisation?.paid) {
            return;
        }

        if (realisation?.user?.hours_checked_by && realisation?.user?.hours_checked_by !== LocalStorage.user?.id) {
            return `Alleen ${realisation?.user?.hours_checked_user?.name} kan de uren goedkeuren`;
        }
    }

}
