import {Pipe, PipeTransform} from '@angular/core';
import {Planning} from './classes/planning.class';
import {Utils} from './utils.class';
import {PlanningHasEntity} from './classes/planning-has-entity.class';

@Pipe({
    name: 'multipleDriver'
})
export class MultipleDriverPipe implements PipeTransform {

    transform(userId: number, ownPlanning: PlanningHasEntity, ownMainPlanning: Planning, planning: Planning[]): boolean {
        let multiple = false;
        if (userId) {
            planning?.filter(pln => ownMainPlanning.is_transport ? !pln.is_transport : pln.id !== ownPlanning.planning_id).forEach(pln => {
                pln.planning_has?.forEach(plnh => {
                    if (plnh.id !== ownPlanning.id && plnh.driver_user_id === userId && Utils.hourDiff(ownPlanning, plnh) < 12) {
                        multiple = true;
                    }
                });
            });
        }

        return multiple;
    }

}
