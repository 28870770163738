<div class="hours-check first">
    <div class="header">
        <div class="container">
            <div class="content justify-content-center">
                <mat-checkbox [formControl]="fcHideSent">
                    Verberg verstuurde boekingsregels
                </mat-checkbox>
                <mat-checkbox [formControl]="fcShowOnlyMissing">
                    Toon alleen incomplete regels
                </mat-checkbox>
                <mat-form-field *ngIf="!hideDateSelection">
                    <input (focus)="pickerStart.open()" [matDatepicker]="pickerStart"
                           [formControl]="fcEndDate"
                           matInput placeholder="Einddatum">
                    <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button color="primary" class="planning-button" *ngIf="!settlementsSubscription$" [disabled]="itemsToSend === 0" (click)="sendAllToAfas()">
                    Verstuur openstaande boekingsregels ({{itemsToSend}})
                </button>
                <button mat-raised-button color="primary" class="planning-button" *ngIf="settlementsSubscription$" (click)="cancelSendToAfas()">
                    Stoppen met versturen ({{itemsToSend}})
                </button>
            </div>
        </div>
    </div>
</div>

<div class="hours-check last">
    <div class="list" *ngIf="allSettlements">
        <ng-container *ngFor="let settlement of allSettlements; let even = even">
            <div [class.warn]="!even" class="list-item"
                 *ngIf="!(fcHideSent.value && (settlement.minutes === 0 || settlement.afas_ptrealization_id) && !settlement.afas_error) &&
                     !(fcShowOnlyMissing.value && !settlement.missing_values && !settlement.afas_error)">
                <div class="container">
                    <div class="content">
                        <div class="name-wrapper">
                            <div class="name">
                                {{settlement.user?.name}}
                            </div>
                            <div class="caption curpoint" (click)="editSettlement(settlement, 'hourtype')">
                                {{settlement.hourtype?.description}} ({{settlement.hourtype?.code}})
                            </div>
                            <div class="caption description">
                                {{settlement.bookdate | date : 'dd MMMM yyyy'}}<br>
                                <small>{{settlement.description}}</small>
                            </div>
                        </div>
                        <div class="prefix">
                            <button mat-icon-button disabled class="icon-button invalid"
                                    *ngIf="settlement.missing_values && !settlement.afas_ptrealization_id">
                                <i class="fa-light fa-person-circle-exclamation" matTooltip="Missende waarden"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button" *ngIf="!settlement.send_to_afas && !settlement.missing_values">
                                <i class="fa-light fa-user-clock" matTooltip="Wacht op versturen"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button"
                                    *ngIf="!settlement.afas_ptrealization_id && settlement.send_to_afas && !settlement.afas_error && settlement.minutes !== 0">
                                <i class="fa-light fa-hourglass-clock" matTooltip="Wordt verstuurd"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button valid" *ngIf="settlement.afas_ptrealization_id">
                                <i class="fa-light fa-check" matTooltip="Is verstuurd"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button valid" *ngIf="settlement.send_to_afas && settlement.minutes === 0">
                                <i class="fa-light fa-check" matTooltip="Nulwaarde, versturen niet nodig"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button invalid" *ngIf="settlement.sent_to_afas && settlement.afas_error">
                                <i class="fa-light fa-circle-exclamation" matTooltip="Fout bij versturen"></i>
                            </button>
                        </div>
                        <div class="times">
                            <div class="top">
                                <div class="time">
                                    {{settlement.minutes | minutes}}<br>
                                    <small>{{settlement.amount}}</small><br>
                                </div>
                                <div class="time"
                                     (click)="editSettlement(settlement, 'project')"
                                     [class.downlight]="!settlement.afas_project_id">
                                    {{settlement.afas_project_id ?? settlement.realisation?.afas_project_id ?? settlement.realisation?.planning?.afas_project_id ?? settlement.realisation?.user_planning?.afas_project_id ?? '-'}}
                                    <br>
                                    <small>Project</small><br>
                                </div>
                                <div class="time"
                                     [class.downlight]="!settlement.afas_employee_id"
                                     [class.difference]="!(settlement.afas_employee_id ?? settlement.user?.afas_employee_id)">
                                    {{settlement.afas_employee_id ?? settlement.user?.afas_employee_id ?? '-'}}<br>
                                    <small>Medewerker</small><br>
                                </div>
                                <div class="time" *ngIf="settlement.send_to_afas">
                                    #{{settlement.afas_ptrealization_id ?? '-'}}<br>
                                    <small>Afas</small><br>
                                </div>
                            </div>
                            <div class="comments valid" *ngIf="settlement.afas_ptrealization_id && !settlement.afas_error">
                                <i>Verstuurd naar Afas op {{settlement.sent_to_afas | date : 'EEE dd MMM yyyy, HH:mm'}}</i>
                            </div>
                            <div class="comments invalid" *ngIf="settlement.afas_error">
                                <i>Fout bij versturen naar Afas:</i>
                                {{settlement.afas_error}}
                            </div>
                        </div>
                        <div class="buttons"
                             [matTooltip]="settlement.missing_values ? 'Project of medewerker is niet juist gevuld' : 'Verstuur naar Afas'">
                            <button mat-icon-button
                                    color="primary"
                                    [disabled]="settlement.missing_values || !!settlement.afas_ptrealization_id"
                                    *ngIf="!settlement.afas_ptrealization_id"
                                    (click)="sendToAfas(settlement)"
                                    class="planning-button">
                                <i class="fas fa-paper-plane-top" *ngIf="!settlement.send_to_afas || settlement.afas_error"></i>
                                <i class="fa-duotone fa-loader fa-spin" *ngIf="settlement.send_to_afas && !settlement.sent_to_afas"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="list-item" *ngIf="sentCount > 0 && fcHideSent.value" (dblclick)="fcHideSent.setValue(false)">
            <div class="container text-center font-italic py-3">
                Er zijn {{sentCount}} verborgen verstuurde regels
            </div>
        </div>
        <div class="list-item" *ngIf="correctCount > 0 && fcShowOnlyMissing.value" (dblclick)="fcShowOnlyMissing.setValue(false)">
            <div class="container text-center font-italic py-3">
                Er zijn {{correctCount}} verborgen complete regels, waarvan {{itemsToSend}} nog verstuurd moeten worden
            </div>
        </div>
    </div>
</div>
