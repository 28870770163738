import {Component, OnInit} from '@angular/core';
import {debounceTime, startWith} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CodaltComponent} from '../codalt.component';
import {ProjectService} from '../services/project.service';
import {Project} from '../classes/project.class';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends CodaltComponent implements OnInit {

    fcProjectSearch = new FormControl();

    projects: Project[];

    constructor(private projectService: ProjectService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.fcProjectSearch.valueChanges.pipe(debounceTime(200), startWith('')).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));
    }

}
