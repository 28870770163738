import {Pipe, PipeTransform} from '@angular/core';
import {RealisationHourtype} from '../classes/realisation';

@Pipe({
    name: 'hourtype'
})
export class HourtypePipe implements PipeTransform {

    transform(type: RealisationHourtype, overrideWork?: string): unknown {
        switch (type) {
            case RealisationHourtype.travel_to:
            case RealisationHourtype.travel_back:
                return 'Reistijd';
            case RealisationHourtype.driving_to:
            case RealisationHourtype.driving_back:
                return 'Transporttijd';
            case RealisationHourtype.worktime:
                return overrideWork ?? 'Werktijd';
            case RealisationHourtype.day_off:
                return 'Roostervrij';
            case RealisationHourtype.frost:
                return 'Vorst';
            case RealisationHourtype.illness:
                return 'Ziek';
            case RealisationHourtype.sleep:
                return 'Slapen';
            case RealisationHourtype.education:
                return 'Scholing';
            case RealisationHourtype.bank_holiday:
                return 'Feestdag';
            case RealisationHourtype.short_absence:
                return 'Kort verzuim';
            case RealisationHourtype.paid_leave:
                return 'Doorbetaald verlof';
            case RealisationHourtype.time_for_time:
                return 'Tijd voor tijd';
            case RealisationHourtype.additional_parental:
                return 'Aanv. geb verl. + ouderschapsv';
            case RealisationHourtype.special_leave:
                return 'Bijzonder verlof';
            case RealisationHourtype.zzp_leave:
                return 'ZZP niet beschikbaar';
        }
        return '';
    }

}
