<div class="document" [class.pb-5]="!print">
    <div class="page" *ngIf="fgWeekComment?.value?.comment?.length > 10 && print">
        <table class="table">
            <tr>
                <th>
                    Algemene opmerkingen week {{beginDate | date : 'w'}}
                </th>
            </tr>
            <tr>
                <td>
                    <div [innerHTML]="fgWeekComment.value.comment | safeHtml">

                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="mb-5" *ngIf="fgWeekComment && !print && !noComments">
        <simple-tiny
            class="w-100 mb-3"
            height="600"
            [showMenuBar]="false"
            [content]="fgWeekComment.controls.comment">
        </simple-tiny>
    </div>

    <ng-container *ngFor="let day of days">
        <div class="page"
             *ngIf="(!noComments && day.commentsAsphalt?.length > 0) || (!noComments && day.ownComment && (UserType.ASPHALTEXECUTOR | hasUserGroup) && !print) || (!noAsphalt && (day.asphalt?.length > 0 || day.adhesive?.length > 0))">
            <h2>Dagrapport asfalt {{day.date | date : 'EEEE dd MMMM yyyy'}} {{projectId}}</h2>

            <ng-container *ngIf="day.asphalt?.length > 0 && !noAsphalt">
                <h2>
                    Asfalt
                </h2>
                <app-asphalt-used-list #asphaltUsedList
                                       [project]="project"
                                       [projectUsedAsphaltList]="day.asphalt"
                                       [date]="day.date"
                                       [print]="print"
                                       [formsDisabled]="true"></app-asphalt-used-list>
            </ng-container>

            <ng-container *ngIf="day.adhesive?.length > 0">
                <h2>
                    Kleeflagen
                </h2>
                <app-asphalt-used-list #asphaltAdhesiveList
                                       [projectUsedAsphaltList]="day.adhesive"
                                       [project]="project"
                                       [date]="day.date"
                                       [print]="print"
                                       [isAdhesive]="true"
                                       [formsDisabled]="true"></app-asphalt-used-list>
            </ng-container>
            <ng-container *ngIf="!noComments">
                <ng-container *ngIf="day.commentsAsphalt?.length > 0 || day.ownComment">
                    <simple-tiny *ngIf="day.ownComment && (UserType.ASPHALTEXECUTOR | hasUserGroup)"
                                 class="w-100 mb-3"
                                 height="600"
                                 [showMenuBar]="false"
                                 [content]="day.ownComment.controls.comment">
                    </simple-tiny>
                    <table class="table" *ngIf="!noComments">
                        <ng-container *ngFor="let comment of day.commentsAsphalt">
                            <ng-container *ngIf="comment?.comment.length > 10">
                                <tr>
                                    <th>
                                        {{comment.user?.name ?? 'Opmerkingen'}}
                                    </th>
                                    <th width="100" class="text-right">
                                        <ng-container *ngIf="!print">
                                            <i class="fa fa-pencil mr-3 curpoint" (click)="editComment(comment)"></i>
                                            <i class="fa fa-history curpoint" [class.changed]="comment.changed_by_count > 1"
                                               (click)="commentHistory(comment)"></i>
                                        </ng-container>
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div [innerHTML]="comment?.comment | safeHtml"></div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </table>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="!noComments">
        <ng-container *ngFor="let day of days">
            <div class="page" *ngIf="day.comments?.length > 0 || (day.ownComment && !(UserType.ASPHALTEXECUTOR | hasUserGroup))">
                <h2>Dagrapport {{day.date | date : 'EEEE dd MMMM yyyy'}} {{projectId}}</h2>

                <ng-container *ngIf="day.comments?.length > 0 || day.ownComment">
                    <simple-tiny *ngIf="day.ownComment && !(UserType.ASPHALTEXECUTOR | hasUserGroup)"
                                 class="w-100 mb-3"
                                 height="600"
                                 [showMenuBar]="false"
                                 [content]="day.ownComment.controls.comment">
                    </simple-tiny>
                    <table class="table">
                        <ng-container *ngFor="let comment of day.comments">
                            <ng-container *ngIf="comment?.comment.length > 10">
                                <tr>
                                    <th>{{comment.user?.name ?? 'Opmerkingen'}}</th>
                                    <th width="100" class="text-right">
                                        <ng-container *ngIf="!print">
                                            <i class="fa fa-pencil mr-3 curpoint" (click)="editComment(comment)"></i>
                                            <i class="fa fa-history curpoint" [class.changed]="comment.changed_by_count > 1"
                                               (click)="commentHistory(comment)"></i>
                                        </ng-container>
                                    </th>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div [innerHTML]="comment?.comment | safeHtml"></div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </table>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <app-project-week-report-hours *ngIf="!noHours"
                                   [project]="project"
                                   [weekComment]="weekComment"
                                   [print]="print"
                                   [realisations]="realisations"
                                   [beginDate]="beginDate"
                                   [endDate]="endDate"
                                   [projectId]="projectId"
                                   [onlyWeekTotals]="onlyWeekTotals"
                                   (readyforpuppeteer)="readyForPuppeteer('userHoursReady')"
                                   (checkChanges)="checkChanges()"
                                   field="user_id">
    </app-project-week-report-hours>


    <app-project-week-report-hours *ngIf="!noHours"
                                   [project]="project"
                                   [weekComment]="weekComment"
                                   [print]="print"
                                   [realisations]="realisations"
                                   [beginDate]="beginDate"
                                   [endDate]="endDate"
                                   [projectId]="projectId"
                                   [onlyWeekTotals]="onlyWeekTotals"
                                   (readyforpuppeteer)="readyForPuppeteer('entityHoursReady')"
                                   (checkChanges)="checkChanges()"
                                   field="entity_id">
    </app-project-week-report-hours>

</div>

<div class="button-wrapper" *ngIf="fgWeekComment && !print">
    <div class="container-xl">
        <div class="buttons">
            <button mat-raised-button color="accent" routerLink="/project-dagrapport/{{projectId}}/{{beginDate | date : 'yyyy-MM-dd'}}">
                Dagrapport
            </button>
            <div class="settings-menu">
                <app-settings-menu [projectId]="project?.afas_project_id" [date]="beginDate"></app-settings-menu>
            </div>
            <button mat-raised-button color="primary" (click)="saveComment()" [disabled]="!fgWeekComment.dirty && !faAllFcDayComment.dirty">
                {{saved ? 'Wijzigingen opgeslagen' : (fgWeekComment.dirty || faAllFcDayComment.dirty) ? 'Openstaande wijzigingen opslaan' : 'Er zijn geen wijzigingen'}}
                <i class="fas fa-spinner fa-spin" *ngIf="saving"></i>
            </button>

        </div>
    </div>
</div>
