import {Pipe, PipeTransform} from '@angular/core';
import {CustomGroup} from '../../../classes/project-comment';
import {GroupTotals} from './group-totals';

@Pipe({
    name: 'groupDayChanges'
})
export class GroupDayChangesPipe implements PipeTransform {

    transform(customGroup: CustomGroup, groupTotals: GroupTotals[], day: string, field: string, trigger?: any): boolean {
        const userGroup = groupTotals.find(ugt => ugt.group === customGroup.group);
        const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        const index = days.indexOf(day);

        let notEqual = false;
        userGroup?.totals?.forEach(userGr => {
            const existingRow = customGroup.rows.find(r => r[field] === userGr.realisations[0].user_id);
            if (existingRow && existingRow[day] != userGr.days[index].total) {
                notEqual = true;
            }
        });

        return notEqual;
    }

}
