<div class="schedule-navbar">
    <mat-form-field class="picker-date">
        <input (focus)="pickerDate.open()"
               [matDatepicker]="pickerDate"
               [formControl]="fcDate"
               matInput
               tabindex="-1">
        <mat-datepicker #pickerDate></mat-datepicker>
    </mat-form-field>
    <div>
        <button mat-icon-button color="white" (click)="prev()">
            <i class="fas fa-chevron-left"></i>
        </button>
    </div>
    <div class="content" (click)="pickerDate.open()">
        <p class="large">{{date | date : 'd MMM'}}</p>
        <p class="small">{{date | date : 'EEEE'}}</p>
    </div>
    <div>
        <button mat-icon-button color="white" (click)="next()">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="hours-check first last">
    <div class="list" *ngIf="show">
        <div *ngFor="let user of users; let even = even"
             [class.warn]="!even"
             class="list-item">
            <div class="container">
                <div class="content">
                    <div class="name-wrapper">
                        <div class="name">
                            {{user.name}}
                        </div>
                        <div class="caption">
                            {{userRealisationMap.get(user.id)?.projects?.join(', ')}}
                        </div>
                    </div>
                    <div class="prefix">
                        <ng-container *ngIf="!userRealisationMap.get(user.id)">
                            <button mat-icon-button disabled class="icon-button">
                                <i class="fa-light fa-user-slash" matTooltip="Medewerker niet ingepland"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button">
                                <i class="fa-light fa-user-slash" matTooltip="Medewerker niet ingepland"></i>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="userRealisationMap.get(user.id)">
                            <button mat-icon-button disabled class="icon-button invalid"
                                    [class.invalid]="!userRealisationMap.get(user.id)?.allSubmitted && userRealisationMap.get(user.id)?.adjustable"
                                    [class.valid]="userRealisationMap.get(user.id)?.allSubmitted">
                                <i class="fa-light fa-user-slash"
                                   matTooltip="Gebruiker heeft geen uren ingevoerd binnen 24 uur"
                                   *ngIf="!userRealisationMap.get(user.id)?.allSubmitted && userRealisationMap.get(user.id)?.adjustable && !userRealisationMap.get(user.id)?.adjustable_time"></i>
                                <i class="fa-light fa-user-clock"
                                   matTooltip="Wacht op invoer gebruiker"
                                   *ngIf="!userRealisationMap.get(user.id)?.allSubmitted && userRealisationMap.get(user.id)?.adjustable && userRealisationMap.get(user.id)?.adjustable_time"></i>
                                <i class="fa-light fa-check-circle"
                                   [matTooltip]="userRealisationMap.get(user.id)?.adjustable ? 'Uren ingevoerd' : 'Gebruiker registreert zelf geen uren'"
                                   *ngIf="userRealisationMap.get(user.id)?.allSubmitted || !userRealisationMap.get(user.id)?.adjustable"></i>
                            </button>
                            <button mat-icon-button disabled class="icon-button"
                                    [class.invalid]="!userRealisationMap.get(user.id)?.allApproved && (!userRealisationMap.get(user.id)?.adjustable || !userRealisationMap.get(user.id)?.adjustable_time)"
                                    [class.valid]="userRealisationMap.get(user.id)?.allApproved">
                                <i class="fa-light fa-check-circle" *ngIf="userRealisationMap.get(user.id)?.allApproved" matTooltip="Uren goedgekeurd"></i>
                                <i class="fa-light fa-user-clock" *ngIf="!userRealisationMap.get(user.id)?.allApproved"
                                   matTooltip="Uren wachten op goedkeuring"></i>
                            </button>
                        </ng-container>
                    </div>
                    <div class="times">
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.worktime < 1">
                            {{userRealisationMap.get(user.id)?.worktime | minutes}}
                            <small>werktijd</small>
                        </div>
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.sleep < 1">
                            {{userRealisationMap.get(user.id)?.sleep | minutes}}
                            <small>slaap</small>
                        </div>
                        <div class="time"
                             [class.downlight]="userRealisationMap.get(user.id)?.break < 1"
                             [class.difference]="userRealisationMap.get(user.id)?.pause_adjusted">
                            {{userRealisationMap.get(user.id)?.break | minutes}}
                            <small>pauze</small>
                        </div>
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.drivingtime < 1">
                            {{userRealisationMap.get(user.id)?.drivingtime | minutes}}
                            <small>transporttijd</small>
                        </div>
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.traveltime < 1">
                            {{userRealisationMap.get(user.id)?.traveltime | minutes}}
                            <small>reistijd</small>
                        </div>
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.leavetime < 1">
                            {{userRealisationMap.get(user.id)?.leavetime | minutes}}
                            <small>verlof</small>
                        </div>
                        <div class="time" [class.downlight]="userRealisationMap.get(user.id)?.illnesstime < 1">
                            {{userRealisationMap.get(user.id)?.illnesstime | minutes}}
                            <small>verzuim</small>
                        </div>
                    </div>
                    <div class="buttons">
                        <button mat-icon-button
                                color="primary"
                                (click)="openHours(date, user)"
                                class="planning-button">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                        <div class="comment">
                            <button mat-icon-button
                                    *ngIf="false"
                                    color="primary"
                                    class="planning-button">
                                <i class="fas fa-history"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="button-wrapper">
    <div class="container">
        <div class="buttons">
            <button mat-raised-button color="accent" class="planning-button" routerLink="/weekcontrole/{{date | date : 'yyyy'}}/{{date | date : 'w'}}">
                Weekcontrole
            </button>
            <div class="settings-menu">
                <app-settings-menu [date]="date"></app-settings-menu>
            </div>
            <button mat-raised-button color="primary" class="planning-button" routerLink="/check-hours/{{date | date : 'yyyy-MM-dd'}}">
                Urencontrole
            </button>
        </div>
    </div>
</div>
