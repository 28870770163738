<div class="dialog-container">
    <h1 class="condensed dialog-title">Materieel van voorgaande dagen</h1>
    <div class="dialog-content">
        <div *ngIf="!realisationList?.length && !!realisationList">
            Er zijn geen materieelstukken die overgenomen kunnen worden van voorgaande dagen.
        </div>
        <div *ngIf="!realisationList?.length && !realisationList">
            Laden...
        </div>
        <mat-selection-list [formControl]="fcEntities">
            <ng-container *ngFor="let entity of realisationList">
                <ng-container *ngFor="let i of [].constructor(entity.count)">
                    <mat-list-option [value]="entity">
                        <div mat-line>{{entity.name}}</div>
                        <div mat-line>
                            <i>Gebruikt op: </i>
                            <ng-container *ngFor="let r of entity.realisations; let last = last">
                                {{r.bookdate | date : 'EEE d MMM'}}{{last ? '' : ', '}}
                            </ng-container>
                        </div>
                    </mat-list-option>
                </ng-container>
            </ng-container>
        </mat-selection-list>
    </div>
    <div class="d-flex flex-wrap pt-3">
        <button mat-button mat-dialog-close>Annuleren</button>
        <div class="spacer"></div>
        <button mat-raised-button (click)="take()" cdkFocusInitial color="primary">Overnemen</button>
    </div>
</div>
