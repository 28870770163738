<div class="modal-inner">

    <div class="modal-inner-inner">
        <h1>Wijzig projectnummer</h1>
        <input placeholder="Zoeken in projecten" [formControl]="fcProjectSearch">
        <div class="results">
            <div class="result text-center" *ngIf="searching">
                aan het zoeken...
            </div>
            <div class="result text-center" *ngIf="!searching && projects?.length === 0">
                {{fcProjectSearch.value?.length > 0 ? 'project niet gevonden' : 'voer een projectnummer in'}}
            </div>
            <div class="result"
                 [class.active]="project.afas_project_id === data.currentProjectId"
                 (click)="selectProject(project)"
                 *ngFor="let project of projects">
                <strong>{{project.afas_project_id}}</strong> {{project.location}}
                <ng-container *ngIf="project.afas_project_id === data.currentProjectId"> (huidig)</ng-container>
            </div>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button class="planning-button cancel" mat-dialog-close="">Annuleren</button>
    </div>
</div>
