import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {Utils} from '../utils.class';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormControl} from '@ngneat/reactive-forms';
import {Project} from '../classes/project.class';
import {RealisationService} from '../services/realisation.service';
import {formatDate} from '@angular/common';
import {User} from '../classes/user.class';
import {UserService, UserType} from '../services/user/user.service';
import {environment} from '../../environments/environment';
import {ProjectService} from '../services/project.service';
import {ProjectWeekReportComponent} from './project-week-report/project-week-report.component';
import {MatDialog} from '@angular/material/dialog';
import {WeekreportPdfOptionsDialogComponent} from './weekreport-pdf-options-dialog/weekreport-pdf-options-dialog.component';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
    selector: 'app-project-week-report-config',
    templateUrl: './project-week-report-config.component.html',
    styleUrls: ['./project-week-report-config.component.scss']
})
export class ProjectWeekReportConfigComponent extends CodaltComponent implements OnInit {

    @ViewChild('projectWeekReport') appProjectWeekReport: ProjectWeekReportComponent;
    projectSearch$ = new Subject<string>();
    searching = false;

    beginDate: Date;
    endDate: Date;
    today = new Date();
    year: number;
    week = 1;
    fcOnlyWeekTotals = new FormControl(false);
    fcNoHours = new FormControl(false);
    fcNoComments = new FormControl(false);
    fcNoAsphalt = new FormControl(false);
    @HostBinding('class.print') print = false;

    fcProjectId = new FormControl<string>();
    project: Project;

    executors: User[];

    projects: Project[];

    downloadingPdf = false;

    constructor(private activatedRoute: ActivatedRoute,
                private realisationService: RealisationService,
                private projectService: ProjectService,
                private userService: UserService,
                private dialog: MatDialog,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Weekrapport' + environment.titleAppend);
    }

    openChangesBackActionCheck() {
        if (this.appProjectWeekReport) {
            return this.appProjectWeekReport.openChangesBackActionCheck();
        }
        return Promise.resolve(true);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.projectSearch$.pipe(debounceTime(400)).subscribe(searchProjectId => {
            this.getProjects(searchProjectId);
            this.searching = searchProjectId?.length > 0;
        }));
        this.subscriptions.add(this.fcProjectId.valueChanges.subscribe(fcProjectId => {
            this.openChangesBackActionCheck().then((result) => {
                if (result) {
                    this.project = this.projects?.find(p => p.afas_project_id === fcProjectId);
                    if (this.project?.week_numbers && this.searching) {

                        if (!this.project.realisation_times.find(rt => rt.week == this.week && rt.year == this.year)) {
                            this.week = this.project.realisation_times[this.project.realisation_times.length - 1].week;
                            this.year = this.project.realisation_times[this.project.realisation_times.length - 1].year;
                        }
                    }
                    if (!this.print) {
                        this.router.navigateByUrl(`project-rapport/${fcProjectId ?? ''}/${this.week}/${this.year}/${this.fcOnlyWeekTotals.value}/${this.fcNoHours.value}/${this.fcNoComments.value}/${this.fcNoAsphalt.value}`);
                    }

                } else {
                    this.fcProjectId.setValue(this.project.afas_project_id, {emitEvent: false});
                }
            });
        }));
        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { project, period, year, onlyTotals, noHours, noComments, noAsphalt }) => {
            const queryParams = this.activatedRoute.snapshot.queryParams as { print };
            this.print = !!queryParams?.print;
            this.fcOnlyWeekTotals.patchValue(params.onlyTotals === 'true', {emitEvent: false});
            this.fcNoHours.patchValue(params.noHours === 'true', {emitEvent: false});
            this.fcNoComments.patchValue(params.noComments === 'true', {emitEvent: false});
            this.fcNoAsphalt.patchValue(params.noAsphalt === 'true', {emitEvent: false});
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            const year = monday.getFullYear();
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            this.beginDate = new Date(monday);

            const period = +(params.period ?? formatDate(new Date(), 'w', 'nl'));
            this.beginDate.setDate(this.beginDate.getDate() + (7 * (period - 1)));
            this.week = period;

            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 7);
            this.year = period > 1 ? this.beginDate.getFullYear() : this.endDate.getFullYear();
            this.fcProjectId.setValue(params.project === 'null' ? null : params.project);

            this.getProjects();
        }));
        this.subscriptions.add(this.fcOnlyWeekTotals.valueChanges.subscribe(() => {
            this.router.navigateByUrl(`project-rapport/${this.project.afas_project_id ?? ''}/${this.week}/${this.year}/${this.fcOnlyWeekTotals.value}/${this.fcNoHours.value}/${this.fcNoComments.value}/${this.fcNoAsphalt.value}`);
        }));
    }

    private getProjects(searchProjects?: string) {
        this.subscriptions.add(this.realisationService.getProjectsPeriod(this.beginDate, this.endDate, this.fcProjectId.value, searchProjects).subscribe(projects => {
            this.projects = projects.data;
            if (!this.fcProjectId.value) {
                this.fcProjectId.setValue(this.projects[0]?.afas_project_id);
            }
            if (!this.project) {
                this.project = this.projects?.find(p => p.afas_project_id === this.fcProjectId.value && !!p.asphalt_performer);
            }
            if (!this.project) {
                this.project = this.projects?.find(p => p.afas_project_id === this.fcProjectId.value);
            }
        }));
    }

    selectWeek(week: { week: number; year: number; }) {
        this.router.navigateByUrl(`project-rapport/${this.project.afas_project_id ?? ''}/${week.week}/${week.year}/${this.fcOnlyWeekTotals.value}/${this.fcNoHours.value}/${this.fcNoComments.value}/${this.fcNoAsphalt.value}`);
    }

    next() {
        let week = this.week + 1;
        let year = this.year;
        if (week > 52) {
            week = 1;
            year += 1;
        }
        this.router.navigateByUrl(`project-rapport/${this.fcProjectId.value ?? ''}/${week}/${year}/${this.fcOnlyWeekTotals.value}/${this.fcNoHours.value}/${this.fcNoComments.value}/${this.fcNoAsphalt.value}`);
    }

    prev() {
        let week = this.week - 1;
        let year = this.year;
        if (week < 1) {
            week = 52;
            year -= 1;
        }
        this.router.navigateByUrl(`project-rapport/${this.fcProjectId.value ?? ''}/${week}/${year}/${this.fcOnlyWeekTotals.value}/${this.fcNoHours.value}/${this.fcNoComments.value}/${this.fcNoAsphalt.value}`);
    }

    pdf() {

        this.dialog.open(WeekreportPdfOptionsDialogComponent, {
            maxWidth: '99vw',
            width: '320px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                week: this.week,
                year: this.year,
                projectId: this.fcProjectId.value
            }
        });


    }


}
