import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EntitiesService} from '../services/entities/entities.service';
import {UserService} from '../services/user/user.service';
import {PlanningService} from '../services/planning/planning.service';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {combineLatest, Subscription} from 'rxjs';
import {Entity} from '../classes/entity.class';

@Component({
    selector: 'app-hire-people-dialog',
    templateUrl: './hire-people-dialog.component.html',
    styleUrls: ['./hire-people-dialog.component.scss']
})
export class HirePeopleDialogComponent implements OnInit {

    selectedWeek: WeekOption;

    planning: Planning[];
    weeks: WeekOption[] = [];
    today = new Date();

    planningsPerDay: {
        day: Date,
        plannings: Planning[]
    }[];

    entitiesMap: Map<number, Entity>;

    constructor(
        public dialogRef: MatDialogRef<HirePeopleDialogComponent>,
        private entitiesService: EntitiesService,
        private usersService: UserService,
        private planningService: PlanningService) {
    }

    ngOnInit() {
        const monday = new Date();

        // Calculate the monday
        monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
        Utils.setTime(monday, 0, 0);
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        this.weeks.push({
            startDate: new Date(monday),
            endDate: new Date(sunday)
        });
        for (let i = 1; i < 7; i++) {
            monday.setDate(monday.getDate() + 7);
            sunday.setDate(sunday.getDate() + 7);
            this.weeks.push({
                startDate: new Date(monday),
                endDate: new Date(sunday)
            });
        }
    }

    showHire(week: WeekOption) {
        this.selectedWeek = week;
        const entities$ = this.entitiesService.getMap();
        const planning$ = this.planningService.getFilteredList(week.startDate, week.endDate);
        const users$ = this.usersService.getMap();
        const subs = new Subscription();
        this.planningsPerDay = [];
        subs.add(combineLatest(entities$, planning$, users$).subscribe(([entitiesMap, planningList, usersMap]) => {
            this.entitiesMap = entitiesMap;
            this.planningsPerDay = [];
            const curDay = new Date(week.startDate);
            while (curDay <= week.endDate) {
                const curDayPlannings = planningList.filter(p => {
                    return Utils.planningAllEntities(p).map(pp => new Date(pp.begindate).getDate()).indexOf(curDay.getDate()) !== -1 && p.planning_hire_people?.length;
                });
                if (curDayPlannings?.length) {
                    this.planningsPerDay.push({
                        day: new Date(curDay),
                        plannings: curDayPlannings
                    });
                }
                curDay.setDate(curDay.getDate() + 1);
            }
        }));
    }

    close() {
        this.dialogRef.close();
    }

}

interface WeekOption {
    startDate: Date;
    endDate: Date;
}

