import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntitiesService} from '../services/entities/entities.service';
import {PlanningService} from '../services/planning/planning.service';
import {AsphaltService} from '../services/asphalt/asphalt.service';
import {Utils} from '../utils.class';
import {Planning} from '../classes/planning.class';
import {AsphaltmillAsphalt} from '../classes/asphaltmillasphalt.class';
import {Asphaltmill} from '../classes/asphaltmill.class';
import {UserService} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {Entity} from '../classes/entity.class';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth/auth.service';
import {Subscription} from 'rxjs';
import {first} from 'rxjs/operators';
import {PlanningAsphalt} from '../classes/planningasphalt.class';

@Component({
    selector: 'app-asphalt-order-list',
    templateUrl: './asphalt-order-list.component.html',
    styleUrls: ['./asphalt-order-list.component.scss']
})
export class AsphaltOrderListComponent implements OnInit, OnDestroy {

    planning: Planning[];
    asphaltList: PlanningAsphalt[];
    asphalt: Map<number, AsphaltmillAsphalt>;
    mills: Asphaltmill[];
    monday: Date;
    now = new Date();
    users: Map<number, User>;
    sets: Map<number, Entity>;
    public authenticatedUser: User;
    Utils = Utils;
    private subscriptions = new Subscription();

    constructor(private entitiesService: EntitiesService,
                private planningService: PlanningService,
                private asphaltService: AsphaltService,
                private userService: UserService,
                private authService: AuthService,
                private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.subscriptions.add(this.activatedRoute.params.pipe(first()).subscribe((params) => {
            if (params['date']) {
                this.asphalt = new Map<number, AsphaltmillAsphalt>();
                this.sets = new Map<number, Entity>();
                this.asphaltService.getAsphaltMills(false).then(mills => {
                    this.mills = mills.filter(m => m.id === +params['millId']);
                    mills.forEach(mill => {
                        mill.asphalt.forEach(asphalt => {
                            this.asphalt.set(asphalt.id, asphalt);
                        });
                    });
                    this.ready();
                });
                this.subscriptions.add(this.authService.getUserDetails().subscribe(user => {
                    this.authenticatedUser = user.data;
                }));

                this.monday = new Date(params['date']);

                // Calculate the monday
                this.monday.setDate(this.monday.getDate() - ((this.monday.getDay() % 7) - 1));
                Utils.setTime(this.monday, 0, 0);
                const sunday = new Date(this.monday);
                Utils.setTime(sunday, 23, 59);
                sunday.setDate(this.monday.getDate() + 6);

                this.planningService.getFilteredList(this.monday, sunday).subscribe(planning => {
                    this.planning = planning;
                    this.asphaltList = [];
                    planning.forEach(pln => {
                        this.asphaltList = this.asphaltList.concat(pln.asphalt_list
                            .filter(a => Utils.getTimeOrNull(a.time) >= this.monday.getTime() && Utils.getTimeOrNull(a.time) <= sunday.getTime())
                            .map(planningAsphalt => {
                                planningAsphalt.planning = pln;
                                return planningAsphalt;
                            }));
                    });
                    this.asphaltList = this.asphaltList.sort((a, b) => {
                        if (a.planning_id == b.planning_id && Utils.getDateOrNull(a.time) === Utils.getDateOrNull(b.time)) {
                            return (new Date(a.time)).getTime() - (new Date(b.time)).getTime();
                        }
                        const compareString = (a: PlanningAsphalt) => '' + Utils.getTimeOrNull(a.planning.planning_asfaltteam.begindate) + a.planning.worknumber + a.planning_id;
                        return compareString(a).localeCompare(compareString(b));
                    });
                    this.ready();
                });

                this.subscriptions.add(this.userService.getMap().subscribe(users => {
                    this.users = users;
                    this.ready();
                }));

                this.entitiesService.getMap().subscribe(sets => {
                    this.sets = sets;
                    this.ready();
                });
            }
        }));
    }

    private ready() {
        if (this.users && this.sets && this.mills && this.asphaltList) {
            setTimeout(() => {
                setTimeout(() => {
                    document.body.appendChild(document.createElement('readyforpuppeteer'));
                });
            }, 2000);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
