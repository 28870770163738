import {Component, OnInit} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Leave} from '../../classes/leave';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Validators} from '@angular/forms';
import {LeaveService} from '../../services/leave.service';
import {SettlementsService} from '../../services/settlements.service';
import {Hourtype} from '../../afas-classes/hourtype';
import {Settings} from '../../settings.class';
import {Utils} from '../../utils.class';
import {UserService} from '../../services/user/user.service';
import {User} from '../../classes/user.class';
import {formatDate} from '@angular/common';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {LeaveExcluded} from '../../classes/leave-excluded';
import {ExcludedKeyPipe} from './excluded-key.pipe';
import {HistoryDialogComponent} from '../../hour-registration/history-dialog/history-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AfasService} from '../../services/afas.service';
import {Rooster} from '../../afas-classes/rooster';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-leave-edit',
    templateUrl: './leave-edit.component.html',
    styleUrls: ['./leave-edit.component.scss']
})
export class LeaveEditComponent extends CodaltComponent implements OnInit {

    lastRooster: string;
    rooster: Rooster[];
    roosterMap = new Map<string, Rooster>();

    minDate: Date;
    maxDate: Date;

    form: FormGroup<ControlsOf<FormGroupLeave>>;

    saving = false;
    saved = false;

    hourtypes: Hourtype[];
    users: User[];
    duration = 0;
    realisations: Realisation[];

    previewRealisations: Realisation[];
    excluded = new Map<string, LeaveExcluded>();

    highlight = null;

    usersSubscription: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private settlementsService: SettlementsService,
                private userService: UserService,
                private dialog: MatDialog,
                private afasService: AfasService,
                private leaveService: LeaveService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.settlementsService.getHourtypes(true).subscribe(hourtypes => {
            this.hourtypes = hourtypes.data;
        }));

        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { id }) => {
            if (params.id === 'new') {
                this.createForm(new Leave());
            } else {
                this.subscriptions.add(this.leaveService.getLeave(params.id).subscribe(leave => {
                    this.createForm(leave.data);
                }));
            }
        }));
    }

    getUsers(zzp = false) {
        this.usersSubscription?.unsubscribe();
        this.users = [];
        this.usersSubscription = this.userService.getList(false, true, zzp).subscribe(users => {
            this.users = users.filter(user => !zzp || user.afas_employee_id?.substring(0, 1) === 'Z').sort((a, b) => {
                const sortString = (z: User) => (z.group ?? 'zzzz') + z.lastname + (z.firstname ?? z.name);
                return sortString(a).localeCompare(sortString(b));
            });
        });
        this.subscriptions.add(this.usersSubscription);
    }

    editHours(date: Date, userId: number) {
        this.router.navigate([
            `/hours/${formatDate(date, 'yyyy-MM-dd', 'nl')}/${userId}`,
            {backTo: window.location.pathname}
        ]);
    }

    createForm(leave: Leave) {

        const beginDate = new Date();
        Utils.setTime(beginDate, Settings.DEFAULT_START, 0, 0);
        const endDate = new Date(beginDate);
        Utils.setTime(endDate, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);

        if (!leave.id || !Utils.isPast(leave.begindate)) {
            this.minDate = new Date(beginDate);
            this.minDate.setDate(this.minDate.getDate() - 100);
        } else {
            this.minDate = new Date(leave.begindate);
        }

        const begintime = new Date();
        if (!leave.begintime) {
            Utils.setTime(begintime, Settings.DEFAULT_START, 0, 0);
        } else {
            const time = leave.begintime.split(':');
            Utils.setTime(begintime, +time[0], +time[1], 0);
        }
        const endtime = new Date();
        if (!leave.endtime) {
            Utils.setTime(endtime, Settings.DEFAULT_START + Settings.DEFAULT_DURATION_SLEEP, 0, 0);
        } else {
            const time = leave.endtime.split(':');
            Utils.setTime(endtime, +time[0], +time[1], 0);
        }

        this.form = new FormGroup<ControlsOf<FormGroupLeave>>({
            id: new FormControl(leave.id),
            hourtype: new FormControl(leave.hourtype, Validators.required),
            comment: new FormControl(leave.comment, Validators.required),
            user_id: new FormControl(leave.user_id),
            users: new FormControl(leave.users?.map(u => u.id)),
            excludedUsers: new FormControl(leave.excluded?.map(ex => ex.user_id)),
            common: new FormControl(leave.common ?? false),
            begindate: new FormControl(leave.begindate ? new Date(leave.begindate) : beginDate, Validators.required),
            enddate: new FormControl(leave.enddate ? new Date(leave.enddate) : endDate, Validators.required),
            begintime: new FormControl(begintime),
            endtime: new FormControl(endtime),
            interval_week: new FormControl(leave.interval_week, Validators.max(52)),
            repeat_monday: new FormControl(leave.repeat_monday),
            repeat_tuesday: new FormControl(leave.repeat_tuesday),
            repeat_wednesday: new FormControl(leave.repeat_wednesday),
            repeat_thursday: new FormControl(leave.repeat_thursday),
            repeat_friday: new FormControl(leave.repeat_friday),
            repeat_saturday: new FormControl(leave.repeat_saturday),
            repeat_sunday: new FormControl(leave.repeat_sunday),
            updated_at: new FormControl(leave.updated_at),
            interval: new FormControl((leave.interval_week > 0))
        });
        if (leave.id) {
            this.form.controls.common.disable();
        }

        this.subscriptions.add(this.form.controls.hourtype.valueChanges.pipe(
                map(hourType => hourType ? hourType === RealisationHourtype.zzp_leave : null),
                distinctUntilChanged()
            ).subscribe((hourtypeIsZzpLeave: boolean | null) => {
                if (hourtypeIsZzpLeave === null) return;

                if (!this.form.value.id) {
                    this.form.controls.users.reset();
                }
                this.getUsers(hourtypeIsZzpLeave);
            })
        );

        this.subscriptions.add(this.form.controls.common.valueChanges.subscribe(common => {
            if (common) {
                this.form.controls.users.disable();
                this.form.controls.users.reset();
            } else {
                this.form.controls.users.enable();
            }
        }));
        if (leave.id) {
            this.subscriptions.add(this.leaveService.getLeaveRealisations(leave.id).subscribe(realistations => {
                this.realisations = realistations.data;
            }));
        }
        this.subscriptions.add(this.form.controls.interval.valueChanges.subscribe(interval => {
            if (this.form.value.interval !== interval) {
                this.form.patchValue({
                    repeat_monday: interval,
                    repeat_tuesday: interval,
                    repeat_wednesday: interval,
                    repeat_thursday: interval,
                    repeat_friday: interval
                });
                if (interval) {
                    this.form.controls.interval_week.setValue(1);
                } else {
                    this.form.controls.interval_week.setValue(0);
                }
            }
        }));
        this.subscriptions.add(this.form.controls.begindate.valueChanges.subscribe(begindate => {
            if (!this.form.controls.interval.value) {
                if (begindate.getHours() !== 0) {
                    const beginDate = new Date(begindate);
                    Utils.setTime(beginDate, 0, 0, 0);
                    this.form.controls.begindate.setValue(beginDate);
                }
            }
            if ((this.form.value.enddate.getTime() < begindate.getTime()) || !this.form.controls.interval.value) {
                const endDate = new Date(begindate);
                Utils.setTime(endDate, 23, 59, 59);
                this.form.controls.enddate.setValue(endDate);
            }

            this.maxDate = new Date(begindate);
            if (this.form.getRawValue().common) {
                this.maxDate.setDate(this.maxDate.getDate() + 28);
            } else {
                this.maxDate.setFullYear(this.maxDate.getFullYear() + 3);
            }
        }));
        this.subscriptions.add(this.form.valueChanges.pipe(debounceTime(500), startWith()).subscribe(() => {
            this.duration = Utils.minuteDuration(this.form.value.endtime, this.form.value.begintime);
            this.generateRealisations();
        }));
        this.subscriptions.add(this.form.controls.excludedUsers.valueChanges.subscribe(excludedUsers => {
            excludedUsers?.forEach(excludedUser => {
                if (!this.form.value.excludedUsers.find(e => excludedUser === e)) {
                    this.previewRealisations.forEach(previewRealisation => {
                        this.excluded.set((new ExcludedKeyPipe()).transform(excludedUser, previewRealisation.begindate), {
                            date: previewRealisation.begindate,
                            user_id: excludedUser
                        });
                    });
                }
            });
        }));
        leave.excluded?.forEach(exclude => {
            this.excluded.set((new ExcludedKeyPipe()).transform(exclude.user_id, exclude.date), exclude);
        });
    }

    save() {
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const leave = new Leave();
            Object.assign(leave, this.form.value);
            leave.excluded = Array.from(this.excluded.values());
            leave.begintime = formatDate(leave.begintime, 'HH:mm', 'nl');
            leave.endtime = formatDate(leave.endtime, 'HH:mm', 'nl');
            this.subscriptions.add(this.leaveService.saveLeave(leave).subscribe(() => {
                this.router.navigateByUrl('/leave');
            }));
        }
    }

    realisationHistory(realisation: Realisation) {
        this.dialog.open(HistoryDialogComponent, {
            maxWidth: '99vw',
            width: '1600px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisation
            }
        });
    }


    setExclude(user_id: number, date: Date, exclude = null): boolean {

        if (this.roosterMap.size && !this.roosterMap.has((new ExcludedKeyPipe()).transform(this.users.find(u => u.id === user_id)?.afas_employee_id, date))) {
            return;
        }
        this.form.markAsDirty();
        const key = (new ExcludedKeyPipe()).transform(user_id, date);
        if (exclude === null) {
            exclude = !this.excluded.has(key);
        }

        if (exclude) {
            this.excluded.set(key, {date, user_id});
            return true;
        } else {
            this.excluded.delete(key);
            return false;
        }
    }

    excludeDate(date: Date) {
        let state = null;
        this.users.forEach(user => {
            const result = this.setExclude(user.id, date, state);
            if (state === null) {
                state = result;
            }
        });
    }

    excludeUser(userId) {
        let state = null;
        this.previewRealisations.forEach(r => {
            const result = this.setExclude(userId, r.begindate, state);
            if (state === null) {
                state = result;
            }
        });
    }

    mouseOverDate(date: Date) {
        this.highlight = formatDate(date, 'yyyy-MM-dd', 'nl');
    }

    mouseOverUser(userId: number) {
        this.highlight = userId;
    }

    generateRealisations() {
        const realisations = [];
        const loopDate = new Date(this.form.value.begindate);
        const lastEndDate = new Date(this.form.value.enddate);
        const firstWeek = +formatDate(loopDate, 'w', 'nl');
        const startTime = new Date(this.form.value.begindate);
        Utils.setTime(startTime, this.form.value.begintime.getHours(), this.form.value.begintime.getMinutes());
        const endTime = new Date(this.form.value.enddate);
        Utils.setTime(endTime, this.form.value.endtime.getHours(), this.form.value.endtime.getMinutes());

        const loopEndDate = new Date(this.form.value.enddate);
        Utils.setTime(loopEndDate, 23, 59, 59);

        const roosterMapKey = () => {
            return `${(new Date(this.form.value.begindate)).toISOString()}|${loopEndDate.toISOString()}`;
        };
        if (this.lastRooster !== roosterMapKey()) {
            this.subscriptions.add(this.afasService.rooster(loopDate, loopEndDate).subscribe(rooster => {
                this.lastRooster = roosterMapKey();
                this.rooster = rooster.data;
                this.rooster.forEach(r => {
                    this.roosterMap.set((new ExcludedKeyPipe()).transform(r.employeeId, r.date), r);
                });
            }));
        }


        while (loopDate.getTime() < loopEndDate.getTime()) {

            let generateThisDay = !this.form.value.interval;
            if (this.form.value.interval_week > 0) {
                const week = +formatDate(loopDate, 'w', 'nl');
                const thisWeek = ((week - firstWeek) % this.form.value.interval_week) === 0;
                const weekDayName = formatDate(loopDate, 'EEEE', 'en').toLowerCase();
                const thisDay = this.form.value[`repeat_${weekDayName}`];
                generateThisDay = thisWeek && thisDay;
            }

            if (generateThisDay) {
                const beginDate = new Date(loopDate);
                if (beginDate.getTime() === Utils.getTimeOrNull(this.form.value.begindate) && beginDate.getTime() > startTime.getTime()) {
                    Utils.setTime(beginDate, this.form.value.begindate.getHours(), this.form.value.begindate.getMinutes(), 0);
                } else {
                    Utils.setTime(beginDate, startTime.getHours(), startTime.getMinutes(), 0);
                }

                const endDate = new Date(loopDate);
                Utils.setTime(endDate, this.form.value.enddate.getHours(), this.form.value.enddate.getMinutes(), 0);

                if (endDate.getTime() !== lastEndDate.getTime() || lastEndDate.getTime() > endTime.getTime()) {
                    Utils.setTime(endDate, endTime.getHours(), endTime.getMinutes(), 0);
                }

                const realisation = new Realisation();
                realisation.hourtype = RealisationHourtype.day_off;
                realisation.begindate = beginDate;
                realisation.enddate = endDate;
                realisations.push(realisation);
            }
            loopDate.setDate(loopDate.getDate() + 1);
        }
        this.previewRealisations = realisations;
    }

    readonly RealisationHourtype = RealisationHourtype;
}

export interface FormGroupLeave extends Omit<Leave, 'begindate' | 'enddate' | 'updated_at' | 'users' | 'excluded_users' | 'begintime' | 'endtime' | 'excluded'> {
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    begintime: FormControl<Date>;
    endtime: FormControl<Date>;
    updated_at: FormControl<Date>;
    interval: boolean;
    users: FormControl<number[]>;
    excludedUsers: FormControl<number[]>;
}
