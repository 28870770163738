import {Component, HostListener, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Planning, PlanningSize} from '../../classes/planning.class';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PlanningAsfaltteam} from '../../classes/planningasfaltteam.class';
import {PlanningCutter} from '../../classes/planningcutter.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {PlanningService} from '../../services/planning/planning.service';
import {StatusService} from '../../services/planning/status.service';
import {Status} from '../../classes/status.class';
import {EntitiesService} from '../../services/entities/entities.service';
import {PlanningSet} from '../../classes/planningset.class';
import {PlanningDetailViewModel} from './planning-detail-view-model';
import {CutterListComponent} from './cutter-list/cutter-list.component';
import {WipetruckListComponent} from './wipetruck-list/wipetruck-list.component';
import {entityAvailableValidator} from '../../validators/entity-available.validator';
import {combineLatest, of, Subject, Subscription} from 'rxjs';
import {Utils} from '../../utils.class';
import {EntityWithAvailable} from '../../classes/entity-with-available.class';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {DateAdapter} from '@angular/material/core';
import {DateFullMonthAdapter} from '../../date-adapters/date-full-month-adapter';
import {User} from '../../classes/user.class';
import {UserService, UserType} from '../../services/user/user.service';
import {AsphaltListComponent} from './asphalt-list/asphalt-list.component';
import {LocalStorage} from '../../storage.class';
import {EntityUnavailableService} from '../../services/entities/entity-unavailable.service';
import {PlanningFixedService} from '../../services/planning/planning-fixed.service';
import {Settings} from '../../settings.class';
import {EntityUnavailable} from '../../classes/entityunavailable.class';
import {Entity} from '../../classes/entity.class';
import {DayTimeOptions} from '../../classes/day-time-options';
import {disabledWhenFinal, RequiredRule} from './planning-detail-dialog.required-rules';
import {PlanningStatus} from '../../planning-status.enum';
import {userIsNotDeleted} from '../../validators/user-is-not-deleted.validator';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {AutocompleteService, AutocompleteType} from '../../services/planning/autocomplete.service';
import {debounceTime} from 'rxjs/operators';
import {PlanningDumper} from '../../classes/planningdumper.class';
import {PlanningDetailDialogService} from '../../services/dialog/planning-detail-dialog.service';
import {HirePeopleSelectorDialogComponent} from './hire-people-selector-dialog/hire-people-selector-dialog.component';
import {PlanningHirePeople} from 'src/app/classes/planninghirepeople.class';
import {EmployeesDialogComponent} from '../../transport-planning/employees-dialog/employees-dialog.component';
import {LocationService} from '../../services/location.service';
import {NgSelectComponent} from '@ng-select/ng-select';
import {CodaltComponent} from '../../codalt.component';
import {PlanningDetailContainerComponent} from '../planning-detail-container/planning-detail-container.component';
import {ProjectService} from '../../services/project.service';
import {Project} from '../../classes/project.class';
import {PlanningHasService} from '../../services/planning/planning-has.service';
import {localUserAvailableValidator} from '../../validators/local-user-available.validator';
import {UserPlanningService} from '../../services/planning/user-planning.service';
import {Hiring} from '../../classes/hiring';
import {HiringService} from '../../services/hiring.service';
import {Leave} from '../../classes/leave';
import {RealisationHourtype} from '../../classes/realisation';
import {formatDate} from '@angular/common';
import {LeaveService} from '../../services/leave.service';

@Component({
    selector: 'app-planning-detail',
    templateUrl: './planning-detail-dialog.component.html',
    styleUrls: ['./planning-detail-dialog.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateFullMonthAdapter
    }]
})
export class PlanningDetailDialogComponent extends CodaltComponent implements OnInit, OnDestroy {

    PlanningStatus = PlanningStatus;

    viewModel = new PlanningDetailViewModel();
    saving = false;
    form: FormGroup;
    mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity;
    statusList: Status[];
    setList: EntityWithAvailable[];
    craneList: EntityWithAvailable[];
    asphaltOtherList: EntityWithAvailable[];
    stickyWagonList: EntityWithAvailable[];
    rollerList: EntityWithAvailable[];
    dumperList: EntityWithAvailable[];
    asfaltTeamList: EntityWithAvailable[];
    preparationTeamList: EntityWithAvailable[];
    projectteamList: EntityWithAvailable[];
    cutterList: EntityWithAvailable[];
    wipetruckList: EntityWithAvailable[];
    truckList: EntityWithAvailable[];
    hirePeopleList: Entity[];
    startTimes: DayTimeOptions[];
    endTimes: DayTimeOptions[];
    dialogTitle = 'Planning Registreren';
    executors: User[];
    asphaltExecutors: User[];
    projectManagers: User[];
    drivers: User[];
    users: User[] = [];
    usersMap: Map<number, User>;
    maxUsedTrucks = 0;
    availableTrucks = 0;
    fixedDate: Date;
    statusControl: FormControl;
    autocompleteContractors: string[];
    autocompletePouringOff: string[];
    setListString = '';
    dumperListString = '';
    craneListString = '';
    rollerListString = '';
    stickyWagonString = '';
    otherString = '';
    initialStatus: number;

    externalChanged = false;
    @Input() planning: Planning;
    entitiesMap: Map<number, Entity>;
    planningList: Planning[];

    projects: Project[];

    addresses: {
        addressLine,
        adminDistrict,
        adminDistrict2,
        countryRegion,
        formattedAddress,
        locality,
        postalCode
    }[];

    searchAfasProjects$ = new Subject<string>();
    searchLocations$ = new Subject<string>();

    origPlanning: Planning;

    protected unavailableList: EntityUnavailable[];
    @ViewChild(CutterListComponent)
    private cutterListComponent: CutterListComponent;
    @ViewChild(WipetruckListComponent)
    private wipetruckListComponent: WipetruckListComponent;
    @ViewChild(AsphaltListComponent)
    private asphaltListComponent: AsphaltListComponent;
    private truckSubscription = new Subscription();
    private entitySubscription = new Subscription();
    private avCheckSubscriptions: Subscription;
    private startms = 0;

    private isCopy = false;

    mayBackToConcept = false;

    @ViewChild('projectSelect') projectSelect: NgSelectComponent;
    @ViewChild('locationSelect') locationSelect: NgSelectComponent;

    @Input() dialogRef: MatDialogRef<PlanningDetailContainerComponent>;

    hiringList: Hiring[];

    leave: Leave;

    constructor(public dialog: MatDialog,
                private planningService: PlanningService,
                private planningFixedService: PlanningFixedService,
                private statusService: StatusService,
                private ngZone: NgZone,
                private confirmDialogService: ConfirmDialogService,
                private entitiesService: EntitiesService,
                private entityUnavailableService: EntityUnavailableService,
                private planningHasService: PlanningHasService,
                private autocompleteService: AutocompleteService,
                private userService: UserService,
                private projectService: ProjectService,
                private locationService: LocationService,
                private userPlanningService: UserPlanningService,
                private hiringService: HiringService,
                private leaveService: LeaveService,
                private planningDetailDialogService: PlanningDetailDialogService) {
        super();
        this.startms = (new Date()).getTime();
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
        if (this.dialog.openDialogs?.length === 1) {
            this.close();
        }
    }

    print() {
        window.open('/planning-detail/' + this.planning.id);
    }

    copy() {
        if (this.form.dirty
            || (this.asphaltListComponent && this.asphaltListComponent.checkDirty())
            || (this.cutterListComponent && this.cutterListComponent.checkDirty())
            || (this.wipetruckListComponent && this.wipetruckListComponent.checkDirty())) {
            this.confirmDialogService.confirm(
                'Sla eerst je wijzigingen op',
                'Voordat je een kopie kunt maken moet je eerst de wijzigingen die je hebt gemaakt opslaan.',
                'Oke', null);
        } else {
            const copyPlanning = JSON.parse(JSON.stringify(this.planning)) as Planning;
            delete copyPlanning.id;
            const planningItems = Utils.planningAllEntities(copyPlanning);
            planningItems.forEach(plnItem => delete plnItem.id);
            copyPlanning.asphalt_list.forEach(a => delete a.id);
            this.planningDetailDialogService.openPlanning(copyPlanning);
            this.dialogRef.close();
        }
    }

    openEmployeesPlanning() {
        this.dialog.open(EmployeesDialogComponent, {
            width: '99vw',
            maxWidth: '1300px',
            minHeight: '500px',
            maxHeight: '95vh',
            disableClose: true,
            data: {
                planning: this.planning
            }
        });
    }

    hirePeopleSelector() {
        const ref = this.dialog.open(HirePeopleSelectorDialogComponent, {
            maxWidth: '500px',
            width: '300px',
            maxHeight: '100%',
            disableClose: true,
            panelClass: 'planning-detail-dialog',
            data: {
                planning: this.planning,
                entities: this.hirePeopleList,
                disabled: this.viewModel.formDisabled
            }
        });
        ref.afterClosed().subscribe(hirePeoplePlannings => {
            if (!this.viewModel.formDisabled) {
                if (typeof hirePeoplePlannings === 'object') {
                    this.planning.planning_hire_people = [];
                    hirePeoplePlannings?.forEach(hire => {
                        const planningHire = new PlanningHirePeople();
                        planningHire.entitytype_id = EntityTypeCode.HirePeople;
                        Object.assign(planningHire, hire);
                        this.overrideDates(planningHire);
                        this.planning.planning_hire_people.push(planningHire);
                    });
                }
            }
        });
    }

    afasProjectClose() {
        if (this.projects.find(p => p.afas_project_id === this.form.get('worknumber').value)) {
            this.form.get('afas_project_id').setValue(this.form.get('worknumber').value);
            this.planning.afas_project_id = this.form.get('worknumber').value;
        }
    }

    afasProjectChange(projectId: string) {
        const project = this.projects.find(p => p.afas_project_id === projectId);
        if (!this.form.get('projectmanager').value) {
            this.form.get('projectmanager').setValue(project.projectmanager_id);
        }

        if (project.location_place && !this.form.get('location').value) {
            this.form.get('location').setValue(`${project.location_straat} ${project.location_housenumber}, ${project.location_postcode} ${project.location_place}`);
            this.form.get('asphalt_location')?.setValue(`${project.location_straat}, ${project.location_place}`);
        }
        this.form.get('afas_project_id').setValue(project.afas_project_id);
        this.planning.afas_project_id = project.afas_project_id;
    }

    ngOnInit() {
        if (!this.planning.id && this.planning.entity_id) {
            this.isCopy = true;
        }

        this.origPlanning = JSON.parse(JSON.stringify(this.planning));
        this.mainPlanning = this.planningService.getMainPlanning(this.planning);

        this.subscriptions.add(this.searchAfasProjects$.pipe(debounceTime(200)).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));

        if (this.planning?.leave_id) {
            this.subscriptions.add(this.leaveService.getLeave(this.planning.leave_id).subscribe(leave => {
                this.leave = leave.data;
                if (!this.leave) {
                    this.leave = this.createLeave();
                }
            }));
        } else if (this.planning.status_id == PlanningStatus.slaapdag) {
            this.leave = this.createLeave();
        }

        this.subscriptions.add(this.searchLocations$.pipe(debounceTime(200)).subscribe(search => {
            const addresses = [];
            this.subscriptions.add(this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                result.resourceSets.forEach(res => {
                    res.resources.forEach(address => {
                        addresses.push(address.address);
                    });
                });
                this.addresses = addresses;
            }));
        }));

        if (this.planning.id) {
            this.subscriptions.add(this.planningService.getSingle(this.planning.id).subscribe((changed) => {
                if (!changed || this.planning.updated_at !== changed.updated_at) {
                    this.externalChanged = true;
                    this.viewModel.asphaltFormDisabled = true;
                    this.disableForms();
                }
                if (changed) {
                    this.planning.user_planning = changed.user_planning;
                }
            }));
        }

        this.initialStatus = this.planning.status_id;

        const user = LocalStorage.getUser();

        if (user.groups.find(g => (g.group as UserType) === UserType.PROJECTMANAGER) && !this.planning.projectmanager) {
            this.planning.projectmanager = user.id;
        }

        this.statusService.getList().then(statusList => {
            this.statusList = statusList;
        });
        if (UserService.userHasRights(UserType.WORKPLANNER)) {
            this.userService.getByType(UserType.TRUCK_DRIVER).subscribe(drivers => {
                this.drivers = drivers;
            });
        }
        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
        this.subscriptions.add(this.userService.getByType(UserType.ASPHALTEXECUTOR).subscribe(users => this.asphaltExecutors = users));
        this.subscriptions.add(this.userService.getByType(UserType.PROJECTMANAGER).subscribe(users => this.projectManagers = users));
        this.subscriptions.add(this.userService.getList().subscribe(users => this.users = users));
        this.subscriptions.add(this.userService.getMap().subscribe(users => {
            this.usersMap = users;
            if (this.form) {
                this.form.get('projectmanager').updateValueAndValidity();
                this.form.get('performer').updateValueAndValidity();
            }
        }));


        if (this.mainPlanning.begindate) {
            this.mainPlanning.begindate = this.mainPlanning.begindate ? new Date(this.mainPlanning.begindate) : null;
            this.mainPlanning.enddate = this.mainPlanning.enddate ? new Date(this.mainPlanning.enddate) : null;
            this.genEndTimesList();
        }
        let entitiesMapInit = false;
        this.dialogTitle = 'Planning registreren';

        const initForm = () => {
            this.viewModel.updateViewState(this.mainPlanning.entitytype_id, user, this.planning);
            this.subscriptions.add(this.planningFixedService.getLatestItem().subscribe(fixed => {
                const isFixed = this.mainPlanning.begindate !== null && new Date(fixed.fixeduntil).getTime() >= new Date(this.mainPlanning.begindate).getTime();
                this.viewModel.updateViewState(this.mainPlanning.entitytype_id, user, this.planning, isFixed);
                this.fixedDate = isFixed ? new Date(fixed.fixeduntil) : null;
                this.mayBackToConcept = !isFixed || UserService.userHasRights(UserType.WORKPLANNER);
                if (this.form) {
                    isFixed ? this.disableForms() : this.enableForms();
                }
            }));
            if (this.viewModel.cutter) {
                this.subscriptions.add(this.hiringService.getByType(EntityTypeCode.Cutter).subscribe(hiring => {
                    this.hiringList = hiring;
                }));
            }
            this.createMainForm(this.planning, this.mainPlanning);
            this.subscriptions.add(this.entitiesService.getList(this.mainPlanning.begindate, this.mainPlanning.enddate).subscribe(entities => {
                this.setList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Set)) as EntityWithAvailable[];
                this.dumperList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Dumper)) as EntityWithAvailable[];
                this.asfaltTeamList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.AsfaltTeam)) as EntityWithAvailable[];
                this.preparationTeamList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.PreparationTeam)) as EntityWithAvailable[];
                this.projectteamList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.ProjectTeam)) as EntityWithAvailable[];
                this.cutterList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Cutter)) as EntityWithAvailable[];
                this.wipetruckList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Wipetruck)) as EntityWithAvailable[];
                this.truckList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Truck)) as EntityWithAvailable[];
                this.hirePeopleList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.HirePeople));

                this.rollerList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Roller)) as EntityWithAvailable[];
                this.craneList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.Crane)) as EntityWithAvailable[];
                this.stickyWagonList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.StickyWagon)) as EntityWithAvailable[];
                this.asphaltOtherList = entities.filter(entity => !!entity.entitytypes.find(et => et.id === EntityTypeCode.AsphaltOther)) as EntityWithAvailable[];


                if (!this.planning.id && !this.isCopy) {
                    this.form.get('rollers')?.setValue([...this.rollerList.filter(c => c.team_entity_id === this.mainPlanning.entity_id).map(c => c.id)]);
                    this.form.get('dumpers')?.setValue([...this.dumperList.filter(c => c.team_entity_id === this.mainPlanning.entity_id).map(c => c.id)]);
                    this.form.get('asphalt_others')?.setValue([...this.asphaltOtherList.filter(c => c.team_entity_id === this.mainPlanning.entity_id).map(c => c.id)]);
                }

                this.updateEntityAvailability();
            }));
            this.trucks();
            this.updateStatus();
        };

        this.subscriptions.add(this.entitiesService.getMap().subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
            this.dialogTitle = (this.entitiesMap?.get(this.mainPlanning.entity_id)?.entitytypes[0]?.name ?? 'Planning') + ' registreren';
            if (!entitiesMapInit) {
                initForm();
            }
            entitiesMapInit = true;
        }));

    }

    setTimesSubscription() {
        if (this.avCheckSubscriptions) {
            this.avCheckSubscriptions.unsubscribe();
        }
        if (this.mainPlanning.begindate) {
            const fromDate = new Date(this.mainPlanning.begindate);
            fromDate.setHours(0);
            const toDate = new Date(fromDate);
            toDate.setUTCHours(toDate.getUTCHours() + 24);
            const entitiesMap$ = this.entitiesService.getMap();
            const fromDateUnavailable = new Date(fromDate);
            fromDateUnavailable.setDate(fromDateUnavailable.getDate() - 14);
            const planning$ = this.planningService.getFilteredList(fromDateUnavailable, toDate);
            const unavailable$ = this.entityUnavailableService.getFilteredList(fromDateUnavailable, toDate);
            this.avCheckSubscriptions = new Subscription();
            this.avCheckSubscriptions.add(combineLatest(entitiesMap$, unavailable$, planning$)
                .subscribe(([entitiesMap, unavailableList, planningList]) => {
                    this.unavailableList = unavailableList;
                    this.planningList = planningList;
                    this.entitiesMap = entitiesMap;
                    this.startTimes = Utils.genTimesMapOneEntity(fromDate, toDate, this.mainPlanning as PlanningHasEntity, this.mainPlanning.planning_id, this.planningList, this.unavailableList);
                    if (this.mainPlanning.begindate) {
                        this.genEndTimesList();
                    }
                })
            );
        }
    }

    delete() {
        this.confirmDialogService.confirm(
            'Planning verwijderen',
            'Weet u zeker dat u deze planning wilt verwijderen?',
            'Verwijderen',
            'Annuleren').then(() => {
            this.planningService.deletePlanning(this.planning.id).then(() => {
                this.dialogRef.close();
            });
        }, () => {

        });
    }

    enableForms() {
        this.form.enable();
        if (this.cutterListComponent) {
            this.cutterListComponent.enableForms();
        }
        if (this.wipetruckListComponent) {
            this.wipetruckListComponent.enableForms();
        }
        if (this.asphaltListComponent) {
            this.asphaltListComponent.enableForms();
        }
    }

    disableForms() {
        this.form.disable({emitEvent: false});
        if (this.cutterListComponent) {
            this.cutterListComponent.disableForms();
        }
        if (this.wipetruckListComponent) {
            this.wipetruckListComponent.disableForms();
        }
        if (this.asphaltListComponent && this.viewModel.asphaltFormDisabled) {
            this.asphaltListComponent.disableForms();
        }
    }


    trucks() {
        if (!this.planning) {
            return;
        }

        if (this.truckSubscription) {
            this.truckSubscription.unsubscribe();
        }
        if (this.entitySubscription) {
            this.entitySubscription.unsubscribe();
        }

        if (this.mainPlanning.begindate) {
            this.truckSubscription = this.planningService
                .getMaxUsedTrucksForRange(this.mainPlanning.begindate, this.mainPlanning.enddate, this.mainPlanning.planning_id)
                .subscribe(maxUsedTrucks => {
                    this.maxUsedTrucks = maxUsedTrucks;
                });
            this.entitySubscription = this.entityUnavailableService
                .getTrucksAvailableForRange(this.mainPlanning.begindate, this.mainPlanning.enddate)
                .subscribe(availableTrucks => {
                    this.availableTrucks = availableTrucks;
                });
        } else {
            this.availableTrucks = this.maxUsedTrucks = 0;
        }
    }

    save() {

        if (this.planning.status_id === PlanningStatus.geblokkeerd) {
            this.planning.planning_has = this.planning.planning_has.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_sets = this.planning.planning_sets.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_trucks = this.planning.planning_trucks.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_dumpers = this.planning.planning_dumpers.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_cranes = this.planning.planning_cranes.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);this.planning.planning_has = this.planning.planning_has.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_sticky_wagons = this.planning.planning_sticky_wagons.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_asphalt_others = this.planning.planning_asphalt_others.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_rollers = this.planning.planning_rollers.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_cutters = this.planning.planning_cutters.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
            this.planning.planning_wipetrucks = this.planning.planning_wipetrucks.filter(ph => ph.entitytype_id === this.mainPlanning.entitytype_id);
        }

        this.planning.planning_hire_people?.forEach(hire => {
            hire.begindate = new Date(this.mainPlanning.begindate);
            hire.enddate = new Date(this.mainPlanning.enddate);
        });
        this.saving = true;

        const allPromises = [
            this.cutterListComponent?.validateForms() ?? of(true),
            this.wipetruckListComponent?.validateForms() ?? of(true),
            this.asphaltListComponent?.validateForms() ?? of(true),
            Utils.triggerValidationP(this.form)
        ];

        Promise.all(allPromises).then(() => {
            if (this.cutterListComponent) {
                this.planning.planning_cutters = this.cutterListComponent.planningEntitiesList;
            }
            if (this.wipetruckListComponent) {
                this.planning.planning_wipetrucks = this.wipetruckListComponent.planningEntitiesList;
            }
            if (this.asphaltListComponent) {
                this.planning.asphalt_list = this.asphaltListComponent.planningAsphaltList;
            }
            this.saveAction();
        }, (results) => {
            // form disabled and invalid but asphalt enabled, validate asphalt again
            if (!this.viewModel.asphaltFormDisabled && this.viewModel.formDisabled && this.asphaltListComponent) {
                this.asphaltListComponent.validateForms().then(() => {
                    console.log('Saving just asphalt...');
                    this.saveAction();
                }, () => {
                    this.saving = false;
                    console.log('Validation errors asphalt');
                });
            } else {
                for (const name of Object.keys(this.form.controls)) {
                    this.form.controls[name].markAsTouched();
                    if (this.form.controls[name] instanceof FormGroup) {
                        const formGroup = this.form.controls[name] as FormGroup;
                        for (const subname of Object.keys(formGroup.controls)) {
                            formGroup.controls[subname].markAsTouched();
                        }
                    }
                }
                this.saving = false;
                console.log('Validation errors', results, this.form);
            }
        });
    }

    close() {
        if ((this.form.dirty
                || (this.asphaltListComponent && this.asphaltListComponent.checkDirty())
                || (this.cutterListComponent && this.cutterListComponent.checkDirty())
                || (this.wipetruckListComponent && this.wipetruckListComponent.checkDirty()))
            && (!this.viewModel.formDisabled || !this.viewModel.asphaltFormDisabled)
        ) {
            if (this.externalChanged) {
                this.confirmDialogService.confirm(
                    'Wijzigingen gaan verloren',
                    'Je hebt wijzigingen aangebracht, maar in de tussentijd heeft iemand anders deze planning ook aangepast. ' +
                    'Helaas kunnen we jouw wijzigingen niet opslaan.',
                    'Oke',
                    null).then(() => {
                    this.dialogRef.close();
                }, () => {
                    this.dialogRef.close();
                });
            } else {
                this.confirmDialogService.confirm(
                    'Wijzigingen opslaan',
                    'Je hebt wijzigingen aangebracht. Wil je deze opslaan?',
                    'Opslaan',
                    'Niet opslaan').then(() => {
                    this.save();
                }, () => {
                    this.dialogRef.close();
                });
            }
        } else {
            this.dialogRef.close();
        }
    }

    ngOnDestroy() {
        if (this.truckSubscription) {
            this.truckSubscription.unsubscribe();
        }
        if (this.entitySubscription) {
            this.entitySubscription.unsubscribe();
        }
        this.subscriptions.unsubscribe();
    }

    overrideDates(item: PlanningCutter | PlanningAsfaltteam | PlanningHasEntity | PlanningSet | PlanningHirePeople) {
        if (!disabledWhenFinal(this)) {
            item.begindate = this.mainPlanning.begindate;
            item.enddate = this.mainPlanning.enddate;
        }
    }

    formChanges() {
        const formValues = this.form.getRawValue();
        for (const [key, value] of Object.entries(formValues)) {
            if (['set', 'sets', 'dumpers', 'rollers', 'asfaltteam', 'preparationteam', 'projectteam', 'cutter', 'wipetruck', 'date', 'asphalt_others'].indexOf(key) !== -1) {
                if (key === 'date') {
                    if (this.form.get('date').touched) {
                        if (this.viewModel.asfaltteam) {
                            this.form.get('asfaltteam').markAsTouched();
                        }
                        if (this.viewModel.projectteam) {
                            this.form.get('projectteam').markAsTouched();
                        }
                        if (this.viewModel.preparationteam) {
                            this.form.get('preparationteam').markAsTouched();
                        }
                        if (this.viewModel.set) {
                            this.form.get('sets').markAsTouched();
                        }
                        if (this.viewModel.dumpers) {
                            this.form.get('dumpers').markAsTouched();
                        }
                        if (this.viewModel.cutter) {
                            this.form.get('cutter').markAsTouched();
                        }
                        if (this.viewModel.rollers) {
                            this.form.get('rollers').markAsTouched();
                        }
                        this.updateEntityAvailability();
                        this.form.get('date').markAsUntouched();
                    }
                } else if (key === 'asfaltteam' && this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    this.mainPlanning.entity_id = value as number;
                    this.planning.entity_id = value as number;
                    this.planning.entity = this.entitiesMap.get(this.planning.entity_id);
                    this.planning.planning_asfaltteam.entity_id = value as number;
                    this.overrideDates(this.planning.planning_asfaltteam);
                    this.viewModel.updateViewState(this.mainPlanning.entitytype_id, LocalStorage.getUser(), this.planning, !!this.fixedDate);
                } else if (key === 'projectteam' && this.mainPlanning.entitytype_id === EntityTypeCode.ProjectTeam) {
                    this.mainPlanning.entity = this.entitiesMap.get(value as number);
                    if (!this.form.get('performer').value && this.mainPlanning?.entity?.default_performer_id) {
                        this.form.get('performer').setValue(this.mainPlanning.entity.default_performer_id);
                    }
                    if (value !== this.mainPlanning.entity_id) {
                        this.genEndTimesList();
                    }
                    this.mainPlanning.entity_id = value as number;
                    this.planning.entity_id = value as number;
                    this.planning.entity = this.entitiesMap.get(this.planning.entity_id);
                    this.planning.planning_projectteam.entity_id = value as number;
                    this.overrideDates(this.planning.planning_projectteam);
                    this.viewModel.updateViewState(this.mainPlanning.entitytype_id, LocalStorage.getUser(), this.planning, !!this.fixedDate);
                } else if (key === 'preparationteam' && this.mainPlanning.entitytype_id === EntityTypeCode.PreparationTeam) {
                    this.mainPlanning.entity_id = value as number;
                    this.planning.entity_id = value as number;
                    this.planning.entity = this.entitiesMap.get(this.planning.entity_id);
                    this.planning.planning_preparationteam.entity_id = value as number;
                    this.overrideDates(this.planning.planning_preparationteam);
                    this.viewModel.updateViewState(this.mainPlanning.entitytype_id, LocalStorage.getUser(), this.planning, !!this.fixedDate);
                } else if (key === 'sets' && this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    if (disabledWhenFinal(this)) {
                        delete this.planning.planning_sets;
                    } else {
                        this.planning.planning_sets = [];
                        (value as number[]).forEach(set => {
                            const planningSet = new PlanningSet();
                            planningSet.entity_id = set;
                            planningSet.entitytype_id = EntityTypeCode.Set;
                            planningSet.planning_id = this.planning.id;
                            this.overrideDates(planningSet);
                            this.planning.planning_sets.push(planningSet);
                        });
                    }
                } else if (key === 'dumpers' && this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    if (disabledWhenFinal(this)) {
                        delete this.planning.planning_dumpers;
                    } else {
                        this.planning.planning_dumpers = [];
                        (value as number[]).forEach(dumper => {
                            const planningDumper = new PlanningDumper();
                            planningDumper.entity_id = dumper;
                            planningDumper.entitytype_id = EntityTypeCode.Dumper;
                            planningDumper.planning_id = this.planning.id;
                            this.overrideDates(planningDumper);
                            this.planning.planning_dumpers.push(planningDumper);
                        });
                    }
                } else if (key === 'rollers' && this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    if (disabledWhenFinal(this)) {
                        delete this.planning.planning_rollers;
                    } else {
                        this.planning.planning_rollers = [];
                        (value as number[]).forEach(dumper => {
                            const planningRoller = new PlanningHasEntity();
                            planningRoller.entity_id = dumper;
                            planningRoller.entitytype_id = EntityTypeCode.Roller;
                            planningRoller.planning_id = this.planning.id;
                            this.overrideDates(planningRoller);
                            this.planning.planning_rollers.push(planningRoller);
                        });
                    }
                } else if (key === 'asphalt_others' && this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    if (disabledWhenFinal(this)) {
                        delete this.planning.planning_asphalt_others;
                    } else {
                        this.planning.planning_asphalt_others = [];
                        (value as number[]).forEach(asphaltOther => {
                            const planningAsphaltOther = new PlanningHasEntity();
                            planningAsphaltOther.entity_id = asphaltOther;
                            planningAsphaltOther.entitytype_id = EntityTypeCode.AsphaltOther;
                            planningAsphaltOther.planning_id = this.planning.id;
                            this.overrideDates(planningAsphaltOther);
                            this.planning.planning_asphalt_others.push(planningAsphaltOther);
                        });
                    }
                } else if (key === 'cutter' && this.mainPlanning.entitytype_id === EntityTypeCode.Cutter) {
                    const mainPlanning = this.mainPlanning as PlanningCutter;
                    mainPlanning.entity_id = value['cutter'];
                    mainPlanning.driver_user_id = value['driver_user_id'];
                    mainPlanning.tons = value['tons'];
                    mainPlanning.thickness = value['thickness'];
                    mainPlanning.trucks = value['trucks'];
                    mainPlanning.location = value['location'];
                    if (!this.entitiesMap.get(value['cutter'])?.use_once) {
                        mainPlanning.hiring_id = value['hiring_id'];
                        mainPlanning.name = value['name'];
                    }
                    this.planning.entity_id = value['cutter'];
                    this.planning.planning_cutters[0].entity_id = value['cutter'];
                    this.planning.planning_cutters[0].driver_user_id = mainPlanning.driver_user_id;
                    this.overrideDates(this.planning.planning_cutters[0]);
                } else if (key === 'wipetruck' && this.mainPlanning.entitytype_id === EntityTypeCode.Wipetruck) {
                    const mainPlanning = this.mainPlanning as PlanningCutter;
                    mainPlanning.entity_id = value['wipetruck'];
                    mainPlanning.driver_user_id = value['driver_user_id'];
                    mainPlanning.type = value['type'];
                    this.planning.entity_id = mainPlanning.entity_id;
                    this.overrideDates(this.planning.planning_wipetrucks[0]);
                }
            } else {
                this.planning[key] = value;
            }
        }
        this.trucks();

        const makeString = (ewa: EntityWithAvailable[], selectedEntities: { entity_id: number; }[]): string => {
            if (ewa && selectedEntities) {
                return ewa.filter(p => !!selectedEntities.find(ps => ps.entity_id === p.id))
                    .map(p => p.name).join('\r\n');
            }
            return '';
        };

        this.setListString = makeString(this.setList, this.planning?.planning_sets);
        this.dumperListString = makeString(this.dumperList, this.planning?.planning_dumpers);
        this.rollerListString = makeString(this.rollerList, this.planning?.planning_rollers);
        this.otherString = makeString(this.asphaltOtherList, this.planning?.planning_asphalt_others);
    }

    private saveAction() {
        const savePlanning = () => {
            this.planningService.save(this.planning).then(planning => {
                this.saving = false;
                this.planning.id = planning.id;

                this.dialogRef.close(this.planning);
            }, error => {
                Utils.handleError(error, this.confirmDialogService);
                this.saving = false;
            });
        };
        if (this.leave) {
            this.leave.users = this.leave.users?.map(u => u.id) as any[];
            this.subscriptions.add(this.leaveService.saveLeave(this.leave).subscribe(leave => {
                this.planning.leave_id = leave.data.id;
                savePlanning();
            }));
        } else {
            savePlanning();
        }
    }

    private genEndTimesList() {
        if (this.entitiesMap) {
            const fromDate = new Date(this.mainPlanning.begindate);
            fromDate.setUTCHours(fromDate.getUTCHours() + 1);
            const toDate = new Date(fromDate);
            const maxTime = this.statusControl.value === PlanningStatus.geblokkeerd ? Settings.DEFAULT_DURATION_BLOCK : Settings.MAX_TIMESPAN_HOURS;
            toDate.setUTCHours(toDate.getUTCHours() + maxTime);
            if (this.mainPlanning.entity) {
                this.endTimes = Utils.genTimesMapOneEntity(
                    fromDate,
                    toDate,
                    this.mainPlanning as PlanningHasEntity,
                    this.mainPlanning.planning_id,
                    this.planningList,
                    this.unavailableList,
                    true
                );
            }
        }
    }

    private updateEntityAvailability() {
        if (this.mainPlanning.begindate) {
            this.entitiesService.getUnavailableEntityIds(
                this.mainPlanning.planning_id,
                this.mainPlanning.begindate,
                this.mainPlanning.enddate).then(unavailableIds => {
                const allEntities = [
                    ...this.cutterList,
                    ...this.wipetruckList,
                    ...this.setList,
                    ...this.asfaltTeamList,
                    ...this.dumperList,
                    ...this.truckList,
                    ...this.rollerList,
                    ...this.craneList,
                    ...this.asphaltOtherList,
                    ...this.stickyWagonList
                ];
                const onlyForMain = [this.mainPlanning.entitytype_id];

                if (this.mainPlanning.entitytype_id === EntityTypeCode.AsfaltTeam) {
                    onlyForMain.push(EntityTypeCode.Set);
                    onlyForMain.push(EntityTypeCode.Dumper);
                }
                allEntities.filter(e => onlyForMain.indexOf(e.entitytype_id) !== -1)
                    .forEach(entity => {
                        entity.notAvailable = unavailableIds.indexOf(entity.id) !== -1;
                        if (entity.entitytype_id === EntityTypeCode.Set) {
                            if (this.planning.planning_sets) {
                                entity.usedHere = this.planning.planning_sets.map(e => e.entity_id).indexOf(entity.id) !== -1;
                            } else {
                                entity.usedHere = false;
                            }
                        }
                        if (entity.entitytype_id === EntityTypeCode.Dumper) {
                            if (this.planning.planning_dumpers) {
                                entity.usedHere = this.planning.planning_dumpers.map(e => e.entity_id).indexOf(entity.id) !== -1;
                            } else {
                                entity.usedHere = false;
                            }
                        }
                        if (entity.entitytype_id === EntityTypeCode.Roller) {
                            if (this.planning.planning_rollers) {
                                entity.usedHere = this.planning.planning_rollers.map(e => e.entity_id).indexOf(entity.id) !== -1;
                            } else {
                                entity.usedHere = false;
                            }
                        }
                    });
                if (typeof this.cutterListComponent !== 'undefined') {
                    this.cutterListComponent.checkAddPossibility();
                }
                if (typeof this.wipetruckListComponent !== 'undefined') {
                    this.wipetruckListComponent.checkAddPossibility();
                }
            });
        }
    }

    focusProjectSelect() {
        this.projectSelect?.filter(this.planning.worknumber);
    }

    focusLocationSelect() {
        this.locationSelect.filter(this.planning.location);
    }

    addressSelected(event: string) {
        const address = this.addresses?.find(a => a.formattedAddress === event);
        if (address) {
            let asphaltLocation = address.formattedAddress;
            if (address.addressLine) {
                const numberIndex = address.addressLine?.search(/\d/);
                const street = address.addressLine?.slice(0, numberIndex < 5 ? address.addressLine?.length : numberIndex).trim();
                asphaltLocation = `${street}, ${address.locality}`;
            }
            this.form.get('asphalt_location').setValue(asphaltLocation);
        }
    }

    private createMainForm(planning: Planning, mainPlanning: PlanningAsfaltteam | PlanningCutter | PlanningHasEntity, resetTransport = false) {
        this.form = new FormGroup({

            status_id: this.statusControl = new FormControl(planning.status_id || PlanningStatus.reservering, [Validators.required]),
            date: new FormGroup({
                date: new FormControl({value: mainPlanning.begindate, disabled: disabledWhenFinal(this)}, RequiredRule(this)),
                begintime: new FormControl(new Date(mainPlanning.begindate).getTime(), RequiredRule(this)),
                endtime: new FormControl(new Date(mainPlanning.enddate).getTime(), RequiredRule(this))
            }),
            projectmanager: new FormControl(planning.projectmanager, [RequiredRule(this), userIsNotDeleted(this)]),
            performer: new FormControl(planning.performer ?? this.mainPlanning?.entity?.default_performer_id, [RequiredRule(this), userIsNotDeleted(this)]),
            asphalt_performer: new FormControl(planning.asphalt_performer ?? this.mainPlanning?.entity?.default_asphalt_performer_id, [RequiredRule(this), userIsNotDeleted(this)]),
            contractor: new FormControl(planning.contractor, [Validators.maxLength(40), RequiredRule(this)]),
            worknumber: new FormControl(planning.worknumber, [Validators.maxLength(40), RequiredRule(this)]),
            location: new FormControl(planning.location, [Validators.maxLength(200), RequiredRule(this)]),
            asphalt_location: new FormControl(planning.asphalt_location, [Validators.maxLength(200), RequiredRule(this)]),
            traffic_controllers: new FormControl(planning.traffic_controllers, [Validators.max(250), RequiredRule(this)]),
            traffic: new FormControl(planning.traffic, RequiredRule(this)),
            mobile_crane: new FormControl(planning.mobile_crane),
            pouring_off: new FormControl(planning.pouring_off, RequiredRule(this)),
            hotbox: new FormControl(planning.hotbox),
            scatter: new FormControl(planning.scatter, RequiredRule(this)),
            sticky_wagon: new FormControl(planning.sticky_wagon),
            hire_people: new FormControl(),
            description: new FormControl(planning.description),
            afas_project_id: new FormControl(planning.afas_project_id),
            reset_transport: new FormControl(resetTransport),
            size: new FormControl(planning.size, RequiredRule(this)),

        });

        if (!this.viewModel.projectmanagerNotOwn) {
            this.form.get('projectmanager').disable();
        }

        this.subscriptions.add(this.form.valueChanges.pipe(debounceTime(25)).subscribe(() => this.formChanges()));
        this.subscriptions.add(this.form.get('date').get('date').valueChanges.subscribe(value => {
            if (value) {
                if (!this.mainPlanning.begindate) {
                    this.mainPlanning.begindate = new Date(value);
                    this.mainPlanning.begindate.setHours(6);
                    this.mainPlanning.enddate = new Date(this.mainPlanning.begindate);
                    this.mainPlanning.enddate.setHours(Settings.MAX_TIMESPAN_HOURS);
                }
                const oldDate = new Date(this.mainPlanning.begindate);
                const newDate = new Date(value);
                Utils.setTime(oldDate, 0, 0);
                Utils.setTime(newDate, 0, 0);
                const diffDays = Math.round(((newDate.getTime() - oldDate.getTime()) / 36e5) / 24);
                const newBeginDate = new Date(this.mainPlanning.begindate);
                newBeginDate.setDate(newBeginDate.getDate() + diffDays);
                const newEndDate = new Date(this.mainPlanning.enddate);
                newEndDate.setDate(newEndDate.getDate() + diffDays);
                this.mainPlanning.begindate = newBeginDate;
                this.mainPlanning.enddate = newEndDate;

                if (this.asphaltListComponent) {
                    this.asphaltListComponent.reValidateFields(diffDays);
                }
                if (this.wipetruckListComponent) {
                    this.wipetruckListComponent.reValidateFields(diffDays, oldDate);
                }
                if (this.cutterListComponent) {
                    this.cutterListComponent.reValidateFields(diffDays, oldDate);
                }
                this.form.get('date').get('endtime').setValue(newEndDate.getTime());
                this.form.get('date').get('begintime').setValue(newBeginDate.getTime());
                this.setTimesSubscription();
            } else {
                if (this.avCheckSubscriptions) {
                    this.avCheckSubscriptions.unsubscribe();
                }
                this.mainPlanning.begindate = this.mainPlanning.enddate = null;
            }
        }));
        this.subscriptions.add(this.form.get('date').get('begintime').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            if (this.mainPlanning.begindate) {
                const origBegin = new Date(this.mainPlanning.begindate);
                const origEnd = new Date(this.mainPlanning.enddate);
                this.mainPlanning.begindate.setTime(value);
                if (!disabledWhenFinal(this)) {
                    if (this.asphaltListComponent) {
                        this.asphaltListComponent.mainTimeChanged(origBegin, origEnd);
                    }
                    if (this.wipetruckListComponent) {
                        this.wipetruckListComponent.mainItemBeginTimeChanged(origBegin, origEnd, value);
                    }
                    if (this.cutterListComponent) {
                        this.cutterListComponent.mainItemBeginTimeChanged(origBegin, origEnd, value);
                    }
                }
                if (this.planningList) {
                    this.genEndTimesList();
                }
                Utils.triggerValidation(this.form, [
                    this.form.get('date').get('date'),
                    this.form.get('date').get('begintime'),
                    this.statusControl
                ]);
            }
        }));
        this.subscriptions.add(this.form.get('date').get('endtime').valueChanges.pipe(debounceTime(25)).subscribe(value => {
            if (this.mainPlanning.begindate) {
                const origBegin = new Date(this.mainPlanning.begindate);
                const origEnd = new Date(this.mainPlanning.enddate);
                this.mainPlanning.enddate.setTime(value);
                if (this.asphaltListComponent) {
                    this.asphaltListComponent.mainTimeChanged(origBegin, origEnd);
                }
                if (this.wipetruckListComponent) {
                    this.wipetruckListComponent.mainItemEndTimeChanged(origBegin, origEnd, value);
                }
                if (this.cutterListComponent) {
                    this.cutterListComponent.mainItemEndTimeChanged(origBegin, origEnd, value);
                }
                Utils.triggerValidation(this.form, [
                    this.form.get('date').get('date'),
                    this.form.get('date').get('endtime'),
                    this.form.get('date').get('begintime'), this.statusControl]);
            }
        }));

        this.subscriptions.add(this.form.get('contractor').valueChanges.subscribe(value => {
            value = value ? value : '';
            const sub = this.autocompleteService.getByType(AutocompleteType.contractors).subscribe(autoItems => {
                this.autocompleteContractors = autoItems.filter(a => a.toLowerCase().includes(('' + value).toLowerCase()));
            });
            sub.unsubscribe();
        }));

        this.autocompletePouringOff = Settings.pouring_off_defaults;

        if (this.viewModel.set) {
            this.form.addControl('sets', new FormControl({
                value: (planning.planning_sets ? planning.planning_sets.map(e => e.entity_id) : []),
                disabled: disabledWhenFinal(this)
            }, {
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning),
                validators: RequiredRule(this)
            }));
        }
        if (this.viewModel.dumpers) {
            this.form.addControl('dumpers', new FormControl({
                value: planning.planning_dumpers ? planning.planning_dumpers.map(e => e.entity_id) : [],
                disabled: disabledWhenFinal(this)
            }, {
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }
        if (this.viewModel.rollers) {
            this.form.addControl('rollers', new FormControl({
                value: planning.planning_rollers ? planning.planning_rollers.map(e => e.entity_id) : [],
                disabled: disabledWhenFinal(this)
            }, {
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }
        if (this.viewModel.asphaltOthers) {
            this.form.addControl('asphalt_others', new FormControl({
                value: planning.planning_asphalt_others ? planning.planning_asphalt_others.map(e => e.entity_id) : [],
                disabled: disabledWhenFinal(this)
            }, {
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }
        if (this.viewModel.asfaltteam) {
            this.form.addControl('asfaltteam', new FormControl({
                value: mainPlanning.entity_id,
                disabled: disabledWhenFinal(this)
            }, {
                validators: RequiredRule(this),
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }
        if (this.viewModel.projectteam) {
            this.form.addControl('projectteam', new FormControl({
                value: mainPlanning.entity_id,
                disabled: disabledWhenFinal(this)
            }, {
                validators: RequiredRule(this),
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }

        if (this.viewModel.preparationteam) {
            this.form.addControl('preparationteam', new FormControl({
                value: mainPlanning.entity_id,
                disabled: disabledWhenFinal(this)
            }, {
                validators: RequiredRule(this),
                asyncValidators: entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)
            }));
        }

        if (this.viewModel.wipetruck) {
            const wipetruckPlanning = mainPlanning as PlanningHasEntity;
            const fgDriverUserId = new FormControl(
                wipetruckPlanning.driver_user_id,
                {
                    asyncValidators: localUserAvailableValidator(
                        wipetruckPlanning as PlanningHasEntity,
                        this.userPlanningService,
                        this.planningHasService,
                        !UserService.userHasRights(UserType.WORKPLANNER)
                    )
                });
            this.form.addControl('wipetruck', new FormGroup({
                    wipetruck: new FormControl(wipetruckPlanning.entity_id, RequiredRule(this), entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)),
                    driver_user_id: fgDriverUserId,
                    type: new FormControl(wipetruckPlanning.type, Validators.required)
                })
            );
        }
        if (this.viewModel.cutter) {
            const cutterPlanning = mainPlanning as PlanningCutter;
            const fgDriverUserId = new FormControl(
                cutterPlanning.driver_user_id,
                {
                    asyncValidators: localUserAvailableValidator(
                        cutterPlanning as PlanningHasEntity,
                        this.userPlanningService,
                        this.planningHasService,
                        !UserService.userHasRights(UserType.WORKPLANNER)
                    )
                });
            this.form.addControl('cutter', new FormGroup({
                    cutter: new FormControl(cutterPlanning.entity_id, Validators.required, entityAvailableValidator(this.planningHasService, this.entitiesMap, mainPlanning)),
                    driver_user_id: fgDriverUserId,
                    location: new FormControl(cutterPlanning.location, RequiredRule(this)),
                    tons: new FormControl(cutterPlanning.tons, RequiredRule(this)),
                    trucks: new FormControl(cutterPlanning.trucks, RequiredRule(this)),
                    thickness: new FormControl(cutterPlanning.thickness, RequiredRule(this)),
                    hiring_id: new FormControl(cutterPlanning.hiring_id, RequiredRule(this)),
                    name: new FormControl(cutterPlanning.name)
                })
            );
        }

        if (this.viewModel.formDisabled) {
            setTimeout(() => {
                this.form.get('date').disable({emitEvent: false});
                this.form.disable({emitEvent: false});
            });
        }
        if (this.viewModel.statusDisabled) {
            this.form.get('status_id').disable();
        }

        this.statusControl.valueChanges.pipe(debounceTime(25)).subscribe(() => {
            this.updateStatus();
        });
        this.form.get('afas_project_id').valueChanges.pipe(debounceTime(25)).subscribe(() => {
            this.updateLeave();
        });
    }

    private createLeave() {
        const leave = new Leave();
        leave.hourtype = RealisationHourtype.sleep;
        leave.afas_project_id = this.planning.afas_project_id;
        leave.begindate = new Date(this.mainPlanning.begindate);
        leave.enddate = new Date(this.mainPlanning.begindate);
        leave.enddate.setHours(leave.enddate.getHours() + Settings.DEFAULT_DURATION_SLEEP);
        leave.comment = 'Slaapdag';
        leave.begintime = formatDate(leave.begindate, 'HH:mm', 'nl');
        leave.endtime = formatDate(leave.enddate, 'HH:mm', 'nl');
        leave.users = [];
        return leave;
    }

    updateLeave(reInit = false) {
        if ((!this.planning?.id || reInit) && this.statusControl.value === PlanningStatus.slaapdag) {
            const from = Utils.setTime(new Date(this.mainPlanning.begindate), 0, 0);
            from.setDate(from.getDate() - 2);
            const to = new Date(this.mainPlanning.begindate);
            this.subscriptions.add(this.planningService.getFilteredList(from, to).subscribe(plannings => {
                const pastPlannings = plannings.filter(p => p.afas_project_id === this.planning.afas_project_id);
                const userPlanning = pastPlannings.map(p => p.user_planning).flat()
                    .filter((up, i, ups) => ups.findIndex(usr => usr.user_id === up.user_id) === i);
                const leave = !this.leave ? this.createLeave() : this.leave;
                leave.users = userPlanning.map(up => up.user);
                this.leave = leave;
            }));
        }
    }

    private updateStatus() {
        this.planning.status_id = this.statusControl.value;
        this.updateLeave(this.planning.id && this.planning.status_id != this.origPlanning.status_id);
        const dateControls = [
            this.form.get('date').get('date'),
            this.form.get('date').get('begintime'),
            this.form.get('date').get('endtime')
        ];
        if (this.fixedDate && ![PlanningStatus.definitief, PlanningStatus.slaapdag, PlanningStatus.geblokkeerd].includes(this.planning.status_id)) {
            this.planning.status_id = PlanningStatus.definitief;
            this.form.get('status_id').setValue(PlanningStatus.definitief);
        }

        // When PlanningStatus.bakvanwessel, date should be empty
        if (this.statusControl.value === PlanningStatus.bakvanwessel) {
            dateControls.forEach(control => {
                control.reset(null);
                control.disable();
            });
        } else if (this.initialStatus === PlanningStatus.bakvanwessel) {
            // When previous state was PlanningStatus.bakvanwessel, refill date with today
            if (dateControls[0].disabled) {
                dateControls.forEach(control => {
                    control.enable();
                });
            }
        }

        if (this.statusControl.value === PlanningStatus.slaapdag && !this.planning.id) {
            const startDate = new Date(this.form.get('date').get('begintime').value);
            const endTime = Utils.setTime(startDate, startDate.getHours() + Settings.DEFAULT_DURATION_SLEEP, startDate.getMinutes());
            this.mainPlanning.enddate = new Date(endTime);
            this.form.get('date').get('endtime').setValue(endTime.getTime());
        }
        if (this.statusControl.value === PlanningStatus.geblokkeerd && !this.planning.id) {
            const startDate = Utils.setTime(new Date(this.form.get('date').get('begintime').value), 0, 0);
            const endDate = Utils.setTime(new Date(startDate), 24, 0);
            this.mainPlanning.begindate = new Date(startDate);
            this.form.get('date').get('begintime').setValue(this.mainPlanning.begindate.getTime());
            this.mainPlanning.enddate = new Date(endDate);
            this.form.get('date').get('endtime').setValue(this.mainPlanning.enddate.getTime());
        }

        Utils.triggerValidation(this.form, [this.statusControl]);
        if (this.wipetruckListComponent) {
            this.wipetruckListComponent.updateStatus(this.statusControl.value);
        }
        if (this.cutterListComponent) {
            this.cutterListComponent.updateStatus(this.statusControl.value);
        }
        if (this.asphaltListComponent) {
            this.asphaltListComponent.updateStatus(this.statusControl.value);
        }
        this.viewModel.updateViewState(this.mainPlanning.entitytype_id, LocalStorage.getUser(), this.planning, !!this.fixedDate);
    }

    backToConcept() {
        if (this.form.dirty
            || (this.asphaltListComponent && this.asphaltListComponent.checkDirty())
            || (this.cutterListComponent && this.cutterListComponent.checkDirty())
            || (this.wipetruckListComponent && this.wipetruckListComponent.checkDirty())) {
            this.confirmDialogService.confirm(
                'Sla eerst je wijzigingen op',
                'Voordat je een planning kunt terugzetten naar concept moet je eerst de wijzigingen die je hebt gemaakt opslaan.',
                'Oke', null).then(() => {
            });
        } else {
            let trucks = this.planning.planning_has.filter(pha => [EntityTypeCode.Truck, EntityTypeCode.CutterTruck, EntityTypeCode.AsfaltTruck].indexOf(pha.entitytype_id) !== -1)?.length;
            trucks += this.planning.planning_has.filter(pha => !!pha.truck_entity_id)?.length;

            const cranes = this.planning.planning_has.filter(pha => [EntityTypeCode.Crane].indexOf(pha.entitytype_id) !== -1)?.length;
            const kleefw = this.planning.planning_has.filter(pha => [EntityTypeCode.StickyWagon].indexOf(pha.entitytype_id) !== -1)?.length;
            let lowloader = this.planning.planning_has.filter(pha => [EntityTypeCode.LowLoader].indexOf(pha.entitytype_id) !== -1)?.length;
            lowloader += this.planning.planning_has.filter(pha => !!pha.lowloader_entity_id && this.entitiesMap.get(pha.lowloader_entity_id)?.use_once)?.length;

            const employees = this.planning.user_planning.length;
            const dumpersUnequal = this.planning.planning_has
                .filter(pha => [EntityTypeCode.Dumper].indexOf(pha.entitytype_id) !== -1 &&
                    (
                        Utils.getTimeOrNull(pha.begindate) !== Utils.getTimeOrNull(this.mainPlanning.begindate) ||
                        Utils.getTimeOrNull(pha.enddate) !== Utils.getTimeOrNull(this.mainPlanning.enddate)
                    ))?.length;
            const setsUnequal = this.planning.planning_has
                .filter(pha => [EntityTypeCode.Set].indexOf(pha.entitytype_id) !== -1 &&
                    (
                        Utils.getTimeOrNull(pha.begindate) !== Utils.getTimeOrNull(this.mainPlanning.begindate) ||
                        Utils.getTimeOrNull(pha.enddate) !== Utils.getTimeOrNull(this.mainPlanning.enddate)
                    ))?.length;

            let text = `Terugzetten naar concept heeft de volgende consequenties:<br><br>`;

            if (employees || trucks || cranes || kleefw || lowloader) {
                text += `<b>Vervalt</b><br>
                ${employees ? `${employees} ingeplande medewerkers, ` : ''}
                ${trucks ? `${trucks} vrachtwagens, ` : ''}
                ${cranes ? `${cranes} kranen, ` : ''}
                ${kleefw ? `${kleefw} kleefwagens, ` : ''}
                ${lowloader ? `${lowloader} diepladers, ` : ''}<br><br>`;
            }
            if (dumpersUnequal || setsUnequal) {
                text += `<b>Tijden terug naar hoofditem</b><br>
                ${dumpersUnequal ? `${dumpersUnequal} dumpers, ` : ''}
                ${setsUnequal ? `${setsUnequal} sets` : ''}<br><br>`;
            }

            text += `Dit kan niet ongedaan gemaakt worden. Weet je zeker dat je door wilt gaan?<br>`;

            if ((employees + trucks + cranes + kleefw + lowloader + dumpersUnequal + setsUnequal) === 0) {
                text = 'Er zijn geen consequenties voor ingepland materiaal.';
            }

            this.confirmDialogService.confirm('Defintieve planning terug naar concept',
                text,
                'Terugzetten naar concept', 'Annuleren'
            ).then(() => {
                this.initialStatus = 1;
                this.form = null;
                setTimeout(() => {
                    this.planning = JSON.parse(JSON.stringify(this.origPlanning));
                    this.createMainForm(this.planning, this.mainPlanning, true);
                    const allPromises = [
                        this.cutterListComponent?.validateForms() ?? of(true),
                        this.wipetruckListComponent?.validateForms() ?? of(true),
                        this.asphaltListComponent?.validateForms() ?? of(true),
                        Utils.triggerValidationP(this.form)
                    ];

                    Promise.all(allPromises).then(() => {

                    }, () => {
                    });
                });
            }, () => {

            });
        }
    }

    protected readonly PlanningSize = PlanningSize;
}
