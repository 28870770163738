import {Component, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {RealisationCount, RealisationService} from '../services/realisation.service';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {Planning} from '../classes/planning.class';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../utils.class';
import {CommentDialogComponent} from '../hour-registration/comment-dialog/comment-dialog.component';
import {TimePickerService} from '../services/time-picker.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {UserService, UserType} from '../services/user/user.service';
import {NotEditablePipe} from './not-editable.pipe';
import {debounceTime} from 'rxjs/operators';
import {AddRealisationDialogComponent} from './add-realisation-dialog/add-realisation-dialog.component';
import {Project} from '../classes/project.class';
import {LocalStorage} from '../storage.class';
import {Subscription} from 'rxjs';
import {CheckHoursFilterPipe} from './check-hours-filter.pipe';
import {EntityId, EntityTypeCode} from '../services/entities/entity-type.class';
import {EditRealisationProjectDialogComponent} from './edit-realisation-project-dialog/edit-realisation-project-dialog.component';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {User} from '../classes/user.class';
import {HistoryDialogComponent} from '../hour-registration/history-dialog/history-dialog.component';
import {EntitiesService} from '../services/entities/entities.service';
import {Entity} from '../classes/entity.class';
import {CopyRealisationFromOtherDayComponent} from './copy-realisation-from-other-day/copy-realisation-from-other-day.component';

@Component({
    selector: 'app-check-hours',
    templateUrl: './check-hours.component.html',
    styleUrls: ['./check-hours.component.scss']
})
export class CheckHoursComponent extends CodaltComponent implements OnInit {

    openPreviousDays: RealisationCount[];
    realisations: Realisation[];
    filteredRealisations: Realisation[];
    RealisationHourtype = RealisationHourtype;
    date: Date;
    form: FormArray<ControlsOf<FgRealisation>>;
    formMap: Map<Realisation, FormGroup<ControlsOf<FgRealisation>>>;
    planningRealisationsList: PlanningRealisations[];
    allowEdit = true;
    disableNext = false;
    fcHideApproved = new FormControl(false);
    forceCheckCounter = 0;
    maxDateValue: Date;

    fcDate = new FormControl();

    userPauseMap: Map<number, number>;
    userMinutesMap: Map<number, number>;
    entityMinutesMap: Map<string, number>;
    changedPauseUserId: number;

    saving = false;
    saved = false;

    lastReload = new Date().getTime();

    highlight?: number;

    userFunctions: string[];

    filteredEmployees = 0;
    totalEmployees = 0;

    fcUserFunction = new FormControl<string[]>(LocalStorage.userFunctionFilter);
    fcHourType = new FormControl<string[]>(LocalStorage.hourTypeFilter);
    fcExecutor = new FormControl<any>();

    EntityTypeCode = EntityTypeCode;

    daySubscriptions: Subscription;

    executors: User[];
    entities: Entity[];

    initialExecutorSelectionAllowed = true;

    constructor(private activatedRoute: ActivatedRoute,
                private timePickerService: TimePickerService,
                private dialog: MatDialog,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                private title: Title,
                private userService: UserService,
                private entityService: EntitiesService,
                private realisationService: RealisationService) {
        super();
        this.title.setTitle('Urencontrole' + environment.titleAppend);
    }

    ngOnInit(): void {
        if (LocalStorage.user.checksHours) {
            this.fcExecutor.setValue('checkFor');
        }
        this.subscriptions.add(this.fcHourType.valueChanges.subscribe(hourtypes => {
            LocalStorage.hourTypeFilter = hourtypes;
            this.calcFilteredEmployees();
        }));
        this.subscriptions.add(this.fcDate.valueChanges.subscribe(value => {
            if (value && Utils.dateString(value as any) !== Utils.dateString(this.date)) {
                this.router.navigateByUrl(`check-hours/${formatDate(value as any, 'yyyy-MM-dd', 'nl')}`);
            }
        }));

        this.subscriptions.add(this.fcExecutor.valueChanges.subscribe(executors => {
            this.getData();
        }));

        this.subscriptions.add(this.fcUserFunction.valueChanges.subscribe(functions => {
            LocalStorage.userFunctionFilter = functions;
            this.calcFilteredEmployees();
        }));
        this.subscriptions.add(this.fcHideApproved.valueChanges.subscribe(() => {
            this.calcFilteredEmployees();
        }));
        this.subscriptions.add(this.userService.userFunctions(false).subscribe(functions => {
            this.userFunctions = functions.data;
        }));
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { date: string, realisationId?: number }) => {
            this.daySubscriptions?.unsubscribe();
            this.daySubscriptions = new Subscription();
            const today = new Date();
            let paramDate = params.date;
            if (this.maxDate(1) < new Date(params.date)) {
                paramDate = null;
            }
            if (this.maxDate() < new Date(params.date)) {
                this.disableNext = true;
            }
            this.date = paramDate ? new Date(paramDate) : new Date(formatDate(today, 'yyyy-MM-dd', 'nl'));
            this.fcDate.patchValue(this.date);
            this.subscriptions.add(this.entityService.getList().subscribe(entities => {
                this.entities = entities;
                this.daySubscriptions.add(this.realisationService.getDayExecutors(this.date).subscribe(executors => {
                    this.executors = executors.data;
                    if (!this.executors.find(e => e.id === LocalStorage.user?.id) && UserService.userHasRights(UserType.DYNAMIC_PERFORMER)) {
                        this.executors.push(LocalStorage.user);
                    }
                    if (UserService.userHasRights(UserType.DYNAMIC_PERFORMER)
                        && !this.fcExecutor.value
                        && this.initialExecutorSelectionAllowed
                        && !UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
                        this.fcExecutor.setValue(LocalStorage.user.id);
                        this.initialExecutorSelectionAllowed = false;
                    } else {
                        this.getData(params.realisationId ?? null);
                    }
                }));
            }));

        }));

        window.onfocus = () => {
            if ((new Date().getTime() - this.lastReload) > 60000 && !this.form.dirty && !this.saving) {
                this.lastReload = new Date().getTime();
                setTimeout(() => {
                    this.getData();
                }, 50);
            }
        };
    }

    clearData() {
        this.filteredEmployees = 0;
        this.totalEmployees = 0;
        this.planningRealisationsList = null;
    }

    calcFilteredEmployees() {
        this.filteredEmployees = 0;
        this.totalEmployees = 0;
        this.planningRealisationsList.forEach(planningRealisation => {
            this.totalEmployees += planningRealisation.userRealisations.length;

            this.filteredEmployees += (new CheckHoursFilterPipe).transform(planningRealisation.userRealisations, this.fcUserFunction.value, this.fcHourType.value, this.fcHideApproved.value, 0).length;
        });
    }

    private getData(scrollTo?: number) {
        this.daySubscriptions.add(this.realisationService.getPreviousDayOpen(this.date, UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) ? this.fcExecutor.value : LocalStorage.user?.id).subscribe(r => {
            this.openPreviousDays = r.data.sort((a, b) => {
                return Utils.getTimeOrNull(a.bookdate) - Utils.getTimeOrNull(b.bookdate);
            });
        }));
        this.daySubscriptions.add(this.realisationService.getDaySchedule(this.date, this.fcExecutor.value).subscribe(realisations => {
            this.realisations = realisations.data;
            this.filteredRealisations = [];
            this.form = new FormArray<ControlsOf<FgRealisation>>([]);
            this.formMap = new Map<Realisation, FormGroup<ControlsOf<FgRealisation>>>();
            const planningRealisationsList = [] as PlanningRealisations[];
            const afasProjectIds = [...new Set(realisations.data.map(Utils.realisationAfasProjectId) || [])];
            const hasGenHourCheck = UserService.userHasRights(UserType.GENERAL_HOUR_CHECK);
            const hasDynamicPerformer = UserService.userHasRights(UserType.DYNAMIC_PERFORMER);
            const filterPerformerId = hasDynamicPerformer ? this.fcExecutor.value : LocalStorage.user.id;
            const belongsToEntityType = this.entities.filter(e => {
                return e.default_employees?.includes(LocalStorage.user.id);
            });
            const entityTypeIds = belongsToEntityType.map(e => e.entitytypes.map(ee => ee.id)).flat();
            afasProjectIds.forEach(afasProjectId => {
                const planning = realisations.data.find(r => r.planning?.afas_project_id === afasProjectId)?.planning;
                const userPlanning = realisations.data.find(r => r.user_planning?.afas_project_id === afasProjectId)?.user_planning;
                const project = planning?.project ?? realisations.data.find(r => r.afas_project_id === afasProjectId)?.project ?? userPlanning?.project;
                const sortString = (realisation: Realisation) => {
                    if (realisation.user) {
                        return `${realisation.user.function}_${realisation.user.lastname}_${realisation.user.firstname}_${realisation.user.name}`;
                    }
                    if (realisation.entity) {
                        return `${realisation.entity.name}_${realisation.hiring_name}`;
                    }
                    return realisation.hiring_name;
                };

                const planningRealisations = realisations.data
                    .filter(r => Utils.realisationAfasProjectId(r) === afasProjectId)
                    // Hide realisations removed by GENERAL_HOUR_CHECK
                    .filter(r => hasGenHourCheck || !r.removed || (r.comment_manager?.length > 0 || Utils.parentRealisations(r, this.realisations).filter(rr => rr.comment_manager?.length > 0).length > 0))
                    // Hide realisations with a custom performer_id on phe line
                    .filter(r => hasGenHourCheck || !r.planning_has?.performer_id || filterPerformerId === r.planning_has.performer_id || filterPerformerId === r.user_id)
                    // Filter is_team entities, skip realisations without planning_has
                    .filter(r => !r.planning_has?.entitytype?.is_team || !r.planning_has_entity_id)
                    .sort((a, b) => {
                        const beginDate = (r: Realisation) => Utils.getTimeOrNull(r.planning_has?.begindate ?? r.user_planning?.begindate ?? r.begindate);
                        return beginDate(a) - beginDate(b);
                    }).sort((a, b) => sortString(a).localeCompare(sortString(b)));

                const hiringEntities = planningRealisations.filter(r => !!r.entity_id && !r.entity?.use_once && `${r.entity?.name}` !== 'GEEN');
                const hiringEntitiesStaff = hiringEntities.filter(r => r.planning_has?.entitytype_id === EntityTypeCode.InhuurPersoneel || r.entity_id === EntityId.HiringEmployee);
                const hiringEntitiesOther = hiringEntities.filter(r => (r.planning_has && r.planning_has?.entitytype_id !== EntityTypeCode.InhuurPersoneel) || r.entity_id === EntityId.HiringMaterials);

                const currentItem = this.planningRealisationsList?.find(pr => pr?.planning?.id === planning?.id && pr?.project?.afas_project_id === project?.afas_project_id);

                const userRealisations = planningRealisations.filter(r => {
                    const isRegisteredTravel = UserService.userHasRights(UserType.HOUR_REGISTER, r.user)
                        && [RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(r.hourtype);
                    const hideSleep = hasGenHourCheck || r.hourtype !== RealisationHourtype.sleep || r.saved_by_id === LocalStorage.user.id;
                    return !!r.user_id && (hasGenHourCheck || !isRegisteredTravel) && hideSleep;
                });
                const entityRealisations = planningRealisations.filter(pr => !!pr.entity_id && pr.entity?.use_once && (entityTypeIds.length === 0 || pr.entity.entitytypes?.filter(et => !et.belongs_to || entityTypeIds.includes(et.belongs_to)).length > 0));
                const entityHiringRealisations = hiringEntitiesOther.filter(pr => (entityTypeIds.length === 0 || pr.entity.entitytypes?.filter(et => !et.belongs_to || entityTypeIds.includes(et.belongs_to)).length > 0));
                const mainPlanningRealisation = planningRealisations
                    .sort((a, b) => Utils.minuteDuration(b.enddate, b.begindate) - Utils.minuteDuration(a.enddate, a.begindate))
                    .find(r => ((!!planning?.entity_id && r.entity_id === planning?.entity_id) || !planning?.entity_id) && r.hourtype === RealisationHourtype.worktime);

                planningRealisationsList.push({
                    userRealisations,
                    entityRealisations,
                    entityHiringRealisations,
                    hiredStaffRealisations: hiringEntitiesStaff,
                    planning,
                    project,
                    mainPlanningRealisation,
                    showMaterial: currentItem?.showMaterial ?? planningRealisations.findIndex(pr => pr.id === scrollTo && !!pr.entity_id) !== -1,
                    showStaff: currentItem?.showStaff ?? true,
                    showHiredMaterial: hiringEntitiesOther.findIndex(pr => pr.id === scrollTo) !== -1,
                    showHiredStaff: currentItem?.showHiredStaff ?? true
                });
                this.filteredRealisations.push(...userRealisations);
                this.filteredRealisations.push(...hiringEntitiesStaff);
                this.filteredRealisations.push(...entityRealisations);
                this.filteredRealisations.push(...entityHiringRealisations);
            });

            realisations.data.forEach(realisation => {
                const formGroup = this.createRealisationForm(realisation);
                this.form.push(formGroup);
                this.formMap.set(realisation, formGroup);
            });
            this.recalculatePauses();
            this.daySubscriptions.add(this.form.valueChanges.subscribe(() => {
                this.saved = false;
            }));
            if (scrollTo) {
                this.highlight = scrollTo;
                setTimeout(() => {
                    this.highlight = null;
                }, 1500);
                document.querySelector(`#realisation-${scrollTo}`)?.scrollIntoView();
                document.querySelector('#main-container').scrollBy(0, -80);
            }
            this.planningRealisationsList = planningRealisationsList;
            this.calcFilteredEmployees();
        }));
    }

    private recalculatePauses() {
        const newUserPauseMap = new Map<number, number>();
        const newEntityMinutesMap = new Map<string, number>();
        const newUserMinutesMap = new Map<number, number>();
        this.filteredRealisations.forEach(realisation => {
            if (!realisation.removed) {
                if (realisation.user_id) {
                    let pause = newUserPauseMap.get(realisation.user_id) || 0;
                    pause += realisation.pause;
                    newUserPauseMap.set(realisation.user_id, pause);

                    let minutes = newUserMinutesMap.get(realisation.user_id) || 0;
                    minutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    newUserMinutesMap.set(realisation.user_id, minutes);
                } else {
                    const entityKey = `${realisation.entity_id}-${(realisation.hiring_name ? (realisation.hiring_name + '-' + realisation.planning_has_entity_id) : '')}`;
                    let minutes = newEntityMinutesMap.get(entityKey) || 0;
                    minutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    newEntityMinutesMap.set(entityKey, minutes);
                }
            }
        });
        [...newUserPauseMap.keys()].forEach(userId => {
            const pauseChanged = this.userPauseMap?.get(userId) !== newUserPauseMap.get(userId);
            if (this.userPauseMap?.has(userId) && pauseChanged) {
                this.changedPauseUserId = userId;
                setTimeout(() => {
                    this.changedPauseUserId = null;
                }, 1500);
            }
        });

        this.entityMinutesMap = newEntityMinutesMap;
        this.userPauseMap = newUserPauseMap;
        this.userMinutesMap = newUserMinutesMap;
    }

    private createRealisationForm(realisation: Realisation) {
        const fg = new FormGroup<ControlsOf<FgRealisation>>({
            id: new FormControl(realisation.id),
            approved: new FormControl(realisation.approved),
            comment_user_approved_handled: new FormControl(realisation.comment_user_approved_handled),
            skip_performer: new FormControl(realisation.skip_performer),
            begindate: new FormControl(realisation.begindate),
            enddate: new FormControl(realisation.enddate),
            planning_id: new FormControl(realisation.planning_id),
            updated_at: new FormControl(realisation.updated_at),
            comment_manager: new FormControl(realisation.comment_manager),
            removed: new FormControl(realisation.removed)
        });

        this.daySubscriptions.add(fg.controls.begindate.valueChanges.subscribe(begindate => {
            realisation.begindate = new Date(begindate);
        }));
        this.daySubscriptions.add(fg.controls.enddate.valueChanges.subscribe(enddate => {
            realisation.enddate = enddate;
        }));

        this.daySubscriptions.add(fg.valueChanges.pipe(debounceTime(100)).subscribe(fgChanged => {
            if (fg.dirty) {
                this.daySubscriptions.add(this.realisationService.approveRealisation(fgChanged as any).subscribe(
                    serverRealisations => this.setRealisations(serverRealisations.data),
                    (error) => {
                        if (error.message === 'Conflict') {
                            this.setRealisations(error.data);
                        } else {
                            setTimeout(() => {
                                console.log('RELAOD QQ');
                                window.location.reload();
                            });
                        }
                    }));
            }
        }));

        return fg;
    }

    private setRealisations(serverRealisations: Realisation[]) {
        serverRealisations.forEach(serverRealisation => {
            const localRealisation = this.realisations.find(r => r.id === serverRealisation.id);
            if (localRealisation) {
                Object.assign(localRealisation, serverRealisation);
                const fgLocal = this.formMap.get(localRealisation);
                if (fgLocal) {
                    fgLocal.setValue({
                        id: serverRealisation.id,
                        planning_id: serverRealisation.planning_id,
                        updated_at: serverRealisation.updated_at,
                        approved: serverRealisation.approved,
                        comment_user_approved_handled: serverRealisation.comment_user_approved_handled,
                        skip_performer: serverRealisation.skip_performer,
                        begindate: serverRealisation.begindate,
                        enddate: serverRealisation.enddate,
                        comment_manager: serverRealisation.comment_manager,
                        removed: serverRealisation.removed
                    });
                    fgLocal.markAsPristine();
                }
            }
        });
        this.calcFilteredEmployees();
        this.recalculatePauses();
        this.forceCheckCounter++;
    }

    approveAll(planningRealisations: PlanningRealisations, realisation: Realisation) {
        const toApproves = planningRealisations.userRealisations.filter(ur => ur.user_id === realisation.user_id && !ur.approved);
        toApproves.forEach(toApprove => {
            this.approve(toApprove);
        });
    }

    approve(realisation: Realisation) {
        if (realisation.approved && realisation.comment_user_approved && !realisation.comment_user_approved_handled) {
            this.formMap.get(realisation).controls.comment_user_approved_handled.setValue(new Date());
        } else {
            this.formMap.get(realisation).controls.approved.setValue(new Date());
            this.formMap.get(realisation).controls.skip_performer.setValue(false);
        }
        this.formMap.get(realisation).markAsDirty();
        const entityRealisations = this.planningRealisationsList.find(pr => (pr.project?.afas_project_id ?? null) === Utils.realisationAfasProjectId(realisation))?.entityRealisations || [];

        entityRealisations.filter(er => er.planning_has_entity_id === (realisation.planning_has_entity_id ?? 'donotmapnull') || er.parent_realisation_id === realisation.id)
            .forEach(entityRealisation => {
                const fgEntityRealisation = this.formMap.get(entityRealisation);
                if (fgEntityRealisation) {
                    fgEntityRealisation.controls.approved.setValue(new Date());
                    fgEntityRealisation.controls.skip_performer.setValue(false);
                    fgEntityRealisation.markAsDirty();
                }
            });
    }

    skipPerformer(realisation) {
        this.formMap.get(realisation).controls.skip_performer.setValue(true);
        this.formMap.get(realisation).controls.approved.setValue(null);
        this.formMap.get(realisation).markAsDirty();
        if (this.formMap.get(realisation).controls.skip_performer.value) {
            this.editComment(realisation, true);
        }
    }

    editProjectComment(event, project: Project) {
        this.router.navigate(['project-dagrapport', project.afas_project_id, formatDate(this.date, 'yyyy-MM-dd', 'nl')]);
    }

    restore(realisation: Realisation) {
        this.formMap.get(realisation).controls.removed.setValue(null);
        this.formMap.get(realisation).controls.approved.setValue(null);
        this.formMap.get(realisation).controls.skip_performer.setValue(false);

        this.formMap.get(realisation).markAsDirty();

        if (realisation.user_id) {
            Utils.parentRealisations(realisation, this.realisations).forEach(parentRealisation => {
                this.formMap.get(parentRealisation).controls.removed.setValue(null);
                this.formMap.get(parentRealisation).controls.approved.setValue(null);
                this.formMap.get(parentRealisation).controls.skip_performer.setValue(false);
                this.formMap.get(parentRealisation).markAsDirty();
            });
        }
    }

    remove(realisation: Realisation) {
        this.formMap.get(realisation).controls.removed.setValue(new Date());

        if (realisation.user_id) {
            Utils.parentRealisations(realisation, this.realisations).forEach(parentRealisation => {
                this.formMap.get(parentRealisation).controls.removed.setValue(new Date());
                this.formMap.get(parentRealisation).markAsDirty();
            });
        }

        if (!UserService.userHasRights(UserType.GENERAL_HOUR_CHECK)) {
            this.skipPerformer(realisation);
        } else {
            this.approve(realisation);
        }
    }

    history(realisation: Realisation) {
        this.dialog.open(HistoryDialogComponent, {
            maxWidth: '99vw',
            width: '1600px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisation
            }
        });
    }

    editComment(realisation: Realisation, required = false) {
        const fcComment = this.formMap.get(realisation).controls.comment_manager;
        const ref = this.dialog.open(CommentDialogComponent, {
            maxWidth: '500px',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                comment: fcComment.value,
                required
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(comment => {
            if (comment !== undefined) {
                fcComment.setValue(comment);
                realisation.comment_manager = fcComment.value;
                this.formMap.get(realisation).markAsDirty();
            }
            if (required && !comment) {
                this.formMap.get(realisation).controls.approved.setValue(null);
                this.formMap.get(realisation).controls.removed.setValue(null);
                this.formMap.get(realisation).markAsDirty();
            }
        }));
    }

    editProjectTimes(event, planningRealisations: PlanningRealisations, field, addToCurrentTime = 0) {
        event.stopPropagation();
        if (planningRealisations.mainPlanningRealisation) {
            const realisations = [
                ...planningRealisations.entityRealisations || [],
                ...planningRealisations.entityHiringRealisations || [],
                ...planningRealisations.userRealisations || [],
                ...planningRealisations.hiredStaffRealisations || []
            ];
            const equalRealisations = realisations.filter(r =>
                new Date(r.begindate).getTime() === new Date(planningRealisations.mainPlanningRealisation.begindate).getTime()
                && new Date(r.enddate).getTime() === new Date(planningRealisations.mainPlanningRealisation.enddate).getTime()
                && !(new NotEditablePipe()).transform(r)
            );

            if (equalRealisations.length) {
                const formGroup = this.formMap.get(equalRealisations[0]);
                this.daySubscriptions.add(this.timePickerService.editRealisationTime(equalRealisations[0], field, formGroup, addToCurrentTime).subscribe(newDate => {
                    if (newDate) {
                        equalRealisations.forEach(realisation => {
                            const fgEntityRealisation = this.formMap.get(realisation);
                            fgEntityRealisation.get(field).setValue(new Date(newDate));
                            fgEntityRealisation.markAsDirty();
                        });
                        this.formMap.get(planningRealisations.mainPlanningRealisation).get(field).setValue(new Date(newDate));
                        planningRealisations.mainPlanningRealisation[field] = new Date(newDate);
                    }
                }));
            }
        }
    }

    updateRelatedRealisations(realisation, field: 'begindate' | 'enddate', newDate: Date, newEndDate: Date) {
        this.realisations
            .filter(er => er.planning_has_entity_id === (realisation.planning_has_entity_id ?? 'donotmapnull') || er.parent_realisation_id === realisation.id)
            .filter(er => er.hourtype === realisation.hourtype)
            .forEach(entityRealisation => {
                const fgEntityRealisation = this.formMap.get(entityRealisation);
                if (fgEntityRealisation) {
                    const origBeginMatches = Utils.getTimeOrNull(realisation.begindate) === Utils.getTimeOrNull(entityRealisation.begindate);
                    const origEndMatches = Utils.getTimeOrNull(realisation.enddate) === Utils.getTimeOrNull(entityRealisation.enddate);
                    if (origBeginMatches && origEndMatches) {
                        fgEntityRealisation.markAsDirty();
                        fgEntityRealisation.get(field).setValue(new Date(newDate));
                        if (newEndDate) {
                            fgEntityRealisation.get('enddate').setValue(new Date(newEndDate));
                        }
                    }
                }
            });
    }

    editTime(realisation: Realisation, field, addToCurrentTime = 0) {
        const fgRealisation = this.formMap.get(realisation);
        const firstRealisationForAUser = realisation.user_id && (this.realisations.filter(r => r.user_id === realisation.user_id && !r.removed)
            .sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate))[0].id === realisation.id);
        this.daySubscriptions.add(this.timePickerService.editRealisationTime(realisation, field, fgRealisation, addToCurrentTime, firstRealisationForAUser).subscribe(newDate => {
            if (newDate) {
                let newEndDate: Date = null;
                if (field === 'begindate' && newDate.getTime() > new Date(realisation.enddate).getTime()) {
                    newEndDate = new Date(newDate);
                    do {
                        newEndDate.setMinutes(newEndDate.getMinutes() + 1);
                    } while (newEndDate.getMinutes() % 3 !== 0);
                }
                if (!!realisation.user_id) {
                    if (field === 'enddate') {
                        const diff = Utils.getTimeOrNull(newDate) - Utils.getTimeOrNull(fgRealisation.get(field).value);
                        this.transferAllFutureTimes(realisation, diff);
                    }
                    this.updateRelatedRealisations(realisation, field, newDate, newEndDate);
                }
                fgRealisation.get(field).setValue(newDate);
                if (newEndDate) {
                    const diff = Utils.getTimeOrNull(newEndDate) - Utils.getTimeOrNull(fgRealisation.get('enddate').value);
                    this.transferAllFutureTimes(realisation, diff);
                    fgRealisation.get('enddate').setValue(newEndDate);
                }
                fgRealisation.markAsDirty();
            }
        }));
    }

    transferAllFutureTimes(fromRealisation: Realisation, timeInSeconds) {
        const userRealisations = this.realisations.filter(r => r.user_id === fromRealisation.user_id).sort((a, b) => Utils.realisationSort(a) - Utils.realisationSort(b));
        const fromIndex = userRealisations.indexOf(fromRealisation);
        const to = userRealisations.slice(fromIndex).find((r, index, realisations) => {
            return index < (realisations.length - 1) && new Date(r.enddate).getTime() !== new Date(realisations[index + 1].begindate).getTime();
        });
        const toIndex = to ? userRealisations.indexOf(to) : userRealisations.length - 1;
        userRealisations.slice(fromIndex, toIndex + 1).forEach((r, index) => {
            ['begindate', 'enddate'].forEach((field: 'begindate' | 'enddate') => {
                if (index !== 0 || field === 'enddate') {
                    const timeToSet = new Date(this.formMap.get(r).get(field).value);
                    timeToSet.setTime(timeToSet.getTime() + timeInSeconds);
                    this.updateRelatedRealisations(r, field, timeToSet, null);
                    this.formMap.get(r).get(field).setValue(timeToSet);
                    this.formMap.get(r).markAsDirty();
                }
            });
        });
    }

    maxDate(add?: number) {
        const maxDate = Utils.setTime(new Date(), 0, 0);
        maxDate.setDate(maxDate.getDate() + (7 - maxDate.getDay()));
        const nextWeekFromDay = 4;
        if (new Date().getDay() > nextWeekFromDay || (new Date().getDay() === nextWeekFromDay && new Date().getHours() > 11)) {
            maxDate.setDate(maxDate.getDate() + 7);
        }
        if (add) {
            maxDate.setDate(maxDate.getDate() + add);
        } else {
            this.maxDateValue = maxDate;
        }
        return maxDate;
    }

    next() {
        const maxDate = this.maxDate();
        if (this.date < maxDate) {
            this.date.setDate(this.date.getDate() + 1);
            this.clearData();
            this.router.navigateByUrl(`check-hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
        } else {
            this.disableNext = true;
        }
    }

    prev() {
        this.disableNext = false;
        this.date.setDate(this.date.getDate() - 1);
        this.clearData();
        this.router.navigateByUrl(`check-hours/${formatDate(this.date, 'yyyy-MM-dd', 'nl')}`);
    }

    add() {
        const ref = this.dialog.open(AddRealisationDialogComponent, {
            maxWidth: '550px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisations: this.realisations,
                bookdate: this.date
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(realisation => {
            if (realisation) {
                this.getData(realisation.id);
            }
        }));
    }

    editProject(realisation: Realisation) {
        const ref = this.dialog.open(EditRealisationProjectDialogComponent, {
            maxWidth: '450px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                realisations: this.realisations,
                realisation,
                bookdate: this.date
            }
        });
        this.daySubscriptions.add(ref.afterClosed().subscribe(rel => {
            if (rel) {
                this.getData(realisation.id);
            }
        }));
    }

    changePause(userId: number) {
        const options = [{
            minutes: 60,
            name: '1 uur'
        }, {
            minutes: 45,
            name: '3 kwartier'
        }, {
            minutes: 30,
            name: 'Half uur'
        }, {
            minutes: 15,
            name: 'Kwartier'
        }, {
            minutes: 0,
            name: 'Geen'
        }];
        this.confirmDialog.confirm(
            'Pauzeduur aanpassen', '', null, 'Annuleren', options
        ).then(data => {
            if (data) {
                this.daySubscriptions.add(this.realisationService.changePause(this.date, data.minutes, userId).subscribe(result => {
                    this.setRealisations(result.data.realisations);
                }));
            }
        }, () => {

        });
    }

    getFromPrevDay(planningRealisations: PlanningRealisations) {
        const ref = this.dialog.open(CopyRealisationFromOtherDayComponent, {
            data: {
                project: planningRealisations.project,
                date: this.date,
                realisations: [...planningRealisations.entityRealisations, ...planningRealisations.entityHiringRealisations]
            },
            maxWidth: '450px'
        });

        this.daySubscriptions.add(ref.afterClosed().subscribe(realisation => {
            if (realisation) {
                this.getData(realisation.id);
            }
        }));
    }

    readonly RealisationService = RealisationService;
}

interface FgRealisation {
    id: number;
    approved: FormControl<Date>;
    comment_user_approved_handled: FormControl<Date>;
    removed: FormControl<Date>;
    skip_performer: boolean;
    begindate: FormControl<Date>;
    enddate: FormControl<Date>;
    planning_id: number;
    comment_manager: string;
    updated_at: FormControl<Date>;
}

export interface PlanningRealisations {
    planning: Planning;
    project: Project;
    mainPlanningRealisation: Realisation;
    userRealisations: Realisation[];
    entityRealisations: Realisation[];
    entityHiringRealisations: Realisation[];
    hiredStaffRealisations: Realisation[];
    showHiredMaterial: boolean;
    showMaterial: boolean;
    showStaff: boolean;
    showHiredStaff: boolean;
}
