<div class="controls">
    <div class="header">
        <app-header [mainTitle]="'Afas nacalculatie'"
                    subTitle="Periode {{period}} - {{year}}">
        </app-header>
    </div>
    <div class="d-flex align-items-center">
        <div class="mt-1 ml-2 d-flex">
            <mat-radio-group [formControl]="fcType" class="d-flex">
                <mat-radio-button value="entities" class="mr-3">Materieel</mat-radio-button>
                <mat-radio-button value="users" class="mr-3">Medewerkers</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="indicator">
        <app-date-indicator (next)="next()"
                            (prev)="prev()"
                            [fromDate]="beginDate"
                            [toDate]="endDate">
        </app-date-indicator>
    </div>
    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>
</div>
<div class="wrapper">
    <div class="container-xl">
        <div class="mb-1 d-flex align-items-center">
            <button class="mr-2" mat-raised-button color="primary"
                    *ngIf="fcAfasProjectId.value"
                    routerLink="/settlements/{{period}}/{{year}}/{{fcType.value}}">
                Terug naar overzicht
            </button>
            <div *ngIf="fcAfasProjectId.value" class="pr-3">
                Project: {{fcAfasProjectId.value}}
            </div>
            <div *ngIf="total">
                Totaal <b>{{total}}</b> waarvan <b>{{totalSend}}</b> verstuurd en <b>{{totalError}}</b> mislukt
            </div>
            <div class="flex-fill"></div>
            <div *ngIf="settlementCounts && settlementCounts[0].retrying > 0">
                Verzenden...
            </div>
            <button mat-icon-button color="primary"
                    *ngIf="fcAfasProjectId.value && settlementCounts && (totalError > 0 || total > totalSend)"
                    (click)="$event.stopPropagation(); retrySendingSettlements(settlementCounts[0])">
                <mat-icon matTooltip="Alle niet verzonden en mislukte regels opnieuw verzenden"
                          class="fa-solid fa-repeat curpoint"
                          [class.fa-fade]="settlementCounts[0].retrying > 0"></mat-icon>
            </button>
            <button mat-raised-button color="primary" *ngIf="fcAfasProjectId.value && total !== totalSend" (click)="editProject(this.settlements)">
                Project aanpassen
            </button>
        </div>
        <table class="table table-hover table-last-col" *ngIf="!fcAfasProjectId.value">
            <tr>
                <th class="project">
                    Project
                </th>
                <th>

                </th>
                <th [style.width.px]="85" class="text-right">
                    Aantal
                </th>
                <th [style.width.px]="90" class="text-right">
                    Verstuurd
                </th>
                <th [style.width.px]="90" class="text-right">
                    Mislukt
                </th>
                <th>

                </th>
            </tr>
            <tr *ngFor="let count of settlementCounts" [class.error]="count.error_count > 0">
                <td matRipple class="curpoint" routerLink="/settlements/{{period}}/{{year}}/{{fcType.value}}/{{count.afas_project_id}}">
                    {{count.afas_project_id}}
                </td>
                <td>
                    {{count.project_name}}
                </td>
                <td class="text-right">
                    {{count.settlement_count}}
                </td>
                <td class="text-right" [class.error]="count.sent_count != count.settlement_count">
                    {{count.sent_count}}
                </td>
                <td>
                    {{count.error_count}}
                </td>
                <td>
                    <div class="d-flex">
                        <div>
                            <i (click)="$event.stopPropagation(); retrySendingSettlements(count)"
                               matTooltip="Alle niet verzonden en mislukte regels opnieuw verzenden"
                               *ngIf="count.settlement_count > count.sent_count"
                               class="fa-solid fa-repeat curpoint" [class.fa-fade]="count.retrying > 0"></i>
                        </div>
                    </div>
                    <small [style.font-size.px]="11" *ngIf="count.retrying > 0">
                        Verzenden...
                    </small>
                </td>
            </tr>
            <tr *ngIf="!settlementCounts?.length && settlementCounts !== null">
                <td colspan="5" class="text-center">
                    Geen Afas nacalculatieregels gevonden
                </td>
            </tr>
            <tr *ngIf="!settlementCounts?.length && settlementCounts === null">
                <td colspan="5" class="text-center">
                    Bezig met laden van boekingsregels...
                </td>
            </tr>
        </table>
        <table class="table table-last-col" *ngIf="fcAfasProjectId.value">
            <tr>
                <th colspan="2">
                    {{fcType.value === 'entities' ? 'Materieel' : 'Medewerker'}}
                </th>
                <th class="text-right">
                    Uren
                </th>
                <th [style.width.px]="125">
                    Boekdatum
                </th>
                <th>
                    Uurtype
                </th>
                <th>
                    Foutmelding
                </th>
                <th>
                    Verstuurd
                </th>
                <th>
                    Afas ID
                </th>
                <th>

                </th>
            </tr>
            <tr *ngFor="let settlement of settlements" [class.error]="settlement.afas_error">
                <td>
                    {{settlement?.entity?.afas_employee_id ?? settlement?.user?.afas_employee_id}}
                </td>
                <td>
                    {{settlement?.entity?.name ?? settlement?.user?.name}}
                </td>
                <td class="text-right">
                    {{settlement.minutes | minutes}}
                </td>
                <td class="curpoint" [routerLink]="['/check-hours',(settlement.bookdate | date : 'yyyy-MM-dd'), {realisationId:settlement.realisation_id }]">
                    {{settlement.bookdate | date : 'dd MMM yyyy'}}
                </td>
                <td>
                    {{settlement.hourtype_code}}
                </td>
                <td>
                    <div class="d-flex">
                        <div class="flex-fill mr-1">
                            <small>{{settlement.afas_error}}</small>
                        </div>
                        <div class="d-flex align-items-center">
                            <i (click)="retrySendingSettlement(settlement)"
                               matTooltip="Opnieuw proberen"
                               *ngIf="settlement.afas_error"
                               class="fa-solid fa-repeat mr-1 curpoint" [class.fa-fade]="settlement.sending"></i>
                        </div>
                    </div>
                    <div *ngIf="settlement.sending">
                        Verzenden...
                    </div>
                </td>
                <td>
                    {{settlement.sent_to_afas | date : 'd MMM yyyy HH:mm'}}
                </td>
                <td>
                    {{settlement.afas_ptrealization_id}}
                </td>
                <td>
                    <div class="d-flex">
                        <i class="fa-solid fa-pen curpoint mr-2" (click)="editProject([settlement])" *ngIf="!settlement.afas_ptrealization_id"></i>
                        <i class="fa-solid fa-trash curpoint" (click)="deleteSettlement(settlement)"></i>
                    </div>
                </td>
            </tr>
            <tr *ngIf="!settlements?.length && settlements !== null">
                <td colspan="8" class="text-center">
                    Geen Afas nacalculatieregels gevonden
                </td>
                <td>

                </td>
            </tr>
            <tr *ngIf="!settlements?.length && settlements === null">
                <td colspan="8" class="text-center">
                    Bezig met laden van boekingsregels...
                </td>
                <td>

                </td>
            </tr>
        </table>
    </div>
</div>
