import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {RealtimeService} from '../realtime/realtime.service';
import {TransportPlanningStatus} from '../../classes/transport-planning-status';
import {WebsocketService} from '../websocket/websocket.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {Utils} from '../../utils.class';

@Injectable({
    providedIn: 'root'
})
export class TransportPlanningStatusService extends RealtimeService<TransportPlanningStatus> {

    constructor(private apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.planningStatus);
    }

    itemInDaterange(planning: TransportPlanningStatus, fromDate: Date, toDate: Date) {
        const fromTime = Utils.setTime(new Date(fromDate), 0, 0).getTime();
        const toTime = Utils.setTime(new Date(toDate), 23, 59).getTime();
        return new Date(planning.date).getTime() > fromTime && (new Date(planning.date).getTime() < toTime);
    }

    setPlanningStatus(date: string, status: string) {
        return this.apiService.postCall$<TransportPlanningStatus>('planning/status', {
            date,
            status
        });
    }
}
