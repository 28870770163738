import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HourRegistrationComponent} from './hour-registration/hour-registration.component';
import {ProjectWeekReportConfigComponent} from './project-week-report-dialog/project-week-report-config.component';
import {VkmPlanningComponent} from './vkm/vkm-planning/vkm-planning.component';

@Injectable()
export class CanDeactivateForm<T extends HourRegistrationComponent | ProjectWeekReportConfigComponent | VkmPlanningComponent> implements CanDeactivate<T> {

    constructor() {
    }

    canDeactivate(
        component: T,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.openChangesBackActionCheck) {
            return component.openChangesBackActionCheck();
        }
        return true;
    }
}
