import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Toolbox} from '../classes/toolbox';
import {ToolboxService} from '../services/toolbox.service';
import {ViewPdfDialogComponent} from '../view-pdf-dialog/view-pdf-dialog.component';
import {ToolboxRead} from '../classes/toolbox-read';

@Component({
    selector: 'app-past-toolboxes-dialog',
    templateUrl: './past-toolboxes-dialog.component.html',
    styleUrls: ['./past-toolboxes-dialog.component.scss']
})
export class PastToolboxesDialogComponent extends CodaltComponent implements OnInit {

    toolboxes: Toolbox[];

    constructor(public dialogRef: MatDialogRef<PastToolboxesDialogComponent>,
                public dialog: MatDialog,
                public toolboxService: ToolboxService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.toolboxService.getToolboxes().subscribe(toolboxes => {
            this.toolboxes = toolboxes.data;
        }));
    }

    open(toolbox: Toolbox) {
        toolbox.read = new ToolboxRead();
        this.dialog.open(ViewPdfDialogComponent, {
            panelClass: 'toolbox-pdf-dialog',
            width: '99vw',
            maxWidth: '1300px',
            height: '99vh',
            minHeight: '500px',
            maxHeight: '99vh',
            disableClose: true,
            data: toolbox
        });
        this.dialogRef.close();
    }

    close() {
        this.dialogRef.close();
    }

}
