<div class="controls">
    <div class="header">
        <app-header
            mainTitle="Planning DvdS"
            subTitle="Project {{project?.afas_project_id}} aanpassen">
        </app-header>
    </div>
    <div class="indicator">

    </div>

    <div class="actions">
        <app-settings-menu></app-settings-menu>
    </div>

</div>
<div *ngIf="fgProject">
    <div class="row justify-content-center">
        <div class="col-md-6" [formGroup]="fgProject">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Uitvoerder</mat-label>
                <mat-select [formControl]="fgProject.controls.performer_id" placeholder="Uitvoerder">
                    <mat-option *ngFor="let executor of executors" [value]="executor.id">
                        {{executor.name}}
                    </mat-option>
                </mat-select>
                <mat-error [control]="fgProject.controls.performer_id"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Asfaltuitvoerder</mat-label>
                <mat-select [formControl]="fgProject.controls.asphalt_performer_id" placeholder="Asfaltuitvoerder">
                    <mat-option *ngFor="let asphaltExecutor of asphaltExecutors" [value]="asphaltExecutor.id">
                        {{asphaltExecutor.name}}
                    </mat-option>
                </mat-select>
                <mat-error [control]="fgProject.controls.asphalt_performer_id"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Projectleider</mat-label>
                <mat-select [formControl]="fgProject.controls.projectmanager_id" placeholder="Projectleider">
                    <mat-option *ngFor="let manager of projectManagers" [value]="manager.id">
                        {{manager.name}}
                    </mat-option>
                </mat-select>
                <mat-error [control]="fgProject.controls.projectmanager_id"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Locatie</mat-label>
                <input matInput [formControl]="fgProject.controls.location">
                <mat-error [control]="fgProject.controls.projectmanager_id"></mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6 d-flex justify-content-between mt-5">
            <button routerLink="/projects" mat-button>Terug</button>

            <button (click)="save()" [disabled]="saving" color="primary" mat-raised-button>
                Opslaan <i *ngIf="saving" class="fas fa-circle-notch fa-spin"></i>
            </button>
        </div>
    </div>
</div>
