import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {debounceTime} from 'rxjs/operators';
import {NgSelectComponent} from '@ng-select/ng-select';
import {fromEvent} from 'rxjs';

@Component({
    selector: 'app-hire-name',
    templateUrl: './hire-name.component.html',
    styleUrls: ['./hire-name.component.scss']
})
export class HireNameComponent extends CodaltComponent implements OnInit {

    @ViewChild('selection') selection: NgSelectComponent;

    focus = false;
    search = new FormControl();
    select = new FormControl();

    @Input() fc: FormControl;
    @Input() hiringAutocompleteOptions: string[];

    constructor(private eRef: ElementRef,
                private zone: NgZone,) {
        super();
        this.zone.runOutsideAngular(() => {
            this.subscriptions.add(fromEvent(document, 'click')
                .subscribe((e: MouseEvent) => {
                    if (!this.eRef.nativeElement.contains(e.target)) {
                        if (this.focus) {
                            this.zone.run(() => {
                                this.focus = false;
                            });
                        }
                    }
                }));
        });
    }

    ngOnInit(): void {
        if (this.fc.disabled) {
            this.search.disable();
        }
        this.search.setValue(this.fc.value);
        this.subscriptions.add(this.fc.valueChanges.subscribe(value => {
            this.search.setValue(value, {emitEvent: false});
        }));
        this.subscriptions.add(this.search.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.fc.setValue(value);
            this.fc.markAsDirty();
        }));
        this.subscriptions.add(this.select.valueChanges.subscribe(value => {
            this.search.setValue(value[0]);
            this.focus = false;
        }));
    }

}
