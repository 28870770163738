import {Injectable} from '@angular/core';

import {ApiService} from '../api/api.service';
import {User} from '../../classes/user.class';
import {LocalStorage} from '../../storage.class';
import {RealtimeService} from '../realtime/realtime.service';
import {RealtimeType} from '../realtime/realtime-type.enum';
import {WebsocketService} from '../websocket/websocket.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Group} from '../../classes/group.class';
import {Utils} from '../../utils.class';

export enum UserType {
    ADMIN = 'ADMIN',
    WORKPLANNER = 'WORKPLANNER',
    PROJECTMANAGER = 'PROJECTMANAGER',
    USER = 'USER',
    EMPLOYEE = 'EMPLOYEE',
    ASPHALTCOORDINATOR = 'ASPHALTCOORDINATOR',
    EXECUTOR = 'EXECUTOR',
    ASPHALTEXECUTOR = 'ASPHALTEXECUTOR',
    TRUCK_DRIVER = 'TRUCK_DRIVER',
    HOUR_REGISTER = 'HOUR_REGISTER',
    GENERAL_HOUR_CHECK = 'GENERAL_HOUR_CHECK',
    EMPLOYEE_PLANNING = 'EMPLOYEE_PLANNING',
    ADD_REALISATION = 'ADD_REALISATION',
    DYNAMIC_PERFORMER = 'DYNAMIC_PERFORMER',
    SETTLEMENT = 'SETTLEMENT',
    TOOLBOX = 'TOOLBOX',
    TRANSPORT_READ_ONLY = 'TRANSPORT_READ_ONLY',
    VKMEXECUTOR = 'VKMEXECUTOR',
    VKMMECHANIC = 'VKMMECHANIC'
}

export const UserGroups = new Map<string, string>([
    [UserType.ADMIN, 'Admin'],
    [UserType.WORKPLANNER, 'Materieelplanner'],
    [UserType.PROJECTMANAGER, 'Projectleider'],
    [UserType.EXECUTOR, 'Uitvoerder'],
    [UserType.ASPHALTEXECUTOR, 'Asfaltuitvoerder'],
    [UserType.ASPHALTCOORDINATOR, 'Asfaltcoordinator'],
    [UserType.USER, 'Gebruiker'],
    [UserType.EMPLOYEE, 'Medewerker'],
    [UserType.TRUCK_DRIVER, 'Chauffeur'],
    [UserType.HOUR_REGISTER, 'Urenregistratie'],
    [UserType.GENERAL_HOUR_CHECK, 'Algemene urencontrole'],
    [UserType.EMPLOYEE_PLANNING, 'Personeelsplanning'],
    [UserType.ADD_REALISATION, 'Urencontrole project toevoegen'],
    [UserType.DYNAMIC_PERFORMER, 'Urencontrole uitvoerder selecteren'],
    [UserType.SETTLEMENT, 'Periodeverrekening'],
    [UserType.TOOLBOX, 'Toolbox beheren'],
    [UserType.TRANSPORT_READ_ONLY, 'Transportplanning alleen lezen'],
    [UserType.VKMEXECUTOR, 'Vkm Uitvoerder'],
    [UserType.VKMMECHANIC, 'Vkm Monteur']
]);


@Injectable()
export class UserService extends RealtimeService<User> {
    /**
     * If the key user group is requested, which groups have also access
     * PLEASE keep in sync with the list in User.php
     */
    public static userRightMap = new Map<UserType, UserType[]>([
        [UserType.ADMIN, [UserType.ADMIN]],
        [UserType.WORKPLANNER, [UserType.WORKPLANNER, UserType.ADMIN]],
        [UserType.PROJECTMANAGER, [UserType.ADMIN, UserType.PROJECTMANAGER, UserType.WORKPLANNER, UserType.ASPHALTCOORDINATOR]],
        [UserType.EXECUTOR, [UserType.ADMIN, UserType.EXECUTOR, UserType.ASPHALTEXECUTOR]],
        [UserType.ASPHALTEXECUTOR, [UserType.ADMIN, UserType.ASPHALTEXECUTOR]],
        [UserType.ASPHALTCOORDINATOR, [UserType.ADMIN, UserType.ASPHALTCOORDINATOR]],
        [UserType.HOUR_REGISTER, [UserType.HOUR_REGISTER, UserType.ADMIN, UserType.ASPHALTEXECUTOR, UserType.EXECUTOR]],
        [UserType.GENERAL_HOUR_CHECK, [UserType.GENERAL_HOUR_CHECK]],
        [UserType.DYNAMIC_PERFORMER, [UserType.GENERAL_HOUR_CHECK, UserType.DYNAMIC_PERFORMER]],
        [UserType.USER, [UserType.ADMIN, UserType.USER, UserType.WORKPLANNER, UserType.PROJECTMANAGER, UserType.EXECUTOR, UserType.ASPHALTCOORDINATOR, UserType.ASPHALTEXECUTOR]],
        [UserType.EMPLOYEE_PLANNING, [UserType.EMPLOYEE_PLANNING, UserType.WORKPLANNER]],
        [UserType.ADD_REALISATION, [UserType.ADD_REALISATION]],
        [UserType.TRUCK_DRIVER, [UserType.TRUCK_DRIVER]],
        [UserType.SETTLEMENT, [UserType.SETTLEMENT, UserType.ADMIN]],
        [UserType.TOOLBOX, [UserType.TOOLBOX, UserType.ADMIN, UserType.PROJECTMANAGER]],
        [UserType.TRANSPORT_READ_ONLY, [UserType.TRANSPORT_READ_ONLY]],
        [UserType.VKMEXECUTOR, [UserType.VKMEXECUTOR, UserType.ADMIN]],
        [UserType.VKMMECHANIC, [UserType.VKMMECHANIC, UserType.VKMEXECUTOR, UserType.ADMIN]],
    ]);

    // PLEASE keep in sync with the list in User.php
    static officeWorkerFunctions = [
        'Administratief Medewerker',
        'Calculator',
        'Communicatie Manager',
        'Directeur',
        'HRM',
        'Planner',
        'Finance',
        'Projectleider',
        'Projectcoordinator',
        'Werkvoorbereider',
        'Hoofd Finance & Control',
        'Hoofd Finance & Controle'
    ];

    constructor(protected apiService: ApiService,
                protected websocketService: WebsocketService) {
        super(websocketService, RealtimeType.users);
    }

    public static userHasRights(group, user?: User) {
        if (!user) {
            user = LocalStorage.getUser();
        }
        const groups = UserService.userRightMap.get(group);
        if (user?.groups?.find(g => groups?.indexOf(g.group as UserType) !== -1)) {
            return true;
        }
        return false;
    }

    public getGroups() {
        return this.apiService.getCall<Group[]>(`${this.type}/groups`);
    }

    public itemInDaterange(user: User, fromDate: Date, toDate: Date) {
        const fromTime = fromDate.getTime();
        return !user.enddate || Utils.getTimeOrNull(user.enddate) >= fromTime;
    }

    userFunctions(officeWorkers = true) {
        return this.apiService.getCall$<string[]>(`${this.type}/functions`, {officeWorkers});
    }

    public saveUser(user: User): Promise<User> {
        return this.apiService.postCall(this.type, user);
    }

    public getUser(userId: number) {
        return this.apiService.getCall$<User>(`${this.type}/${userId}`);
    }

    public getByType(code: UserType): Observable<User[]> {
        return this.getList().pipe(map(users => users.filter(user => user.groups.find(g => (g.group as UserType) === code))));
    }

    public getFilteredByType(fromDate: Date, toDate: Date, code: UserType): Observable<User[]> {
        return this.getFilteredList(fromDate, toDate).pipe(map(users => users.filter(user => user.groups.find(g => (g.group as UserType) === code))));
    }

    public getFilteredList(fromDate: Date, toDate: Date, withTrashed = false, officeWorkers = true): Observable<User[]> {
        return super.getFilteredList(fromDate, toDate).pipe(map(users => this.filterTrashedOfficeWorkers(users, withTrashed, officeWorkers)));
    }

    private filterTrashedOfficeWorkers(users: User[], withTrashed = false, officeWorkers = true, withZzp = true) {
        return users
            .filter(user => withTrashed || user.deleted_at === null)
            .filter(user => withZzp || !(user.afas_employee_id?.substring(0, 1) === 'Z'))
            .filter(user => officeWorkers || ((!UserService.officeWorkerFunctions.includes(user.function) || UserService.userHasRights(UserType.TRUCK_DRIVER, user)) && user.function));
    }

    public getList(withTrashed = false, officeWorkers = true, withZzp = true): Observable<User[]> {
        return super.getList().pipe(map(users => this.filterTrashedOfficeWorkers(users, withTrashed, officeWorkers, withZzp)));
    }

    newUserPin(userId) {
        return this.apiService.getCall$<string>(`${this.type}/new-pin/${userId}`);
    }

    public getMap(withTrashed = false): Observable<Map<number, User>> {
        return this.getList(withTrashed).pipe(map(users => this.genEntitiesMap(users)));
    }

    public deleteUser(userId: number): Promise<object> {
        return this.apiService.deleteCall(`${this.type}/${userId}`);
    }

    private genEntitiesMap(usersList: User[]): Map<number, User> {
        const map = new Map<number, User>();
        usersList.forEach(user => {
            map.set(user.id, user);
        });
        return map;
    }

}

