import {Planning} from './planning.class';
import {User} from './user.class';
import {PlanningHasEntity} from './planning-has-entity.class';
import {UserPlanning} from './user-planning';
import {Entity} from './entity.class';
import {Settlement} from './settlement.class';
import {Project} from './project.class';
import {Leave} from './leave';

export class Realisation {
    id?: number;
    bookdate: Date;
    begindate: Date;
    enddate: Date;
    travel?: boolean;
    user_id: number;
    parent_realisation_id?: number;
    user?: User;
    entity_id?: number;
    entity?: Entity;
    planning_id: number;
    planning?: Planning;
    planning_has_entity_id?: number;
    planning_has?: PlanningHasEntity;
    user_planning?: UserPlanning;
    user_planning_id?: number;
    afas_project_id?: string;
    adjusted?: Date;
    submitted?: Date;
    confirmed?: Date;
    approved?: Date;
    removed?: Date;
    skip_performer?: boolean;
    paid?: Date;
    kms?: number;
    pause?: number;
    pause_adjusted?: boolean;
    saved_by_id?: number;
    comment_user?: string;
    comment_user_approved?: string;
    comment_user_approved_handled?: Date;
    comment_manager?: string;
    created_at?: Date;
    updated_at?: Date;
    deleted_at?: Date;

    dayOff?: boolean;
    dayInvisible?: boolean;
    dayOffReason?: string;

    hourtype?: RealisationHourtype;
    text_prepend?: string;
    text_append?: string;
    traveltime?: number;

    hiring_name?: string;

    settlements?: Settlement[];
    project?: Project;

    leave_id?: number;
    leave?: Leave;
}

export enum RealisationHourtype {
    worktime = 'worktime',
    driving_to = 'driving_to',
    driving_back = 'driving_back',
    bank_holiday = 'bank_holiday',
    travel_to = 'travel_to',
    travel_back = 'travel_back',
    frost = 'frost',
    day_off = 'day_off',
    illness = 'illness',
    sleep = 'sleep',
    education = 'education',
    short_absence = 'short_absence',
    paid_leave = 'paid_leave',
    time_for_time = 'time_for_time',
    special_leave = 'special_leave',
    additional_parental = 'additional_parental',
    zzp_leave = 'zzp_leave'
}
