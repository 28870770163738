<div *ngIf="focus" class="complete">
    <mat-selection-list [multiple]="false" [formControl]="select" #selection class="p-0">
        <mat-list-option [value]="hire"
                         *ngFor="let hire of hiringAutocompleteOptions | search : null : search?.value">
            <div class="list-item">
                {{hire}}
            </div>
        </mat-list-option>
    </mat-selection-list>
</div>
<input matInput placeholder="Inhuurnaam" type="text" [formControl]="search"
       (focusin)="focus = true"
       (click)="focus = true"
       (keydown.arrowDown)="selection.focus()">
