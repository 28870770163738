import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {Utils} from '../utils.class';
import {formatDate} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SettlementCounts, SettlementsService} from '../services/settlements.service';
import {FormControl} from '@angular/forms';
import {combineLatest, concat} from 'rxjs';
import {Settlement} from '../classes/settlement.class';
import {startWith} from 'rxjs/operators';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {MinutesPipe} from '../pipes/minutes.pipe';
import {ApiResponse} from '../services/api/api.service';
import {MatDialog} from '@angular/material/dialog';
import {EditSettlementProjectDialogComponent} from './edit-settlement-project-dialog/edit-settlement-project-dialog.component';

@Component({
    selector: 'not-send-settlements',
    templateUrl: './not-send-settlements.component.html',
    styleUrls: ['./not-send-settlements.component.scss']
})
export class NotSendSettlementsComponent extends CodaltComponent implements OnInit {

    beginDate: Date;
    endDate: Date;
    today = new Date();

    year: number;
    period: number;

    settlementCounts: SettlementCounts[];
    settlements: Settlement[];

    fcAfasProjectId = new FormControl<string>(null);
    fcType = new FormControl<'entities' | 'users'>('entities');

    total: number;
    totalSend: number;
    totalError: number;

    constructor(private activatedRoute: ActivatedRoute,
                private settlementsService: SettlementsService,
                private confirmDialog: ConfirmDialogService,
                private dialog: MatDialog,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest(
            this.activatedRoute.params,
            this.fcAfasProjectId.valueChanges.pipe(startWith(this.fcAfasProjectId.value)),
        ).subscribe(([params]) => {
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            const year = monday.getFullYear();
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            let today = new Date();
            if (params.period) {
                const period = +params.period;
                today = new Date(monday);
                today.setDate(today.getDate() + (28 * (period - 1)));
            }
            let currentWeek = +formatDate(today, 'w', 'nl');
            if (today.getDay() === 0) {
                currentWeek -= 1;
            }
            currentWeek--;
            const periodStartWeek = (currentWeek - (currentWeek % 4));
            this.beginDate = new Date(monday);
            this.beginDate.setDate(this.beginDate.getDate() + (periodStartWeek * 7));
            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 28);
            this.period = Math.floor((currentWeek / 4) + 1);
            this.year = this.period > 1 ? this.beginDate.getFullYear() : this.endDate.getFullYear();
            if (params.project) {
                this.fcAfasProjectId.setValue(params.project, {emitEvent: false});
            }
            if (params.type) {
                this.fcType.setValue(params.type, {emitEvent: false});
            }
            this.getData();
        }));
        this.subscriptions.add(this.fcType.valueChanges.subscribe(type => {
            let url = `/settlements/${this.period}/${this.year}/${type}`;
            if (this.fcAfasProjectId.value) {
                url += `/${this.fcAfasProjectId.value}`;
            }
            this.router.navigateByUrl(url);
        }));
    }

    editProject(settlements: Settlement[]) {
        const ref = this.dialog.open(EditSettlementProjectDialogComponent, {
            maxWidth: '450px',
            width: '100vw',
            maxHeight: '100%',
            disableClose: false,
            panelClass: 'comment-edit-dialog',
            data: {
                settlements,
                currentProjectId: this.fcAfasProjectId.value
            }
        });
        this.subscriptions.add(ref.afterClosed().subscribe((resultingSettlements: Settlement[]) => {
            this.settlements.forEach(settlement => {
                const s = resultingSettlements.find(s => s.id === settlement.id);
                if (s) {
                    Object.assign(settlement, s);
                }
            });
            this.settlements = this.settlements.filter(s => s.afas_project_id === this.fcAfasProjectId.value);
            if (resultingSettlements.length) {
                this.confirmDialog.confirm(
                    'Nacalculatieregels verplaatst',
                    `Er zijn <b>${resultingSettlements.length}</b> regels verplaatst naar <b>${resultingSettlements[0].afas_project_id}</b>.
                <br>Wilt u dit project openen?`,
                    'Open Project', 'Sluiten').then(() => {
                    this.router.navigateByUrl(`/settlements/${this.period}/${this.year}/${this.fcType.value}/${resultingSettlements[0].afas_project_id}`);
                }, () => {

                });
            } else {
                this.confirmDialog.confirm(
                    'Geen nacalculatieregels',
                    `Er zijn <b>geen</b> regels verplaatst.`,
                    'Sluiten', null).then(() => {
                }, () => {

                });
            }

        }));
    }

    getData() {
        this.total = null;
        this.settlementCounts = null;
        this.settlements = null;
        if (this.fcAfasProjectId.value) {
            this.subscriptions.add(this.settlementsService.getSettlements(this.beginDate, this.endDate, this.fcType.value, this.fcAfasProjectId.value).subscribe(settlements => {
                this.settlements = settlements.data;
            }));
        }
        this.subscriptions.add(this.settlementsService.getSettlementCounts(this.beginDate, this.endDate, this.fcType.value, this.fcAfasProjectId.value).subscribe(counts => {
            counts.data.forEach(count => {
                count.error_count = +count.error_count;
            });
            this.settlementCounts = counts.data;
            this.total = this.settlementCounts.map(s => s.settlement_count).reduce((a, b) => a + b, 0);
            this.totalSend = this.settlementCounts.map(s => s.sent_count).reduce((a, b) => a + b, 0);
            this.totalError = this.settlementCounts.map(s => s.error_count).reduce((a, b) => (+a) + (+b), 0);
        }));
    }

    deleteSettlement(settlement: Settlement) {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        if (settlement.entity_id && Utils.getTimeOrNull(settlement.bookdate) > oneWeekAgo.getTime()) {
            this.confirmDialog.confirm('Verwijderen niet mogelijk',
                'Deze nacalculatieregel kan niet verwijderd worden omdat deze nog niet ouder is dan 1 week',
                'Sluiten', null).then(() => {
            }, () => {

            });
            return;
        }
        this.confirmDialog.confirm('Verwijderen',
            `Weet u zeker dat u deze nacalculatieregel:<br>
            <small><i>
            ${settlement.user_id ? 'Materieel' : 'Gebruiker'}: ${settlement?.entity?.name ?? settlement?.user?.name}<br>
            Project: ${settlement.afas_project_id}<br>
            Boekdatum: ${formatDate(settlement.bookdate as any, 'dd MMM yyyy', 'nl')} <br>
            Uren: ${(new MinutesPipe()).transform(settlement.minutes)} <br>
            ${settlement.afas_ptrealization_id ? `Afas ID: ${settlement.afas_ptrealization_id}<br>` : ''}
            </i></small>
            wilt verwijderen?
            <br><br>
            <small>${settlement.user_id ? `Let op: nacalculatieregel wordt opnieuw aangemaakt indien de periodeverrekening opnieuw wordt uitgevoerd!</small><br>` : ''}`, 'Verwijderen', 'Behouden').then(() => {
            this.subscriptions.add(this.settlementsService.deleteSettlements(settlement.id).subscribe(() => {
                this.settlements.splice(this.settlements.indexOf(settlement), 1);
            }, () => {

            }));
        }, () => {
        });

    }

    retrySendingSettlements(count: SettlementCounts) {
        count.retrying = 1;
        this.subscriptions.add(this.settlementsService.getSettlements(this.beginDate, this.endDate, this.fcType.value, count.afas_project_id).subscribe(settlements => {
            const calls$ = [];
            settlements.data.filter(s => s.afas_project_id == count.afas_project_id && !s.afas_ptrealization_id).forEach(settlement => {
                calls$.push(this.settlementsService.sendSingle(settlement));
            });
            count.retrying = calls$.length;
            this.subscriptions.add(concat(...calls$).subscribe((resultSettlement: ApiResponse<Settlement>) => {
                if (this.settlements) {
                    Object.assign(this.settlements.find(s => s.id === resultSettlement.data.id), resultSettlement.data);
                }

                const oldSettlement = settlements.data.find(s => s.id === resultSettlement.data.id);
                count.retrying--;
                if (!resultSettlement.data.afas_error && !!oldSettlement.afas_error) {
                    count.error_count--;
                }
            }, error => {
                count.retrying--;
                console.error(error);
            }));
        }));
    }

    retrySendingSettlement(settlement: Settlement) {
        settlement.sending = true;
        this.subscriptions.add(this.settlementsService.sendSingle(settlement).subscribe(resultSettlement => {
            settlement.sending = false;
            Object.assign(settlement, resultSettlement.data);
        }, () => {
            settlement.sending = false;
        }));
    }

    next() {
        let period = this.period + 1;
        let year = this.year;
        if (period > 13) {
            period = 1;
            year += 1;
        }

        this.router.navigateByUrl(`settlements/${period}/${year}/${this.fcType.value}`);
    }

    prev() {
        let period = this.period - 1;
        let year = this.year;
        if (period < 1) {
            period = 13;
            year -= 1;
        }
        this.router.navigateByUrl(`settlements/${period}/${year}/${this.fcType.value}`);
    }

}
