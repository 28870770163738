import {Component, OnInit} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../../services/project.service';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Project} from '../../classes/project.class';
import {User} from '../../classes/user.class';
import {UserService, UserType} from '../../services/user/user.service';
import {Utils} from '../../utils.class';

@Component({
    selector: 'app-project-edit',
    templateUrl: './project-edit.component.html',
    styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent extends CodaltComponent implements OnInit {

    fgProject: FormGroup<ControlsOf<fgProject>>;
    project: Project;

    executors: User[];
    asphaltExecutors: User[];
    projectManagers: User[];

    saving = false;

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userService: UserService,
                private projectService: ProjectService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => {
            this.executors = users;
        }));
        this.subscriptions.add(this.userService.getByType(UserType.ASPHALTEXECUTOR).subscribe(users => this.asphaltExecutors = users));
        this.subscriptions.add(this.userService.getByType(UserType.PROJECTMANAGER).subscribe(users => this.projectManagers = users));

        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { id }) => {
            this.projectService.getProject(params.id).subscribe(project => {
                this.project = project.data;
                this.fgProject = new FormGroup<ControlsOf<fgProject>>({
                    asphalt_performer_id: new FormControl(project.data.asphalt_performer_id),
                    projectmanager_id: new FormControl(project.data.projectmanager_id),
                    performer_id: new FormControl(project.data.performer_id),
                    location: new FormControl(project.data.location)
                });
            });
        }));
    }

    save() {
        Utils.triggerValidationP(this.fgProject).then(() => {
            Object.assign(this.project, this.fgProject.value);
            this.projectService.saveProject(this.project).subscribe(() => {
                this.router.navigateByUrl('/projects');
            });
        }, () => {

        });
    }

}

export interface fgProject {
    projectmanager_id: number;
    performer_id: number;
    asphalt_performer_id: number;
    location: string;
}
