import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RequestRestorePasswordComponent} from './auth/request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from './auth/restore-password/restore-password.component';
import {PlanningOverviewComponent} from './planning-overview/planning-overview.component';
import {AsphaltOrderListComponent} from './asphalt-order-list/asphalt-order-list.component';
import {IsAuthenticated} from './is-authenticated';
import {IsNotAuthenticated} from './is-not-authenticated';
import {PlanningDetailPrintComponent} from './planning-detail-print/planning-detail-print.component';
import {TransportPlanningComponent} from './transport-planning/transport-planning.component';
import {ProfileComponent} from './profile/profile.component';
import {HourRegistrationComponent} from './hour-registration/hour-registration.component';
import {WeekScheduleComponent} from './week-schedule/week-schedule.component';
import {DayDetailComponent} from './week-schedule/day-detail/day-detail.component';
import {CheckHoursComponent} from './check-hours/check-hours.component';
import {LogoutComponent} from './logout/logout.component';
import {CanDeactivateForm} from './can-deactivate-form';
import {LinkLoginComponent} from './auth/link-login/link-login.component';
import {EmployeePlanningComponent} from './employee-planning/employee-planning.component';
import {DayCheckRealisationsComponent} from './day-check-realisations/day-check-realisations.component';
import {SettlementsSendComponent} from './settlements-send/settlements-send.component';
import {SettlementComponent} from './settlement/settlement.component';
import {CanDeactivateEmployeePlanning} from './employee-planning/can-deactivate-employee-planning';
import {ProjectWeekReportConfigComponent} from './project-week-report-dialog/project-week-report-config.component';
import {ProjectDayReportComponent} from './project-day-report/project-day-report.component';
import {LeaveComponent} from './leave/leave.component';
import {LeaveEditComponent} from './leave/leave-edit/leave-edit.component';
import {SettlementSendAllComponent} from './settlement-send-all/settlement-send-all.component';
import {WeekCheckRealisationsComponent} from './week-check-realisations/week-check-realisations.component';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectEditComponent} from './projects/project-edit/project-edit.component';
import {VkmWorkordersComponent} from './vkm/vkm-workorders/vkm-workorders.component';
import {VkmDayPlanningComponent} from './vkm/vkm-projects/vkm-day-planning.component';
import {VkmPlanningComponent} from './vkm/vkm-planning/vkm-planning.component';
import {NotSendSettlementsComponent} from './not-send-entities/not-send-settlements.component';

const routes: Routes = [
    {path: '', component: PlanningOverviewComponent, canActivate: [IsAuthenticated]},
    {path: 'profiel', component: ProfileComponent},
    {path: 'print/:date/:token', component: LinkLoginComponent},
    {path: 'print/:date', component: PlanningOverviewComponent},
    {path: 'print', component: PlanningOverviewComponent},
    {path: 'transport-planning/:date', component: TransportPlanningComponent, canActivate: [IsAuthenticated]},
    {path: 'boekingsregels', component: SettlementsSendComponent, canActivate: [IsAuthenticated]},
    {path: 'boekingsregels/:date', component: SettlementsSendComponent, canActivate: [IsAuthenticated]},
    {path: 'transport-planning', component: TransportPlanningComponent, canActivate: [IsAuthenticated]},
    {path: 'personeelsplanning', component: EmployeePlanningComponent, canDeactivate: [CanDeactivateEmployeePlanning], canActivate: [IsAuthenticated]},
    {
        path: 'personeelsplanning/:period/:year',
        component: EmployeePlanningComponent,
        canDeactivate: [CanDeactivateEmployeePlanning],
        canActivate: [IsAuthenticated]
    },
    {path: 'dagcontrole', component: DayCheckRealisationsComponent, canActivate: [IsAuthenticated]},
    {path: 'dagcontrole/:date', component: DayCheckRealisationsComponent, canActivate: [IsAuthenticated]},
    {path: 'weekcontrole', component: WeekCheckRealisationsComponent, canActivate: [IsAuthenticated]},
    {path: 'weekcontrole/:year/:week', component: WeekCheckRealisationsComponent, canActivate: [IsAuthenticated]},
    {path: 'planning-detail/:id', component: PlanningDetailPrintComponent},
    {path: 'login', component: LinkLoginComponent, canActivate: [IsNotAuthenticated]},
    {path: 'wachtwoord-vergeten', component: RequestRestorePasswordComponent, canActivate: [IsNotAuthenticated]},
    {path: 'forgot-password', component: RequestRestorePasswordComponent, canActivate: [IsNotAuthenticated]},
    {path: 'restore-password/:token', component: RestorePasswordComponent},
    {path: 'asphalt-order-list/:token/:millId/:date', component: LinkLoginComponent},
    {path: 'asphalt-order-list/:millId/:date', component: AsphaltOrderListComponent, canActivate: [IsAuthenticated]},
    {path: 'hours', component: HourRegistrationComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: 'hours/:date', component: HourRegistrationComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: 'hours/:date/:userId', component: HourRegistrationComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: 'weekschedule', component: WeekScheduleComponent, canActivate: [IsAuthenticated]},
    {path: 'weekschedule/:date', component: DayDetailComponent, canActivate: [IsAuthenticated]},
    {path: 'check-hours', component: CheckHoursComponent, canActivate: [IsAuthenticated], canDeactivate: []},
    {path: 'check-hours/:date', component: CheckHoursComponent, canActivate: [IsAuthenticated], canDeactivate: []},
    {path: 'boekingsregels-versturen', component: SettlementSendAllComponent, canActivate: [IsAuthenticated]},
    {path: 'settlements', component: NotSendSettlementsComponent, canActivate: [IsAuthenticated]},
    {path: 'settlements/:period/:year', component: NotSendSettlementsComponent, canActivate: [IsAuthenticated]},
    {path: 'settlements/:period/:year/:type', component: NotSendSettlementsComponent, canActivate: [IsAuthenticated]},
    {path: 'settlements/:period/:year/:type/:project', component: NotSendSettlementsComponent, canActivate: [IsAuthenticated]},
    {path: 'periode/:type', component: SettlementComponent, canActivate: [IsAuthenticated]},
    {path: 'periode/:type/:userId', component: SettlementComponent, canActivate: [IsAuthenticated]},
    {path: 'periode/:type/:userId/:period', component: SettlementComponent, canActivate: [IsAuthenticated]},
    {path: 'periode/:type/:userId/:period/:year', component: SettlementComponent, canActivate: [IsAuthenticated]},
    {path: 'project-dagrapport/:project/:date', component: ProjectDayReportComponent, canActivate: [IsAuthenticated]},
    {path: 'project-dagrapport/:project', component: ProjectDayReportComponent, canActivate: [IsAuthenticated]},
    {
        path: 'project-rapport/:project/:period/:year/:onlyTotals/:noHours/:noComments/:noAsphalt',
        component: ProjectWeekReportConfigComponent,
        canActivate: [IsAuthenticated],
        canDeactivate: [CanDeactivateForm]
    },
    {
        path: 'project-rapport/:project/:period/:year',
        component: ProjectWeekReportConfigComponent,
        canActivate: [IsAuthenticated],
        canDeactivate: [CanDeactivateForm]
    },
    {path: 'project-rapport/:project', component: ProjectWeekReportConfigComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: 'project-rapport', component: ProjectWeekReportConfigComponent, canActivate: [IsAuthenticated]},
    {path: 'leave', component: LeaveComponent, canActivate: [IsAuthenticated]},
    {path: 'leave/:id', component: LeaveEditComponent, canActivate: [IsAuthenticated]},
    {path: 'projects', component: ProjectsComponent, canActivate: [IsAuthenticated]},
    {path: 'projects/:id', component: ProjectEditComponent, canActivate: [IsAuthenticated]},
    {path: 'vkm/:id', component: VkmPlanningComponent, canActivate: [IsAuthenticated], canDeactivate: [CanDeactivateForm]},
    {path: 'vkm-werkorders', component: VkmWorkordersComponent, canActivate: [IsAuthenticated]},
    {path: 'vkm-planning', component: VkmDayPlanningComponent, canActivate: [IsAuthenticated]},
    {path: 'vkm-planning/:date', component: VkmDayPlanningComponent, canActivate: [IsAuthenticated]},
    {path: 'logout', component: LogoutComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule],
    providers: [IsAuthenticated, IsNotAuthenticated, CanDeactivateEmployeePlanning]
})
export class AppRoutingModule {
}
