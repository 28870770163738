import {Pipe, PipeTransform} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FgPlanningHas} from './vkm-planning.component';
import {SortTabsPipe} from './sort-tabs.pipe';

@Pipe({
    name: 'nameTabs'
})
export class NameTabsPipe implements PipeTransform {

    transform(planning: FormGroup<FgPlanningHas>, set: FormGroup<FgPlanningHas>[], ...args: unknown[]): unknown {
        const sorted = (new SortTabsPipe()).transform(set).filter(p => p.value.type === planning?.value.type);
        return `${planning?.value.type} ${sorted.indexOf(planning) + 1}`;
    }

}
