<div class="toolbox-title">
    Toolbox {{toolbox?.begindate | date : 'EEEE d MMMM'}}
    <button mat-icon-button class="hide" (click)="close()" [disabled]="!showClose">
        <mat-icon *ngIf="showClose" class="fas fa-times"></mat-icon>
        <span *ngIf="!showClose">
            {{secToClose}}
        </span>
    </button>
</div>
<div *ngIf="!pdfUrl" class="d-flex align-items-center justify-content-center h-100">
    <span>Er is momenteel geen toolbox</span>
</div>
<iframe *ngIf="pdfUrl" [src]="pdfUrl | safeResourceUrl"></iframe>
