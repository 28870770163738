import {Component, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {CodaltComponent} from '../../codalt.component';
import {Utils} from '../../utils.class';
import {RealisationService} from '../../services/realisation.service';
import {Realisation, RealisationHourtype} from '../../classes/realisation';
import {User} from '../../classes/user.class';
import {ProjectService} from '../../services/project.service';
import {ProjectComment} from '../../classes/project-comment';
import {Project} from '../../classes/project.class';
import {AsphaltUsedListComponent} from '../../project-day-report/asphalt-used-list/asphalt-used-list.component';
import {ProjectUsedAsphalt} from '../../classes/project-used-asphalt.class';
import {AsphaltService} from '../../services/asphalt/asphalt.service';
import {LocalStorage} from '../../storage.class';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {combineLatest, Subscription} from 'rxjs';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Settings} from '../../settings.class';
import {EditWeekCommentDialogComponent} from './edit-week-comment-dialog/edit-week-comment-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {WeekCommentHistoryDialogComponent} from './week-comment-history-dialog/week-comment-history-dialog.component';

@Component({
    selector: 'app-project-week-report',
    templateUrl: './project-week-report.component.html',
    styleUrls: ['./project-week-report.component.scss']
})
export class ProjectWeekReportComponent extends CodaltComponent implements OnInit, OnChanges {

    @Input() @HostBinding('class.print') print = false;
    @Input() beginDate: Date;
    @Input() endDate: Date;
    @Input() projectId: string;
    @Input() onlyWeekTotals = false;
    @Input() noHours = false;
    @Input() noComments = false;
    @Input() noAsphalt = false;

    year: number;
    period: number;

    realisations: Realisation[];
    employees: User[];

    weekComment: ProjectComment;
    initialContent: string;

    saving = false;
    saved = false;
    fgWeekComment: FormGroup<ControlsOf<FormGroupProjectComment>>;
    projectComments: ProjectComment[];
    days: {
        ownComment: FormGroup<ControlsOf<FormGroupProjectComment>>,
        commentsAsphalt: ProjectComment[],
        comments: ProjectComment[],
        aa: string,
        date: Date,
        asphalt: ProjectUsedAsphalt[],
        adhesive: ProjectUsedAsphalt[],
    }[] = [];
    @Input() project: Project;
    @ViewChild('asphaltUsedList') asphaltUsedListComponent: AsphaltUsedListComponent;
    @ViewChild('asphaltAdhesiveList') asphaltAdhesiveListComponent: AsphaltUsedListComponent;
    weekSubscriptions: Subscription;

    faAllFcDayComment: FormArray<ControlsOf<FormGroupProjectComment>>;


    puppeteerReady = {
        commentsReady: false,
        entityHoursReady: false,
        userHoursReady: false
    };

    constructor(
        private projectService: ProjectService,
        private asphaltService: AsphaltService,
        private realisationService: RealisationService,
        private dialog: MatDialog,
        private confirmDialog: ConfirmDialogService,
        private renderer2: Renderer2) {
        super();
    }

    ngOnInit(): void {
        this.puppeteerReady.commentsReady = this.noComments;
        this.puppeteerReady.userHoursReady = this.noHours;
        this.puppeteerReady.entityHoursReady = this.noHours;
        setTimeout(() => {
            this.checkChanges();
        }, 2500);
    }

    readyForPuppeteer(ready: string) {
        this.puppeteerReady[ready] = true;

        let allReady = true;
        for (const property in this.puppeteerReady) {
            if (!this.puppeteerReady[property]) {
                allReady = false;
            }
        }

        if (allReady) {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        }
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.faAllFcDayComment.dirty) {
                this.confirmDialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen verwerpen?`,
                    'Hier blijven',
                    'Wijzigingen verwerpen').then(() => {
                    resolve(false);
                }, () => {
                    this.faAllFcDayComment.markAsPristine();
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    parseCommentContent(content) {
        const processedContent = [];
        const articleHtml = this.renderer2.createElement('article-content');
        articleHtml.innerHTML = content;

        articleHtml.childNodes.forEach((paragraph) => {
            let html = paragraph.outerHTML;
            if (html) {
                const m = html.match(/src="["\S]*"[ >]/g);
                if (m && m[0].substring(5, 5 + Settings.RESOURCES_ENDPOINT.length) === Settings.RESOURCES_ENDPOINT) {
                    html = html.replace(m[0], m[0].replace(/"[ >]/g, `?access_token=${LocalStorage.getUserToken()}"`));
                }
            }
            processedContent.push(html);
        });

        return processedContent.join('');
    }

    ngOnChanges(changes: SimpleChanges) {
        this.weekSubscriptions?.unsubscribe();
        this.weekSubscriptions = new Subscription();
        // Speed up PDF
        this.asphaltService.getAsphaltMills().then(() => {
            const realisations$ = this.realisationService.getProjectSchedule(this.beginDate, this.endDate, this.projectId);
            const commentsWeek$ = this.projectService.getProjectCommentsWeek(this.projectId, this.beginDate, true);
            this.weekSubscriptions.add(combineLatest([realisations$, commentsWeek$]).subscribe(([realisations, projectComments]) => {
                this.realisations = realisations.data.filter(re => ![RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(re.hourtype));
                this.projectComments = projectComments.data.filter(pc => !pc.week);
                this.weekComment = projectComments.data.find(pc => pc.week);
                this.processData();
                if (!this.weekComment) {
                    this.weekComment = {} as ProjectComment;
                }
                if (!this.weekComment.content) {
                    this.weekComment.content = {};
                }
                if (!this.weekComment.content.customs) {
                    this.weekComment.content.customs = {
                        entity_id: [],
                        user_id: []
                    };
                }
                this.initialContent = JSON.stringify(this.weekComment.content);
                this.fgWeekComment = new FormGroup<ControlsOf<FormGroupProjectComment>>({
                    id: new FormControl(this.weekComment?.id),
                    afas_project_id: new FormControl(this.weekComment?.afas_project_id || this.projectId),
                    date: new FormControl(this.weekComment?.date || this.beginDate),
                    comment: new FormControl(this.print ? this.parseCommentContent(this.weekComment?.comment) : this.weekComment?.comment),
                    week: new FormControl(true),
                    updated_at: new FormControl(this.weekComment?.updated_at)
                });
                this.faAllFcDayComment = new FormArray<ControlsOf<FormGroupProjectComment>>([]);
                this.faAllFcDayComment.push(this.fgWeekComment);
                this.days.forEach(day => {
                    const ownComment = this.projectComments.find(pc => Utils.getDateDate(day.date) === Utils.getDateDate(pc.date) && pc.user_id === LocalStorage.getUser().id && !this.print) ?? new ProjectComment();
                    const dayComments = this.projectComments.filter(pc =>
                        Utils.getDateDate(day.date) === Utils.getDateDate(pc.date) && pc.id !== ownComment?.id
                    );
                    dayComments.forEach(comment => {
                        comment.comment = this.parseCommentContent(comment.comment);
                    });
                    day.commentsAsphalt = dayComments.filter(dayComment => dayComment.user?.groups.find(g => g.group === 'ASPHALTEXECUTOR'));
                    day.comments = dayComments.filter(dayComment => !dayComment.user?.groups.find(g => g.group === 'ASPHALTEXECUTOR'));
                    if (ownComment && !this.print) {
                        day.ownComment = new FormGroup<ControlsOf<FormGroupProjectComment>>({
                            id: new FormControl(ownComment?.id),
                            afas_project_id: new FormControl(ownComment?.afas_project_id || this.projectId),
                            user_id: new FormControl(ownComment.user_id ?? LocalStorage.getUser().id),
                            date: new FormControl(ownComment?.date || day.date),
                            comment: new FormControl(ownComment?.comment),
                            week: new FormControl(false),
                            updated_at: new FormControl(ownComment?.updated_at)
                        });
                        this.faAllFcDayComment.push(day.ownComment);
                    }
                });
                setTimeout(() => {
                    this.readyForPuppeteer('commentsReady');
                });
            }));
        });
    }

    processData() {
        const days = [] as Date[];
        this.days = [];
        const bookDate = new Date(this.beginDate);
        while (bookDate.getTime() < this.endDate.getTime()) {
            days.push(new Date(bookDate));
            const day = {
                date: new Date(bookDate),
                comments: [],
                commentsAsphalt: [],
                ownComment: null,
                aa: null,
                asphalt: [],
                adhesive: []
            };
            this.days.push(day);
            this.subscriptions.add(this.projectService.getProjectAsphalt(this.projectId, new Date(bookDate)).subscribe(ua => {
                day.asphalt = ua.data.filter(pal => pal.application?.substring(0, 9) !== 'adhesive_');
                day.adhesive = ua.data.filter(pal => pal.application?.substring(0, 9) === 'adhesive_');
            }));

            bookDate.setDate(bookDate.getDate() + 1);
        }
    }

    saveComment() {
        const saveCall = (fgComment) => {
            this.saving = true;
            const comment = new ProjectComment();
            Object.assign(comment, fgComment.value);
            comment.content = this.weekComment.content;
            this.projectService.saveProjectComment(comment).subscribe(weekComment => {
                fgComment.markAsPristine();
                fgComment.controls.id.setValue(weekComment.data.id);
                fgComment.controls.updated_at.setValue(weekComment.data.updated_at);
                this.saving = false;
                this.saved = true;
                setTimeout(() => {
                    this.saved = false;
                }, 2000);
            }, () => {
                this.saving = false;
            });
        };

        // TinMCE model update tick
        setTimeout(() => {
            if (this.fgWeekComment.dirty) {
                saveCall(this.fgWeekComment);
            }
            this.days.forEach(day => {
                if (day.ownComment.dirty && (this.getCleanText(day.ownComment.value.comment)?.length > 0 || day.ownComment.value.id)) {
                    saveCall(day.ownComment);
                }
            });
        });
    }

    getCleanText(text) {
        const contentHtml = document.createElement('article-content');
        contentHtml.innerHTML = text;
        return contentHtml.innerText.replace(/\s/g, '');
    }


    checkChanges() {
        const hasChanges = JSON.stringify(this.weekComment?.content) !== this.initialContent;
        if (hasChanges && !this.fgWeekComment.dirty) {
            this.fgWeekComment.markAsDirty();
        }
    }

    editComment(comment: Comment) {
        const ref = this.dialog.open(EditWeekCommentDialogComponent, {
            width: '95vw',
            maxWidth: '1100px',
            maxHeight: '100%',
            disableClose: false,
            restoreFocus: false,
            panelClass: 'comment-week-edit-dialog',
            data: {comment}
        });
        this.subscriptions.add(ref.afterClosed().subscribe(result => {
            Object.assign(comment, result);
        }));
    }

    commentHistory(comment: Comment) {
        this.dialog.open(WeekCommentHistoryDialogComponent, {
            width: '95vw',
            maxWidth: '1100px',
            maxHeight: '100%',
            autoFocus: false,
            disableClose: false,
            panelClass: 'comment-week-history-dialog',
            data: {comment}
        });
    }

}


export interface FormGroupProjectComment extends Omit<ProjectComment, 'date' | 'updated_at' | 'content'> {
    date: FormControl<Date>;
    updated_at: FormControl<Date>;
}
