import {ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Component, ElementRef, forwardRef, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {formatDate} from '@angular/common';
import {merge, Subject, Subscription} from 'rxjs';
import {MatFormFieldAppearance} from '@angular/material/form-field';
import {validTime} from './validators/valid-time.validator';
import {requiredConditionalControl} from './validators/required-conditional-control.validator';
import {DateAdapter} from '@angular/material/core';
import {DateSmallAdapter} from './date-adapters/date-small-adapter';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Utils} from './utils.class';

@Component({
    selector: 'app-datetime-input',
    template: `
        <ng-container *ngIf="!matFormField">
            <div role="group" class="d-flex" [formGroup]="form">
                <input *ngIf="!hideDate" class="col-7" [min]="min" [max]="max" [formControl]="fcDate" [matDatepicker]="picker" placeholder="{{labelDate}}"
                       matInput
                       (click)="picker.open()">
                <mat-datepicker #picker></mat-datepicker>
                <input class="{{timeClass ?? (hideDate ? 'col-12' : 'col-5')}}"
                       [formControl]="fcTime"
                       (click)="timeInput.select()"
                       #timeInput
                       matInput
                       placeholder="hh:mm"
                       type="text">
            </div>
        </ng-container>
        <ng-container *ngIf="matFormField">
            <div class="row" [formGroup]="form">
                <mat-form-field class="{{hideDate ? 'col' : 'col-7'}}" *ngIf="!hideDate" [appearance]="appearance">
                    <mat-label>{{labelDate}}</mat-label>
                    <input [min]="min" [max]="max" [formControl]="fcDate" [matDatepicker]="picker" placeholder="{{labelDate}}" matInput
                           (click)="picker.open()">
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="{{hideDate ? 'col' : 'col-5'}}" [appearance]="appearance">
                    <mat-label>{{labelTime}}</mat-label>
                    <input
                        [formControl]="fcTime"
                        (click)="timeInput.select()"
                        #timeInput
                        matInput
                        placeholder="hh:mm"
                        type="text">
                </mat-form-field>
            </div>
        </ng-container>
    `,
    styles: [`
        input {
            border: 0;
        }

        input:first-child {
            padding-left: 0.5rem;
        }

        input:focus-visible {
            outline: 0;
        }
    `],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DatetimeInputComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => DatetimeInputComponent),
        multi: true
    }, {
        provide: DateAdapter, useClass: DateSmallAdapter
    }]
})
export class DatetimeInputComponent implements ControlValueAccessor, OnDestroy, OnInit {

    @HostBinding('class.ng-invalid') invalid = false;
    @HostBinding('class.ng-touched') touched = false;


    static nextId = 0;
    form: FormGroup<{
        date,
        time
    }>;
    fcDate: FormControl<Date>;
    fcTime: FormControl<string>;
    @HostBinding() id = `datetime-input-${DatetimeInputComponent.nextId++}`;
    readonly autofilled: boolean;
    readonly controlType: string;
    readonly disabled: boolean;
    readonly empty: boolean;
    readonly errorState: boolean;
    readonly focused: boolean;
    readonly required: boolean;
    readonly stateChanges = new Subject<void>();
    @Input() appearance: MatFormFieldAppearance;
    @Input() labelDate = 'Datum';
    @Input() labelTime = 'Tijd';
    @Input() hint;
    @Input() min;
    @Input() max;
    @Input() hideDate = false;
    @Input() defaultDate: Date;
    @Input() timeClass: string;
    @Input() matFormField = false;
    @ViewChild('timeInput') timeInput: ElementRef;
    private subscriptions = new Subscription();

    constructor() {

    }


    ngOnInit(): void {
        this.fcDate = new FormControl<Date>();
        this.fcTime = new FormControl<string>(
            null,
            {
                validators: [
                    validTime(),
                    requiredConditionalControl(this.fcDate)
                ],
                updateOn: 'blur'
            }
        );
        this.form = new FormGroup({
            date: this.fcDate,
            time: this.fcTime
        });
        setTimeout(() => {

            this.form.invalid$.subscribe(invalid => {
                this.invalid = invalid;
            });
            this.form.touch$.subscribe(touch => {
                this.touched = touch;
            });
            this.subscriptions.add(this.fcDate.valueChanges.subscribe(() => {
                if (this.timeInput) {
                    setTimeout(() => {
                        this.timeInput.nativeElement.focus();
                        this.timeInput.nativeElement.select();
                    });
                }
            }));

            this.subscriptions.add(this.fcTime.valueChanges.subscribe(
                value => handleTimeValueChange(value, this.fcTime)
            ));

            this.subscriptions.add(merge(this.fcDate.valueChanges, this.fcTime.valueChanges).subscribe(() => {
                if (this.fcTime.value && (this.fcDate.value ?? this.defaultDate)) {
                    const time = this.fcTime.value.split(':');
                    if (time.length === 2) {
                        const date = new Date(this.hideDate ? this.defaultDate ?? this.fcDate.value : (this.fcDate.value ?? this.defaultDate));
                        date.setHours(+time[0]);
                        date.setMinutes(+time[1]);

                        // Always set the time after the defaultDate to handle times over current day
                        if (date.getTime() < Utils.getTimeOrNull(this.defaultDate)) {
                            date.setDate(date.getDate() + 1);
                        }
                        if (date.toString() !== (new Date(this.form.value.date ?? this.defaultDate)).toString()) {
                            this.value = date;
                        }
                    }
                } else {
                    this.value = (!this.hideDate && this.fcDate.value) ? new Date(this.fcDate.value) : null;
                    if (this.fcDate.value !== null) {
                        this.fcDate.setValue(null);
                    }
                }
            }));
        });
    }

    @Input()
    get value(): Date | null {
        return this.fcDate.value;
    }

    set value(date: Date | null) {
        this.form.setValue({
            time: date ? formatDate(date, 'HH:mm', 'nl') : null,
            date
        }, {emitEvent: false});
        this.onChange(date);
        this.onTouch(date);
        this.stateChanges.next();
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.form.disable();
        } else {
            this.form.enable();
        }
    }

    onChange: any = () => {
    };

    onTouch: any = () => {
    };

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.stateChanges.complete();
    }

    writeValue(obj: Date) {
        this.value = obj;
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    validate({value}: FormControl<any>) {
        this.fcDate.updateValueAndValidity({emitEvent: false});
        this.fcTime.updateValueAndValidity({emitEvent: false});
        const valid = this.fcDate.valid && this.fcTime.valid;
        if (!valid) {
            return {
                invalid: true
            };
        }
        return null;
    }

}

export function handleTimeValueChange(value: string, formControl: FormControl<any>) {
    if (value) {
        let newValue = value.replace('.', ':');
        const splitted = newValue.split(':'), hour = +splitted[0], minute = +splitted[1];
        if (splitted.length === 2 && hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59) {
            newValue = '' + hour + ':' + (minute < 10 ? '0' : '') + minute;
        } else {
            const testHour = +newValue;
            if (newValue.length <= 2 && testHour >= 0 && testHour <= 23) {
                newValue = newValue + ':00';
            } else if (newValue.length > 2) {
                for (let place = 2; place >= 1; place--) {
                    const tempValue = [newValue.slice(0, place), newValue.slice(place)].join(':');
                    const tempSplitted = tempValue.split(':'), tempHour = +tempSplitted[0], tempMinute = +tempSplitted[1];
                    if (tempSplitted.length === 2 && tempHour >= 0 && tempHour <= 23 && tempMinute >= 0 && tempMinute <= 59) {
                        newValue = tempValue;
                        break;
                    }
                }

            }
        }
        // Round to 3 minutes
        const newValueSplit = newValue.split(':') as any[];
        if (newValueSplit.length > 1) {
            while ((+newValueSplit[1] % 3) !== 0) {
                newValueSplit[1] = (+newValueSplit[1]) - 1;
            }
        }
        const correctedNewValue = newValueSplit.join(':');

        if (value !== correctedNewValue) {
            formControl.setValue(correctedNewValue);
        }
    }
}
