import {Pipe, PipeTransform} from '@angular/core';
import {Realisation} from '../classes/realisation';
import {UserService, UserType} from '../services/user/user.service';
import {Utils} from '../utils.class';
import {OwnHourEditAllowedPipe} from '../hour-registration/hour-edit-allowed.pipe';
import {LocalStorage} from '../storage.class';
import {Settings} from '../settings.class';
import {formatDate} from '@angular/common';

@Pipe({
    name: 'notEditable'
})
export class NotEditablePipe implements PipeTransform {

    transform(realisation?: Realisation, date?: Date): unknown {
        if (!realisation && !date) {
            return;
        }

        if (UserService.userHasRights(UserType.GENERAL_HOUR_CHECK) && !realisation?.paid) {
            return;
        }

        if (realisation?.paid) {
            return 'Niet aanpasbaar omdat de uren al uitbetaald zijn';
        }

        const beginDate = new Date(realisation?.begindate ?? date);
        const today = new Date();
        const endOfDay = Utils.setTime(new Date(today), 23, 59);
        if (endOfDay.getTime() < beginDate.getTime()) {
            return 'Niet aanpasbaar omdat de uren in de toekomst liggen';
        }

        if (realisation && (new OwnHourEditAllowedPipe()).transform(realisation)) {
            return;
        }

        if (realisation
            && realisation.user_id
            && LocalStorage.user.id !== realisation.user_id
            && !realisation.submitted
            && UserService.userHasRights(UserType.HOUR_REGISTER, realisation.user)) {
            const diff = (today.getTime() - beginDate.getTime()) / 1000 / 60 / 60;
            const maxDiff = Settings.HOUR_EDIT_ALLOWED_HOURS;
            if (diff < maxDiff) {
                return 'Niet aanpasbaar omdat de uren niet zijn ingediend';
            }
        }

        const registerDate = Utils.setTime(new Date(beginDate), 23, 59);

        const editUntil = Utils.setTime(new Date(today), 0, 0);
        // daysBack = this week, added 7 days if today is before thursday, minus 1 day because week starts on monday
        const editUntilDayPrevWeek = 3;
        const editBeforeHour = 6;
        const mayEditPrevWeek = today.getDay() < editUntilDayPrevWeek || (today.getDay() === editUntilDayPrevWeek && today.getHours() < editBeforeHour);
        const daysBack = today.getDay() + (mayEditPrevWeek ? 7 : 0) - 1;

        editUntil.setDate(editUntil.getDate() - daysBack);

        if (registerDate.getTime() < editUntil.getTime()) {
            return `Niet aanpasbaar omdat de uren voor ${formatDate(editUntil, 'EEE dd MMM y', 'nl')} zijn`;
        }
        return;
    }

}
