import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {debounceTime, startWith} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import {VkmService, Workorder} from '../../services/vkm.service';
import {CodaltComponent} from '../../codalt.component';

@Component({
    selector: 'app-vkm-workorders',
    templateUrl: './vkm-workorders.component.html',
    styleUrls: ['./vkm-workorders.component.scss']
})
export class VkmWorkordersComponent extends CodaltComponent implements OnInit {

    fcSearch = new FormControl();
    fcShowHistory = new FormControl();

    workorders: Workorder[];

    constructor(private vkmService: VkmService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest(
            this.fcSearch.valueChanges.pipe(debounceTime(200), startWith('')),
            this.fcShowHistory.valueChanges.pipe(debounceTime(50), startWith(false)),
        ).subscribe(([search, history]) => {
            this.subscriptions.add(this.vkmService.getWorkorderList(search, history).subscribe(workorders => {
                this.workorders = workorders.data;
            }));
        }));
    }

}
