import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from '../utils.class';
import {PlanningRealisations} from './check-hours.component';

@Pipe({
    name: 'checkHoursFilterAny'
})
export class CheckHoursFilterAnyPipe implements PipeTransform {

    transform(planningRealisations: PlanningRealisations[], functions: string[], hourtypes: string[], hideApproved: boolean, forceCheckCounter: number): PlanningRealisations[] {

        return planningRealisations?.filter(values => {
            let count = 0;
            let include = [
                'userRealisations'
            ]
            if (!hideApproved) {
                include = [
                    ...include,
                    functions?.length > 0 ? null : 'entityRealisations', // Don't show projects based on entityRealisations when filtering on userFunction
                    functions?.length > 0 ? null : 'entityHiringRealisations', // Don't show projects based on entityHiringRealisations when filtering on userFunction
                    'hiredStaffRealisations'
                ];
            }
            include.filter(p => !!p).forEach(propName => {
                count += Utils.filterRealisations(values[propName], functions, hourtypes, hideApproved).length;
            });

            return count > 0;
        });

    }

}
