<ng-container [formGroup]="fg">
    <td class="drag-handle" cdkDragHandle [class.d-none]="readOnly">
        <i class="fa fa-arrows-from-line"></i>
    </td>
    <td *ngIf="(pln.entitytype?.has_lowloader || pln.entitytype?.has_truck || pln.entitytype?.has_driver) && pln.entity?.use_once"
        [class.error-background]="fg.controls.driver_user_id.invalid"
        [class.alert-multiple]="pln.driver_user_id | multipleDriver : pln : main.planning : planning">
        <div class="d-flex">
            <ng-select placeholder="Chauffeur"
                       bindLabel="name"
                       class="ng-dropdown-panel-left"
                       bindValue="id"
                       [items]="drivers"
                       [formControl]="fg.controls.driver_user_id"
                       [style.width.px]="150">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index">
                    <ng-container *ngIf="leaveDayUserMap.has(item.id)">
                        <i matTooltip="{{leaveDayUserMap.get(item.id)?.hourtype | hourtype}} - {{leaveDayUserMap.get(item.id)?.comment}}"
                           [matTooltipShowDelay]="0"
                           class="{{hourtypeIconMap.get(leaveDayUserMap.get(item.id).hourtype)}} text-red"></i>
                    </ng-container>
                    <i *ngIf="blockedMap?.has(item.id)"
                       [matTooltip]="blockedMap.get(item.id).description"
                       [matTooltipShowDelay]="0"
                       class="text-red fa-regular {{blockedMap.get(item.id).icon}}"></i>
                    {{item.name}} <i *ngIf="item.default_stand == DefaultStand.Home" class="fa-duotone fa-house-user"></i>
                </ng-template>
            </ng-select>
        </div>
    </td>

    <mat-menu #menu="matMenu" backdropClass="context-menu">
        <button mat-menu-item *ngIf="!pln.entity_id" (click)="changeEntity(pln, main, pln.id)">Kies materieel</button>
        <button mat-menu-item *ngIf="pln.entity_id" (click)="removeEntity(main, pln.id)" [disabled]="main.planning.entity_id === pln.entity_id">

            {{main.planning.entity_id === pln.entity_id ? 'Verwijder materieel via weekplanning' : 'Verwijder materieel'}}
        </button>
        <button mat-menu-item (click)="duplicatePlanning(pln, main, fg, true)">Voorgaand invoegen</button>
        <button mat-menu-item (click)="duplicatePlanning(pln, main, fg)">Opvolgend invoegen</button>
        <button mat-menu-item (click)="deletePlanning(pln)" [disabled]="main.planning.entity_id === pln.entity_id"><i class="fa fa-trash"></i>
            {{main.planning.entity_id === pln.entity_id ? 'Verwijder via weekplanning' : 'Regel verwijderen'}}
        </button>
    </mat-menu>
    <td [attr.colspan]="pln.entity | transportPlanningCols : pln.entitytype : pln.entity?.use_once"
        class="text-nowrap"
        *ngIf="pln.entity_id"
        [class.error-background]="fg.controls.entity_id.invalid"
        [class.alert-multiple]="pln.entity_id | multiple : pln : planning : entitiesMap">
        <div
            *ngIf="pln.entitytype?.id !== EntityTypeCode.Truck && pln.entitytype?.id !== EntityTypeCode.CutterTruck && pln.entitytype?.id !== EntityTypeCode.AsfaltTruck && pln.entity?.use_once"
            [matTooltip]="pln.name"
            class="material-piece">
            {{pln.entity?.name ?? pln.hiring?.name ?? pln.name}}
        </div>
        <div class="d-flex"
             *ngIf="(pln.entitytype_id === EntityTypeCode.Truck || pln.entitytype_id === EntityTypeCode.CutterTruck || pln.entitytype_id === EntityTypeCode.AsfaltTruck) && pln.entity?.use_once">
            <ng-select [placeholder]="pln.name ?? 'Auto'" [formControl]="fg.controls.entity_id">
                <ng-template ng-label-tmp let-item="item">{{(trucks | byId: item)?.name ?? item | licensePlate}}</ng-template>
                <ng-option [value]="truck.id" *ngFor="let truck of trucks" [innerHTML]="truck.name | materialPiece"
                           [disabled]="truck | entityUnavailable : pln : planning"></ng-option>
            </ng-select>
        </div>
        <div class="d-flex" *ngIf="!pln.entity?.use_once">
            <ng-select placeholder="Inhuurbedrijf {{(entityTypes | byId: pln.entitytype_id)?.name}}" [formControl]="fg.controls.hiring_id">
                <ng-option [value]="h.id" *ngFor="let h of hiringMap.get(pln.entitytype_id)" [innerHTML]="h.name"></ng-option>
            </ng-select>
        </div>
    </td>
    <td *ngIf="!pln.entity?.use_once && pln.entity_id" class="text-input" width="150"
        [attr.colspan]="pln.entity | transportPlanningCols : pln.entitytype ">
        <app-hire-name [fc]="fg.controls.name" [hiringAutocompleteOptions]="hiringAutocompleteOptions"></app-hire-name>
    </td>
    <td *ngIf="!pln.entity_id" class="free-text">
        {{pln.entitytype?.name}}
        <small>
            <br>{{pln.type}}
            <br>nader te bepalen</small>
    </td>
    <td *ngIf="!pln.entity_id" class="text-input" colspan="4">
        <input matInput placeholder="Nader te bepalen" type="text" formControlName="name">
    </td>
    <td *ngIf="pln.entitytype?.has_truck && pln.entity_id"
        [class.error-background]="fg.controls.truck_entity_id.invalid"
        [class.alert-multiple]="pln.truck_entity_id | multiple : pln : planning : entitiesMap">
        <div class="d-flex">
            <ng-select placeholder="Auto" [formControl]="fg.controls.truck_entity_id">
                <ng-template ng-label-tmp let-item="item">{{(trucks | byId: item)?.name ?? item | licensePlate}}</ng-template>
                <ng-option [value]="truck.id" *ngFor="let truck of trucks" [innerHTML]="truck.name"></ng-option>
            </ng-select>
        </div>
    </td>
    <td *ngIf="pln.entitytype?.has_lowloader && pln.entity_id"
        [class.error-background]="fg.controls.lowloader_entity_id.invalid"
        [class.alert-multiple]="pln.lowloader_entity_id | multiple : pln : planning : entitiesMap">
        <div class="d-flex">
            <ng-select placeholder="Dieplader" [formControl]="fg.controls.lowloader_entity_id"
                       [clearable]="!fg.controls.lowloader_entity_id?.value">
                <ng-template ng-label-tmp let-item="item">{{((lowLoaders | byId: item)?.name) ?? item | licensePlate}}</ng-template>
                <ng-option [value]="lowloader.id" *ngFor="let lowloader of lowLoaders" [innerHTML]="lowloader.name"></ng-option>
            </ng-select>
        </div>
    </td>
    <ng-container formGroupName="date">
        <td [class.error-background]="fg.errors && fg.errors.notAvailable"
            colspan="2"
            class="tabletime">
            <div class="timepicker">
                <mat-select formControlName="begintime" placeholder="van" [class.text-red]="fg.get('date').get('begintime')?.value | isNight">
                    <mat-select-trigger>
                        <ng-container *ngIf="(fg.get('date').get('begintime')?.value | isSubsequent); else dateDisplay1">
                            <small>aansluitend {{fg.get('date').get('begintime')?.value | date : 'HH:mm'}}</small>
                        </ng-container>
                        <ng-template #dateDisplay1>
                            <small>{{fg.get('date').get('begintime')?.value | date : 'EEE'}}</small> {{fg.get('date').get('begintime')?.value | date : 'HH:mm'}}
                        </ng-template>
                    </mat-select-trigger>
                    <ng-container>
                        <mat-optgroup
                            *ngFor="let day of startTimesEntity.get(pln)"
                            label="{{day.datetime | date : 'EEEE d MMM'}}">
                            <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                <ng-container *ngIf="(time.datetime | isSubsequent); else dateDisplay2">
                                    Aansluitend
                                </ng-container>
                                <ng-template #dateDisplay2>
                                    {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                                </ng-template>
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
                <mat-select formControlName="endtime" placeholder="tot" [class.text-red]="fg.get('date').get('endtime')?.value | isNight">
                    <mat-select-trigger>
                        <ng-container *ngIf="(fg.get('date').get('endtime')?.value | isSubsequent); else dateDisplay7">
                            <small>aansluitend {{fg.get('date').get('endtime')?.value | date : 'HH:mm'}}</small>
                        </ng-container>
                        <ng-template #dateDisplay7>
                            <small>{{fg.get('date').get('endtime')?.value | date : 'EEE'}}</small> {{fg.get('date').get('endtime')?.value | date : 'HH:mm'}}
                        </ng-template>
                    </mat-select-trigger>
                    <ng-container *ngIf="endTimesEntity.has(pln)">
                        <mat-optgroup
                            *ngFor="let day of endTimesEntity.get(pln)"
                            [label]="day.datetime | date : 'EEEE d MMM'">
                            <mat-option *ngFor="let time of day.options" [disabled]="time.notAvailable" [value]="time.time">
                                {{time.datetime | date : 'HH:mm'}} <small>{{time.datetime | date : 'EEE d MMM'}}</small>
                            </mat-option>
                        </mat-optgroup>
                    </ng-container>
                </mat-select>
            </div>
        </td>
    </ng-container>
    <td class="text-input">
            <span style="position:fixed;" #spanTrigger class="context-menu-trigger" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
                  (menuOpened)="pln.selected=true" (menuClosed)="pln.selected=false"></span>
        <app-transport-address [main]="main"
                               [planning]="pln"
                               [fc]="fg.get('transport_origin')"
                               [isOrigin]="true"
                               [hasNext]="main.entities[i-1]?.planning.driver_user_id === pln.driver_user_id && !!pln.driver_user_id"
                               [fg]="fg"
                               [users]="employees"
                               placeholder="Startlocatie">
        </app-transport-address>
    </td>
    <td class="text-input">
        <app-transport-address [main]="main"
                               [planning]="pln"
                               [fc]="fg.get('transport_worksite')"
                               [fg]="fg"
                               [users]="employees"
                               placeholder="Werklocatie">
        </app-transport-address>
    </td>
    <td class="text-input">
        <app-transport-address [main]="main"
                               [planning]="pln"
                               [fc]="fg.get('transport_worksite_end')"
                               [fg]="fg"
                               [users]="employees"
                               placeholder="Werklocatie">
        </app-transport-address>
    </td>
    <td class="text-input">
        <app-transport-address [main]="main"
                               [planning]="pln"
                               [fc]="fg.get('transport_destination')"
                               [fg]="fg"
                               [hasNext]="main.entities[i+1]?.planning.driver_user_id === pln.driver_user_id && !!pln.driver_user_id"
                               [users]="employees"
                               placeholder="Eindlocatie">
        </app-transport-address>
    </td>
    <td class="text-input description" (click)="textArea.focus()">
        <textarea cdkTextareaAutosize #textArea placeholder="Omschrijving werkzaamheden" formControlName="comment"></textarea>
    </td>
    <td>
        <div class="d-flex">
            <ng-select formControlName="performer_id"
                       [clearable]="true"
                       [items]="executors"
                       placeholder="Uitvoerder"
                       bindLabel="name"
                       bindValue="id">
            </ng-select>
        </div>
    </td>
    <td *ngIf="main.planning.is_transport">
        <div class="d-flex">
            <ng-select formControlName="afas_project_id"
                       [typeahead]="searchAfasProjects$"
                       [clearable]="true"
                       [selectableGroup]="false"
                       [items]="projects"
                       placeholder="Project"
                       groupBy="mainProject"
                       bindLabel="name"
                       bindValue="afas_project_id">
                <ng-template ng-label-tmp let-item="item">
                    <b>{{item.afas_project_id}}</b> {{item.name}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <b>{{item.afas_project_id}}</b> {{item.name}}
                </ng-template>
            </ng-select>
        </div>
    </td>
</ng-container>
