import {Pipe, PipeTransform} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {FgPlanningHas, FgWorkorderLine} from './vkm-planning.component';

@Pipe({
    name: 'notSettledCount'
})
export class NotSettledCountPipe implements PipeTransform {

    transform(planning_vkm: FormArray<FormGroup<FgPlanningHas>>,
              fgWl: FormGroup<FgWorkorderLine>,
              field: string,
              absolute = true,
              ...args: unknown[]): number {
        const mwos = planning_vkm.value.filter(p => p.type === 'MWO')
            .map(p => p.workorderObjects)
            .flat()
            .filter(o => o.entity_id === fgWl.value.entity_id)
            .reduce((sum, current) => sum + current.count_placed, 0);

        const dwos = planning_vkm.value.filter(p => p.type === 'DWO')
            .map(p => p.workorderObjects)
            .flat()
            .filter(o => o.entity_id === fgWl.value.entity_id)
            .reduce((sum, current) => sum + current.count_removed + current.count_lost, 0);

        const diff = mwos - dwos;

        if(absolute) {
            return Math.max(0, diff);
        }
        return diff;
    }

}
